import {LitElement, html, css} from 'lit'
import { customElement, property } from 'lit/decorators.js';
import { loadGlobalStyles } from './slds-system';

@customElement("slds-menu")
export class SldsCombobox extends LitElement {
  static get properties() {
    return {
      value: { type: Object },
      open: { type: Boolean },
      values: { type: Array },
    };
  }
  @property({type: Boolean}) mobileMode = false;

  constructor() {
    super();
    this.values = [];
    this.open = false;
    document.addEventListener('click', (function(e) {
      if (this.open) {
        const path = e.composedPath();
        let insideMenuClick = false;
        if(!(path === undefined || path.length == 0)) {
         insideMenuClick = path.find((el) => { return el == this; });
        }
        if(insideMenuClick) {
          //inside element
        } else {
          //outside element
          this.open = false;
          for (var i = this.shadowRoot.querySelectorAll("a").length - 1; i >= 0; i--) {
            this.shadowRoot.querySelectorAll("a")[i].ariaExpanded = "false";
          }
          const event = new CustomEvent('blur', { open: this.open, bubbles: true });
          this.dispatchEvent(event);
        }
      }
    }).bind(this), false);
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  click(e) {
    this.open = !this.open;
  }

  handleFocus(e) {
    var target = e.target;
    var i = 0;
    while((!target.dataset.level || !target.href) && i<5) {
      target = target.parentElement;
      i++;
    }
    var myLevel = parseInt(target.dataset.level);
    for (var i = this.shadowRoot.querySelectorAll("a").length - 1; i >= 0; i--) {
      var targetLevel = parseInt(this.shadowRoot.querySelectorAll("a")[i].dataset.level);
      if (targetLevel >= myLevel)
      {
        this.shadowRoot.querySelectorAll("a")[i].ariaExpanded = "false";
      }
    }
    target.ariaExpanded = "true";
  }

  findLabelByValue(value, items) {
    for (const item of items) {
      if (item.subItems) {
        const subLabel = this.findLabelByValue(value, item.subItems);
        if (subLabel) {
          return subLabel;
        }
      }
      if (item.value === value) {
        return item.label;
      }
    }
    return null;
  }

  handleSelect(e) {
    var target = e.target;
    var i = 0;
    while((!target.dataset.value || !target.href) && i<5) {
      target = target.parentElement;
      i++;
    }
    if (this.mobileMode && target.dataset.level === "0") {
      var myLevel = parseInt(target.dataset.level);
      for (var i = this.shadowRoot.querySelectorAll("a").length - 1; i >= 0; i--) {
        var targetLevel = parseInt(this.shadowRoot.querySelectorAll("a")[i].dataset.level);
        if (targetLevel >= myLevel)
        {
          this.shadowRoot.querySelectorAll("a")[i].ariaExpanded = "false";
        }
      }
      target.ariaExpanded = "true";
    }
    else {
      this.open = false;
      var value = target.dataset.value;
      var label = this.findLabelByValue(value, this.values);
      const event = new CustomEvent('changed', { detail: { value: value, label: label }, bubbles: true });
      this.dispatchEvent(event);
      this.value = { value: value, label: label };
      for (var i = this.shadowRoot.querySelectorAll("a").length - 1; i >= 0; i--) {
        this.shadowRoot.querySelectorAll("a")[i].ariaExpanded = "false";
      }
      e.preventDefault();
      // target.ariaExpanded = "false";
    }
  }

  renderMenu(items, level) {
    return html`
      <ul class="slds-dropdown__list" role="menu">
        ${items.map(
          item => html`
            <li class="slds-dropdown__item ${item.subItems ? 'slds-has-submenu' : ''}" role="presentation">
              <a href="#" role="menuitem" data-label="${item.label}" data-value="${item.value}" data-level="${level}" @mouseover="${this.handleFocus}" @focus="${this.handleFocus}" @click="${this.handleSelect}" tabindex="0" aria-haspopup="${item.subItems ? 'true' : 'false'}">
                <span class="slds-truncate" title="${item.label}">${item.label}</span>
                ${item.subItems ? html`
                  <svg class="slds-icon slds-icon_x-small slds-icon-text-default slds-m-left_small slds-shrink-none" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#right"></use>
                  </svg>
                ` : ''}
              </a>
              ${item.subItems ? html`
                <div class="slds-dropdown slds-dropdown_submenu slds-dropdown_submenu-right">
                  ${this.renderMenu(item.subItems, level+1)}
                </div>
              ` : ''}
            </li>
          `
        )}
      </ul>
    `;
  }

  static styles = css`
    @media (max-width: 767px) {
      .slds-dropdown-trigger>button {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 7.5rem;
      }
    }
    .slds-dropdown__item>a {
      color: var(--text-color, #181818);
    }
    .slds-dropdown {
      background: var(--background-color, #fff);
      border: 1px solid var(--slds-c-button-neutral-color-border, #e5e5e5);
    }
    .slds-dropdown__item>a:focus, .slds-dropdown__item>a:hover {
      color: var(--text-active-color);
    }
  `;

  render() {
    return html`
      <div class="slds-dropdown-trigger slds-dropdown-trigger_click ${this.open ? `slds-is-open` : `slds-is-closed`}">
        <button class="slds-button slds-button_neutral slds-truncate" @click="${this.click}">${this.value.label}
          <svg class="slds-button__icon slds-button__icon_right" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevrondown"></use>
          </svg>
        </button>
        <div class="slds-dropdown slds-dropdown_left">
          ${this.renderMenu(this.values, 0)}
        </div>
      </div>
    `
  }
}
