import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('not-found')
class NotFound extends LitElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  render() {
    return html`
      <div class="slds-grid slds-grid_vertical-align-center slds-text-align_center" style="height: 80vh;">
        <div class="slds-size_3-of-3">
          <span class="slds-icon_container null slds-icon__svg--default"><svg class="slds-icon slds-icon-text-default test-class" aria-hidden="true"><use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#sentiment_negative"></use></svg></span>
          <!-- <img src="https://example.com/404-image.png" alt="404 Error" style="width: 100%; max-width: 400px;"> -->
          <h1 class="slds-text-heading_large">Uh oh!</h1>
          <p class="slds-text-heading_medium">We couldn't find what you were looking for.</p>
          <p class="slds-text-body_regular slds-m-top_xx-small">It seems like the page you were trying to access has either been moved or doesn't exist.</p>
          <a href="/" class="slds-button slds-button_brand slds-m-top_medium">Take me back to safety</a>
        </div>
      </div>
    `
  }
}