import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import store from '../views/store';
import { setUser, setAccessToken } from '../views/store';
import { navigator } from 'lit-element-router';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('payment-view')
class PaymentView extends connect(store)(navigator(LitElement)) {
  @property({type: String}) errorMsg = '';
  @property({type: String}) paymentDialog = 'freeOrPremium'; ///membershipType/freeOrPremium/paymentPage/congrats/loading/removedMembership
  @property({type: String}) route = "";
  @property({type: Boolean}) freeOrPremiumBtnDisabled = true;
  @property({type: Boolean}) membershipTypeBtnDisabled = false;
  @property({type: String}) selectedValue = "";
  @property({type: Object}) user = null;
  @property({type: Object}) stripePromise = null;
  @property({type: String}) verifySessionId = "";
  @property({type: String}) verifyUID = "";
  @property({type: String}) accessToken = '';
  @property({type: String}) currentUserType = 'free';

  connectedCallback() {
    super.connectedCallback();
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  async handleUpgrade(membershipType) {
    const stripe = await this.stripePromise;

    const fetchClientSecret = async () => {
      const response = await fetch(window.endpoint+'/api/create/subscription', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ planType: membershipType, accessToken: this.accessToken }),
      });
      if (!(response.status === 200)) {
        const obj = await response.json();
        // Handle 401 unauthorized response
        this.errorMsg = obj.error;
        return null;
      }
      const { client_secret } = await response.json();
      return client_secret;
    };
    const checkout = await stripe.initEmbeddedCheckout({
      fetchClientSecret,
    });
    this.membershipTypeBtnDisabled = false;
    // Mount Checkout
    checkout.mount(this.shadowRoot.querySelector('#checkout'));
    this.paymentDialog = "paymentPage";
  }

  async downgradePlan() {
    this.paymentDialog = "loading";
    const response = await fetch(window.endpoint+'/api/remove/subscription', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accessToken: this.accessToken }),
    });
    if (!(response.status === 200)) {
      const obj = await response.json();
      // Handle 401 unauthorized response
      this.errorMsg = obj.error;
      return null;
    }
    this.paymentDialog = "removedMembership";
  }

  async verifySessionRequest(verifySessionId, verifyUID) {
    this.paymentDialog = "loading";
    const response = await fetch(window.endpoint+`/api/session/status?session_id=${verifySessionId}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ verifyUID }),
    });
    const session = await response.json();
    // Handle the session according to its status
    if (session.status == 'open') {
      // Remount embedded Checkout
      this.paymentDialog = "paymentPage";
    } else if (session.status == 'complete') {
      this.paymentDialog = "congrats";
      // Show success page
      // Optionally use session.payment_status or session.customer_email
      // to customize the success page
    }
  }

  clearError() {
    this.errorMsg = '';
  }

  async initStripe() {
    // Dynamically import the Stripe helper module
    const { initializeStripe } = await import('./stripe-helper.js');
    
    // Initialize Stripe with your publishable key
    if (window?.process?.env?.NODE_ENV === "production") {
      this.stripePromise = await initializeStripe('pk_live_yl1CrbzgOr5xvHaM0kzv2iZZ');
    }
    else
    {
      this.stripePromise = await initializeStripe('pk_test_75887UnoAP1jNLDWoskPuCXG');
    }
    // this.stripePromise = loadStripe('pk_test_75887UnoAP1jNLDWoskPuCXG');
  }

  stateChanged(state) {
    if (!(this.route === state.route)) {
      this.route = state.route;
      this.paymentDialog = 'freeOrPremium'
    }
    if (this.route === "payment" && !this.stripePromise) {
      this.stripePromise = {};
      this.initStripe();
    }
    this.user = state.user;
    if (this.user) {
      const custom = this.user.customClaims;
      if (custom && custom.apiKey) {
        this.currentUserType = custom.userType;
        if (this.paymentDialog === 'freeOrPremium') {
          this.freeOrPremiumBtnDisabled = false;
        }
      }
    }
    const session_id = state?.verify_session?.session_id;
    const uid = state?.verify_session?.uid;
    if (session_id && uid && !(session_id === this.verifySessionId) && !(uid === this.verifyUID)) {
      this.verifySessionId = session_id;
      this.verifyUID = uid;
      if (this.verifySessionId) {
        this.verifySessionRequest(this.verifySessionId, this.verifyUID);
      }
    }
    const token = state.token;
    if (!(token===this.accessToken)) {
      this.accessToken = token;
    }
  }

  freeOrPremiumRadioChange(event) {
    const selectedValue = event.target.value;
    this.selectedValue = selectedValue;
    console.log('Radio button selected:', this.selectedValue);
    // You can call your function here
    this.errorMsg = '';
    if (selectedValue === "free") {
      this.freeOrPremiumBtnDisabled = false;
    }
    else if (selectedValue === "premium") {
      this.freeOrPremiumBtnDisabled = false;
    }
    else if (selectedValue === "monthlyPremium") {
      this.membershipTypeBtnDisabled = false;
    }
    else if (selectedValue === "business") {
      this.membershipTypeBtnDisabled = false;
    }
    else {
      this.errorMsg = 'Invalid free or premium radio choice!';
    }
  }

  freeOrPremiumSubmit() {
    if (this.selectedValue === "free" && this.currentUserType === "free") {
      this.errorMsg = 'This user is already Free, cannot downgrade!';
      return null;
    }
    if (this.selectedValue === "free") {
      if (this.selectedValue != this.currentUserType) {
        //Cancel membership
        this.paymentDialog = 'cancelMembership';
        return null;
      }
      else {
        //Goto chat
        window.history.pushState({}, null, '/chat');
        window.dispatchEvent(new CustomEvent('route'));
        return null;
      }
    }
    this.paymentDialog = 'membershipType';
    this.errorMsg = '';
  }

  membershipTypeSubmit() {
    this.errorMsg = '';
    if (this.stripePromise) {
      if (this.selectedValue === "" || this.selectedValue === "premium") { //Code for beta
        this.selectedValue = "monthlyPremium";
      }
      if (this.currentUserType === this.selectedValue) {
        this.errorMsg = `User already has '${this.selectedValue}' plan`;
        return null;
      }
      this.membershipTypeBtnDisabled = true;
      this.handleUpgrade(this.selectedValue);
    }
  }

  getChatHref() {
    if (window?.process?.env?.NODE_ENV === "production") {
      return "https://chat.inclouds.io";
    }
    return "/chat";
  }

  static styles = css`
    #checkout {
      min-width: 34rem;
      min-height: 44rem;
    }
    .payment-page {
      margin-bottom: -8rem;
      padding-bottom: 2rem;
    }
    .slds-visual-picker input[disabled]~label .slds-visual-picker__figure {
      background-color: var(--background-color, #8F8E8D);
      border-color: #7A7978;
    }
    .dark .slds-visual-picker input[disabled]~label .slds-visual-picker__body, .dark .slds-visual-picker input[disabled]~label .slds-visual-picker__text {
      color: #c9c7c5;
    }
    .center-container2 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin-top: -3rem;
      min-height: 50rem;
    }
    .dark .mb2 {
      color: white;
    }
    .slds-visual-picker__text-check .slds-icon {
      fill: #2b2826;
    }
    .slds-visual-picker__figure {
      background: var(--background-color, #fff);
    }
    .slds-text-heading--label-normal, .slds-text-heading_label-normal, .slds-text-title, .slds-form-element__label {
      color: var(--text-color, #444);
    }
    .slds-visual-picker input[disabled]~label .slds-visual-picker__body, .slds-visual-picker input[disabled]~label .slds-visual-picker__text {
      color: var(--text-color, #444);
    }
    .slds-modal__content {
      --slds-c-modal-content-color-background: var(--background-color, white);
    }
    .slds-theme--default, .slds-theme_default {
      background-color: var(--background-color, #fff);
      color: var(--text-color, #181818);
    }
    .slds-modal__container {
      --slds-c-modal-color-border: var(--table-hover, #e5e5e5);
    }
  `;

  render() {
    return html`
    ${this.errorMsg==='' ? '' : html`<div class="slds-notify slds-notify_alert slds-alert_error" role="alert">
      <span class="slds-assistive-text">error</span>
      <span class="slds-icon_container slds-icon-utility-error slds-m-right_x-small" title="Description of icon when needed">
        <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#error"></use>
        </svg>
      </span>
      <h2>${this.errorMsg}
        <a href="#">More Information</a>
      </h2>
      <div class="slds-notify__close">
        <button @click="${this.clearError}" class="slds-button slds-button_icon slds-button_icon-small slds-button_icon-inverse" title="Close">
          <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
          </svg>
          <span class="slds-assistive-text">Close</span>
        </button>
      </div>
    </div>`} 

    ${this.paymentDialog === 'cancelMembership' ? html`<section role="dialog" tabindex="-1" aria-modal="true" aria-labelledby="modal-heading-01" class="slds-modal slds-fade-in-open">
      <div class="slds-modal__container">
        <button @click="${() => { this.paymentDialog = 'freeOrPremium'; }}" class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse">
          <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
          </svg>
          <span class="slds-assistive-text">Cancel and close</span>
        </button>
        <div class="slds-modal__header slds-theme--info slds-theme--alert-texture">
          <h1 id="modal-heading-01" class="slds-modal__title slds-hyphenate" tabindex="-1">You are about to cancel your membership!</h1>
        </div>
        <div class="slds-modal__content slds-p-around_medium" id="modal-content-id-1">
          <p>Your account will be downgraded to the 'Free' plan immediately.</p>
        </div>
        <div class="slds-modal__footer slds-theme_default">
          <button class="slds-button slds-button_neutral" @click="${() => { this.paymentDialog = 'freeOrPremium'; }}" aria-label="Cancel and close">Cancel</button>
          <button class="slds-button slds-button_destructive" @click="${this.downgradePlan}">Downgrade Plan</button>
        </div>
      </div>
    </section>
    <div class="slds-backdrop slds-backdrop_open" role="presentation"></div>` : ``}
    
    <div class="center-container2">
      <div ?hidden="${!(this.paymentDialog === 'loading')}" class="login slds-box slds-m-top_xx-large slds-m-bottom_xx-large">
        <div class="slds-form-element slds-text-align_center slds-p-around_x-large">
          <div class="slds-text-heading_large">Loading…</div>
          <div class="slds-text-heading_medium">Please wait</div>
          <div class="demo-only" style="height:6rem;position:relative">
            <div role="status" class="slds-spinner slds-spinner_medium slds-spinner_brand">
              <span class="slds-assistive-text">Loading</span>
              <div class="slds-spinner__dot-a"></div>
              <div class="slds-spinner__dot-b"></div>
            </div>
          </div>
        </div>
      </div>
      <div ?hidden="${!(this.paymentDialog === 'removedMembership')}" class="login slds-box slds-m-top_xx-large slds-m-bottom_xx-large">
        <div class="slds-form-element slds-text-align_center slds-p-around_x-large">
          <div class="slds-text-heading_large">Your account was downgraded!</div>
          <div class="slds-text-heading_medium">You no longer have any premium features.</div>
          <div class="slds-p-top_small slds-p-bottom_small">
            <span class="slds-icon_container slds-icon-utility-success" title="Success">
              <svg class="slds-icon slds-icon_large" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#sentiment_negative"></use>
              </svg>
            </span>
          </div>
          <a href="${this.getChatHref()}" class="slds-button slds-button_brand slds-m-top_large">Go to Non-Premium Chat</a>
        </div>
      </div>
      <div ?hidden="${!(this.paymentDialog === 'congrats')}" class="login slds-box slds-m-top_xx-large slds-m-bottom_xx-large">
        <div class="slds-form-element slds-text-align_center slds-p-around_x-large">
          <div class="slds-text-heading_large">Congratulations!</div>
          <div class="slds-text-heading_medium">You have successfully subscribed!</div>
          <div class="slds-p-top_small slds-p-bottom_small">
            <span class="slds-icon_container slds-icon-utility-success" title="Success">
              <svg class="slds-icon slds-icon_large" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#success"></use>
              </svg>
            </span>
          </div>
          <a href="${this.getChatHref()}" class="slds-button slds-button_brand slds-m-top_large">Go to Premium Chat</a>
        </div>
      </div>
      <div ?hidden="${!(this.paymentDialog === 'freeOrPremium')}" class="login slds-box slds-m-top_xx-large slds-m-bottom_xx-large">
        <div class="slds-form-element slds-text-align_center">
          <fieldset class="slds-form-element slds-m-left_x-large slds-m-right_x-large">
            <legend class="slds-form-element__legend slds-form-element__label">Select a plan</legend>
            <div class="slds-form-element__control">
              <div class="slds-visual-picker slds-visual-picker_medium slds-m-right_medium">
                <input type="radio" id="premium" value="premium" name="freeOrPremium" @change="${this.freeOrPremiumRadioChange}" ?checked="${!(this.currentUserType==='free')}" />
                <label for="premium">
                  <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
                    <svg class="slds-icon slds-icon-standard-account slds-icon_large slds-var-p-around_xx-small" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#moneybag"></use>
                    </svg>
                  </span>
                  <span class="slds-visual-picker__body">
                    <span class="slds-text-heading_small">Premium</span>
                    <span class="slds-text-title">Access to more advanced models with favorable limits.</span>
                  </span>
                  <span class="slds-icon_container slds-visual-picker__text-check">
                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                    </svg>
                  </span>
                </label>
              </div>
              <div class="slds-visual-picker slds-visual-picker_medium slds-m-left_medium">
                <input type="radio" id="free" value="free" name="freeOrPremium" @change="${this.freeOrPremiumRadioChange}" ?checked="${this.currentUserType==='free'}" />
                <label for="free">
                  <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
                    <svg class="slds-icon slds-icon-standard-lead slds-icon_large" aria-hidden="true">
                      <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#lead"></use>
                    </svg>
                  </span>
                  <span class="slds-visual-picker__body">
                    <span class="slds-text-heading_small">Free</span>
                    <span class="slds-text-title">Limited access to less advanced models.</span>
                  </span>
                  <span class="slds-icon_container slds-visual-picker__text-check">
                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </fieldset>
          <button type="submit" @click="${this.freeOrPremiumSubmit}" ?disabled="${this.freeOrPremiumBtnDisabled}" class="slds-m-bottom_medium slds-button slds-button_brand slds-m-top_x-small">Select</button>
        </div>
      </div>
      <div ?hidden="${!(this.paymentDialog === 'membershipType')}" class="login slds-box slds-m-top_xx-large slds-m-bottom_xx-large">
        <div class="slds-form-element slds-text-align_center">
          <fieldset class="slds-form-element slds-m-left_x-large slds-m-right_x-large">
            <legend class="slds-form-element__legend slds-form-element__label">Select a plan</legend>
            <div class="slds-form-element__control">
              <div class="slds-visual-picker slds-visual-picker_medium slds-m-right_medium">
                <input type="radio" id="monthlyPremium" value="monthlyPremium" name="membershipType" @change="${this.freeOrPremiumRadioChange}" checked />
                <label for="monthlyPremium">
                  <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
                    <span>
                      <span class="slds-text-heading_large">$19.99</span>
                      <span class="slds-text-title">USD/user/month *</span>
                    </span>
                  </span>
                  <span class="slds-visual-picker__body">
                    <span class="slds-text-heading_small">Monthly Premium</span>
                    <span class="slds-text-title">Free <b>30 day trial</b> period!</span>
                    <span class="slds-text-title">Much higher limits than Free accounts.</span>
                    <span class="slds-text-title">Access to the most advanced models.</span>
                  </span>
                  <span class="slds-icon_container slds-visual-picker__text-check">
                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                    </svg>
                  </span>
                </label>
              </div>
              <div class="slds-visual-picker slds-visual-picker_medium slds-m-left_medium">
                <input type="radio" id="business" value="business" name="membershipType" @change="${this.freeOrPremiumRadioChange}" disabled="" />
                <label for="business">
                  <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
                    <span>
                      <span class="slds-text-heading_large"><s>As you go</s></span>
                      <span class="slds-text-title">(Disabled in Beta)</span>
                      <!-- USD/user/month * -->
                    </span>
                  </span>
                  <span class="slds-visual-picker__body">
                    <span class="slds-text-heading_small"><s>Business</s></span>
                    <span class="slds-text-title"><s>Virtually unlimited access to different models.</s></span>
                    <span class="slds-text-title"><s>Account balance topped off after dropping below a threshold.</s></span>
                  </span>
                  <span class="slds-icon_container slds-visual-picker__text-check">
                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </fieldset>
          <button type="submit" @click="${this.membershipTypeSubmit}" ?disabled="${this.membershipTypeBtnDisabled}" class="slds-m-bottom_medium slds-button slds-button_brand  slds-m-top_x-small">Select</button>
        </div>
      </div>
      <div ?hidden="${!(this.paymentDialog === 'paymentPage')}" class="payment-page">
        <div class="slds-box">
          <div id="checkout">
            <!-- Checkout inserts the payment form here -->
          </div>
        </div>
      </div>
    </div>
    `
  }
}