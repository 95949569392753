import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import store from '../views/store';
import { setUser, setAccessToken } from '../views/store';
import { navigator } from 'lit-element-router';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('login-view')

class LoginView extends connect(store)(navigator(LitElement)) {
  @property({type: Object}) errorMsg = null;
  @property({type: String}) loginDialog = 'login'; //login/recaptcha/phoneNum/phoneCode/accountType/signup/authenticated
  @property({type: String}) smsSentToPhoneNum = '';
  @property({type: Boolean}) enrollMfaButtonDisabled = false;
  @property({type: String}) smsVerificationCode = '';
  @property({type: Boolean}) smsVerificationCodeDisabled = false;
  @property({type: Object}) user = null;

  stateChanged(state) {
    if (state.user) {
      this.user = state.user;
      this.loginDialog = 'authenticated';
    }
  }

  connectedCallback() {
    super.connectedCallback()
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  clearError() {
    this.errorMsg = null;
  }

  handleErrors(error) {
    var prettyCode = null;
    if (error.code) {
      prettyCode = error.code.split("/").join("-");
    }
    if (error.code === "auth/account-exists-with-different-credential") {
      // The pending Google credential.
      let pendingCred = error.credential;

      // Step 3: Save the pending credential in temporary storage,

      // Step 4: Let the user know that they already have an account
      // but with a different provider, and let them choose another
      // sign-in method.
      debugger;
    }
    if (error.code === "auth/cancelled-popup-request" || error.code === "auth/popup-closed-by-user") {
      this.errorMsg = {msg: "SSO Popup was closed!", code: prettyCode};
    }
    if (error.code === "auth/invalid-verification-code") {
      this.errorMsg = {msg: "Invalid verification code provided!", code: prettyCode};
    }
    else if (error.message) {
      this.errorMsg = {msg: error.message, code: prettyCode};
    }
    else
    {
      console.error(error);
    }
  }

  verifyMfa() {
    var codeNum = this.renderRoot.querySelector('#code').value;
    this.smsVerificationCodeDisabled = true;
    this.smsVerificationCode = codeNum;
  }

  enrollMfa() {
    var phoneNum = this.renderRoot.querySelector('#phone').value;
    this.enrollMfaButtonDisabled = true;
    this.smsSentToPhoneNum = phoneNum;
  }

  phoneMfa(params = {enroll:false, user: null, resolver: null}, done) {
    const user = params.user;
    const resolver = params.resolver;
    const enroll = params.enroll;

    this.loginDialog = 'recaptcha'; //login/recaptcha/phoneNum/phoneCode/accountType/signup
    const recaptcha = document.getElementById('recaptcha');
    const recaptchaVerifier = new firebase.RecaptchaVerifier(firebase.auth, recaptcha, { size: "invisible" }); //normal
    
    recaptchaVerifier.verify().then((token) => {
      if (user) {
        return firebase.multiFactor(user).getSession();
      }
      else
      {
        return new Promise((resolve, reject) => { resolve(null) });
      }
    })
    .then((multiFactorSession) => {
      if (user) {
        this.loginDialog = 'phoneNum';
        return new Promise((resolve, reject) => { 
          setInterval((function(){
            if (this.smsSentToPhoneNum.length>0) {
              resolve(multiFactorSession);
            }
          }).bind(this), 500)
        });
      }
      else {
        return new Promise((resolve, reject) => { resolve(multiFactorSession) });
      }
    })
    .then(((multiFactorSession) => {
      // Specify the phone number and pass the MFA session.
      var phoneInfoOptions = null;
      const selectedIndex = 0;
      if (resolver) {
        phoneInfoOptions = {
          multiFactorHint: resolver.hints[selectedIndex],
          session: resolver.session
        };
        this.smsSentToPhoneNum = resolver.hints[selectedIndex].phoneNumber;
      }
      else if (user) {
        phoneInfoOptions = {
          phoneNumber: this.smsSentToPhoneNum,
          session: multiFactorSession
        };
      }
      else
      {
        throw Error("Invalid args supplied to phoneMfa")
      }
      var phoneAuthProvider = new firebase.PhoneAuthProvider(firebase.auth);
      // Send SMS verification code.
      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    }).bind(this))
    .then(((verificationId) => {
      this.loginDialog = 'phoneCode';
      return new Promise((resolve, reject) => { 
        setInterval((function(){
          if (this.smsVerificationCode.length>0) {
            resolve(verificationId);
          }
        }).bind(this), 500)
      });
    }).bind(this))
    .then((function (verificationId) {
        // this.enrollMfaButtonDisabled = false;
        var cred = firebase.PhoneAuthProvider.credential(verificationId, this.smsVerificationCode);
        var multiFactorAssertion = firebase.PhoneMultiFactorGenerator.assertion(cred);
        // Complete enrollment.
        this.loginDialog = 'authenticated';
        if (enroll) {
          return firebase.multiFactor(user).enroll(multiFactorAssertion, "Default MFA");
        }
        else
        {
          return resolver.resolveSignIn(multiFactorAssertion)
        }
    }).bind(this))
    .then(done)
    .catch(this.handleErrors.bind(this));
  }

  handleSuccess(result) {
    const firebase = window.firebase;
    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = firebase.GoogleAuthProvider.credentialFromResult(result);
    // const token = credential.accessToken;
    // // The signed-in user info.
    // const user = result.user;
    // debugger    
    // User is signed in.
    // You can retrieve the user's GCP token if you need to interact with GCP services.
    const tokenResponse = result._tokenResponse;
    const user = result.user;
    const reloadUserInfo = user.reloadUserInfo;
    const token = user.accessToken;

    this.phoneMfa({enroll:true, user: user}, (function(){
      store.dispatch(setAccessToken(token));
      store.dispatch(setUser(reloadUserInfo));
      this.navigate("upgrade-downgrade");
      debugger;
    }).bind(this));
  }

  loginEmail(e) {
    if (e.target.attributes.disabled) { return null; }
    e.preventDefault();
    const provider = new firebase.auth.EmailAuthProvider();
    firebase.auth()
      .signInWithPopup(provider)
      .then(this.handleSuccess.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  loginLinkedin(e) {
    if (e.target.attributes.disabled) { return null; }
    e.preventDefault();
    const provider = new firebase.auth.LinkedinAuthProvider();
    firebase.auth()
      .signInWithPopup(provider)
      .then(this.handleSuccess.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  loginGithub(e) {
    if (e.target.attributes.disabled) { return null; }
    e.preventDefault();
    const provider = new window.firebase.GithubAuthProvider();
    window.firebase
      .signInWithPopup(window.firebase.auth, provider)
      .then(this.handleSuccess.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  async loginGoogle(e) {
    if (e.target.attributes.disabled) { return null; }
    e.preventDefault();
    const provider = new window.firebase.GoogleAuthProvider();
    const firebase = window.firebase;
    const navigate = this.navigate;
    firebase
      .signInWithPopup(firebase.auth, provider)
      .then(this.handleSuccess.bind(this))
      .catch((function(error) {
        if (error.code === "auth/multi-factor-auth-required") {
          const resolver = firebase.getMultiFactorResolver(firebase.auth, error);
          // Show UI to let user select second factor.
          const multiFactorHints = resolver.hints;
          const selectedIndex = 0;
          if (resolver.hints[selectedIndex].factorId ===
              firebase.PhoneMultiFactorGenerator.FACTOR_ID) {
              this.phoneMfa({enroll:false, resolver: resolver}, function(result){
                const tokenResponse = result._tokenResponse;
                const user = result.user;
                const reloadUserInfo = user.reloadUserInfo;
                const token = user.accessToken;
                store.dispatch(setAccessToken(token));
                store.dispatch(setUser(reloadUserInfo));
                if (window?.process?.env?.NODE_ENV === "production") {
                  window.location.href = "https://chat.inclouds.io";
                }
                else {
                  navigate("chat");
                }
              });
              // const recaptchaVerifier = new firebase.RecaptchaVerifier(firebase.auth, domRecaptcha, { size: "normal" }); //invisible
              // recaptchaVerifier.verify().then((token) => {
              //   // User selected a phone second factor.
              //   const phoneInfoOptions = {
              //       multiFactorHint: resolver.hints[selectedIndex],
              //       session: resolver.session
              //   };
              //   // Send SMS verification code.
              //   const phoneAuthProvider = new firebase.PhoneAuthProvider(firebase.auth);
              //   phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
              //       .then(function (verificationId) {
              //           // verificationId will be needed for sign-in completion.
              //         var cred = firebase.PhoneAuthProvider.credential(verificationId, "123456");
              //         var multiFactorAssertion = firebase.PhoneMultiFactorGenerator.assertion(cred);
              //         return resolver.resolveSignIn(multiFactorAssertion)
              //       })
              //       .then(function (result) {
              //         const tokenResponse = result._tokenResponse;
              //         const user = result.user;
              //         const token = user.accessToken;
              //         store.dispatch(setAccessToken(token));
              //         store.dispatch(setUser(tokenResponse));
              //         debugger;
              //           // User successfully signed in with the second factor phone number.
              //       });
              // })
          } else if (resolver.hints[selectedIndex].factorId ===
                     firebase.TotpMultiFactorGenerator.FACTOR_ID) {
              this.errorMsg = {msg: "TotpMultiFactorGenerator is not supported", code: "TotpMultiFactorGeneratorNotSupported" };
          }
        }
        else
        {
          this.handleErrors(error).bind(this);
        }
      }).bind(this));
  }

  static styles = css`
    .center-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin-top: -3rem;
      min-height: 32rem;
    }
    .login {
      display: flex;
    }
    .login[hidden] {
      display: none;
    }
    .login-options>span>button, .login-options>span {
      width: 100%;
      display: inline-block;
    }
    .login-options {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-left: 1px solid #ddd;
      padding: 1rem 2rem;
    }
    .login-form {
      padding: 20px;
    }
    button.slds-button[disabled] {
      cursor: not-allowed;
    }
    .dark .slds-button_neutral:disabled, .dark .slds-button_neutral[disabled] {
      background-color: #444444;
      border-color: #c9c9c9;
      color: #c9c9c9;
    }
    .dark .slds-button_neutral:disabled:hover, .dark .slds-button_neutral[disabled]:hover {
      color: #c9c9c9;
    }
    .dark label {
      color: white;
    }
    label.slds-form-element__label {
      color: var(--text-color);
    }
    .slds-input, .slds-input:active, .slds-input:focus {
      --slds-c-input-text-color: var(--text-color, #181818);
    }
  `;

  render() {
    return html`
    ${this.errorMsg===null ? '' : html`<div class="slds-notify slds-notify_alert slds-alert_error" role="alert">
      <span class="slds-assistive-text">error</span>
      <span class="slds-icon_container slds-icon-utility-error slds-m-right_x-small" title="Description of icon when needed">
        <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#error"></use>
        </svg>
      </span>
      <h2>${this.errorMsg.msg}
        <a href="/error/${this.errorMsg.code}">More Information</a>
      </h2>
      <div class="slds-notify__close">
        <button @click="${this.clearError}" class="slds-button slds-button_icon slds-button_icon-small slds-button_icon-inverse" title="Close">
          <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
          </svg>
          <span class="slds-assistive-text">Close</span>
        </button>
      </div>
    </div>`}
    <div class="center-container">
      <div ?hidden="${!(this.loginDialog === 'authenticated')}" class="login slds-box">
        <div class="slds-form-element">
          <p>The account is authenticated.</p>
        </div>
      </div>
      <div ?hidden="${!(this.loginDialog === 'accountType')}" class="login slds-box slds-m-top_xx-large slds-m-bottom_xx-large">
        <div class="slds-form-element slds-text-align_center">
          <fieldset class="slds-form-element slds-m-left_x-large slds-m-right_x-large">
            <legend class="slds-form-element__legend slds-form-element__label">Select a plan</legend>
            <div class="slds-form-element__control">
              <div class="slds-visual-picker slds-visual-picker_medium slds-m-right_medium">
                <input type="radio" id="visual-picker-370" value="visual-picker-370" name="example-unique-name-140" />
                <label for="visual-picker-370">
                  <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
                    <svg class="slds-icon slds-icon-standard-account slds-icon_large" aria-hidden="true">
                      <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#account"></use>
                    </svg>
                  </span>
                  <span class="slds-visual-picker__body">
                    <span class="slds-text-heading_small">Corporate Account</span>
                    <span class="slds-text-title">For business related activities</span>
                  </span>
                  <span class="slds-icon_container slds-visual-picker__text-check">
                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                    </svg>
                  </span>
                </label>
              </div>
              <div class="slds-visual-picker slds-visual-picker_medium slds-m-left_medium">
                <input type="radio" id="visual-picker-371" value="visual-picker-371" name="example-unique-name-140" />
                <label for="visual-picker-371">
                  <span class="slds-visual-picker__figure slds-visual-picker__text slds-align_absolute-center">
                    <svg class="slds-icon slds-icon-standard-lead slds-icon_large" aria-hidden="true">
                      <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#lead"></use>
                    </svg>
                  </span>
                  <span class="slds-visual-picker__body">
                    <span class="slds-text-heading_small">Personal Account</span>
                    <span class="slds-text-title">For personal use</span>
                  </span>
                  <span class="slds-icon_container slds-visual-picker__text-check">
                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </fieldset>
          <button @click="${this.verifyMfa}" ?disabled="${this.smsVerificationCodeDisabled}" type="submit" class="slds-m-bottom_medium slds-button slds-button_brand  slds-m-top_x-small">Choose</button>
        </div>
      </div>
      <div ?hidden="${!(this.loginDialog === 'phoneCode')}" class="login slds-box">
        <div class="slds-form-element">
          <p>We need to verify your phone number before we proceed (standard phone carrier SMS charges apply).</p>
          <p class="slds-m-top_x-small">This is used in order to prevent the abuse of our service.</p>
          <label class="slds-form-element__label slds-m-top_x-small" for="code">
            Please enter the code that you have received on your phone number (${this.smsSentToPhoneNum}):<br />
            <small>Format: 123-456</small>
          </label>
          <div class="slds-form-element__control">
            <input type="tel" id="code" class="slds-input" name="code" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required />
          </div>
          <button @click="${this.verifyMfa}" ?disabled="${this.smsVerificationCodeDisabled}" type="submit" class="slds-button slds-button_brand slds-m-top_x-small">Verify</button>
        </div>
      </div>
      <div ?hidden="${!(this.loginDialog === 'phoneNum')}" class="login slds-box">
        <div class="slds-form-element">
          <p>We need to verify your phone number before we proceed (standard phone carrier SMS charges apply).</p>
          <p class="slds-m-top_x-small">This is used in order to prevent the abuse of our service.</p>
          <label class="slds-form-element__label slds-m-top_x-small" for="phone">
            Enter your phone number:<br />
            <small>Format: +1-123-456-7890</small>
          </label>
          <div class="slds-form-element__control">
            <input type="tel" id="phone" class="slds-input" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required />
          </div>
          <button @click="${this.enrollMfa}" ?disabled="${this.enrollMfaButtonDisabled}" type="submit" class="slds-button slds-button_brand slds-m-top_x-small">Send Code</button>
        </div>
      </div>
      <div ?hidden="${!(this.loginDialog === 'recaptcha')}" class="login slds-box">
        <div class="slds-grid slds-wrap">
          <div class="slds-col slds-size_1-of-1">
            <p>Please wait until we verify your browser, you may need to complete a captcha.</p>
          </div>
          <div class="slds-col slds-size_1-of-1">
            <slot></slot>
          </div>
        </div>
      </div>
      <div ?hidden="${!(this.loginDialog === 'login')}" class="login slds-box">
        <div class="login-form">
          <form>
            <div class="slds-form-element">
              <label class="slds-form-element__label" for="email">Email</label>
              <div class="slds-form-element__control">
                <input type="text" id="email" class="slds-input" placeholder="Email" required>
              </div>
            </div>
            <div class="slds-form-element">
              <label class="slds-form-element__label" for="password">Password</label>
              <div class="slds-form-element__control">
                <input type="password" id="password" class="slds-input" placeholder="Password" required>
              </div>
            </div>
            <button type="submit" disabled class="slds-button slds-button_brand slds-m-top_x-small">Submit</button>
          </form>
        </div>
        <div class="login-options">
          <span>
            <button @click="${this.loginLinkedin}" disabled class="slds-button slds-button_neutral">
              Login with Linkedin
            </button>
          </span>
          <span>
            <button @click="${this.loginGithub}" class="slds-button slds-button_neutral">
              Login with Github
            </button>
          </span>
          <span>
            <button @click="${this.loginGoogle}" class="slds-button slds-button_neutral">
              Login with Google
            </button>
          </span>
          <span>
            <button @click="${this.loginEmail}" disabled class="slds-button slds-button_neutral">
              Email / Password
            </button>
          </span>
        </div>
      </div>
    </div>
    `
  }
}