import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { router } from "lit-element-router";
import "./main-view.js";
import "./login-view.js";
import "./chat-view.js";
import "./faq-view.js";
import "./payment-view.js";
import "./not-found.js";
import "./settings-view.js";
import "./sidebar.component.js";
import "./../components/slds-dropdown.js";
import "./../components/slds-menu.js";
import "./../components/slds-search.js";
import "./../components/slds-spinner.js";
import "./profile-view.js";
import store from './store';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { setUser, setAccessToken, setModel, loadChat, setRoute, verifyPaymentSessionId } from '../views/store';
import localStorageService from '../components/LocalStorageService';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('app-view')
class AppView extends connect(store)(router(LitElement)) {
  @property({type: Boolean}) chatMode = false;
  @property({type: Boolean}) mobileMode = false;
  @property({type: Boolean}) toggleMenu = false;
  @property({type: Object}) user = null;
  @property({type: Number}) count = 0;
  @property({type: String}) route = '';
  @property({type: Object}) params = {};
  @property({type: Object}) query = {};
  @property({type: String}) selected = '';
  @property({type: String}) theme = '';
  @property({type: Array}) profileDropdown = [
    {label:'User Actions', type: 'header'},
    {label:'My Profile', href: 'profile'},
    {label:'Upgrade/Downgrade', href: 'upgrade-downgrade'},
    // {label:'Customize', value: 'customize-tags'},
    // {label:'Saved', value: 'saved'},
    {label:'Settings', href: 'settings'},
    {label:'Help Center', value: 'help-center'},
    {label:'Report Problems', value: 'report-problems'},
    // {label:'Dark Mode', value: 'dark-mode', type: 'toggle'},
    {label:'Log Out', value: 'log-out'}
  ];
  @property({type: Boolean}) profileDropdownOpen = false;
  @property({type: Object}) aiSelected = { label: 'GPT 3.5 Turbo 0125', value: 'gpt-3.5-turbo-0125' };
  @property({type: Array}) aiValues = [
    { 
      label: 'Gemini', 
      value: 'gemini-1.5-pro-preview-0409',
      subItems: [
        { label: 'Gemini 1.5 Pro Preview', value: 'gemini-1.5-pro-preview-0409' },
        { label: 'Gemini 1.5 Flash', value: 'gemini-1.5-flash' },
        { label: 'Gemini 1.0 Pro', value: 'gemini-1.0-pro' }
      ]
    },
    { 
      label: 'ChatGPT', 
      value: 'gpt-4o',
      subItems: [
        { label: 'GPT 4o', value: 'gpt-4o' },
        { label: 'GPT 4o Mini', value: 'gpt-4o-mini' },
        { label: 'GPT 4o 2024', value: 'gpt-4o-2024' },
        { label: 'GPT 4 Turbo 0125', value: 'gpt-4-0125-preview' },
        { label: 'GPT 4 Turbo', value: 'gpt-4-turbo-preview' },
        { label: 'GPT 4 Turbo 1106', value: 'gpt-4-1106-preview' },
        { label: 'GPT 4', value: 'gpt-4' },
        { label: 'GPT 3.5 Turbo 0125', value: 'gpt-3.5-turbo-0125' },
        { label: 'GPT 3.5 Turbo 1106', value: 'gpt-3.5-turbo-1106' }
      ]
    },
    { 
      label: 'Claude', 
      value: 'claude-3-5-sonnet-20240620',
      subItems: [
        { label: 'Claude 3.5 Sonnet', value: 'claude-3-5-sonnet-20240620' },
        { label: 'Claude 3 Opus', value: 'claude-3-opus-20240229' },
        { label: 'Claude 3 Sonnet', value: 'claude-3-sonnet-20240229' },
        { label: 'Claude 3 Haiku', value: 'claude-3-haiku-20240307' },
      ]
    },
    { 
      label: 'Mistral', 
      value: 'mistral-large-latest',
      subItems: [
        { label: 'Mistral Large Latest', value: 'mistral-large-latest' },
        { label: 'Mistral Nemo', value: 'open-mistral-nemo' },
        { label: 'Codestral', value: 'codestral-latest' },
        { label: 'Mixtral 8x22B', value: 'open-mixtral-8x22b' },
        { label: 'Mixtral 8x7B', value: 'open-mixtral-8x7b' },
        { label: 'Mistral 7B', value: 'open-mistral-7b' },
        { label: 'Codestral Mamba', value: 'open-codestral-mamba' },
      ]
    },
    { 
      label: 'Llama 3 405b', 
      value: 'llama3-405b-instruct-maas'
    },
    // { 
    //   label: 'Grok 1.5', 
    //   value: 'grok-1.5'
    // }
  ];
  @property({type: Number}) forceRedraw = null;
  @property({ type: Boolean }) isLoading = true;

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('load', this._onLoad.bind(this));
    if (window?.process?.env?.NODE_ENV === "production" && this.route === 'main') {
      const currentSubdomain = window.location.hostname.split('.')[0];
      if (currentSubdomain === 'chat') {
        this.route = 'chat';
        store.dispatch(setRoute('chat'));
        this.chatMode = true;
      }
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('load', this._onLoad.bind(this));
  }

  _onLoad() {
    this.isLoading = false;
  }

  stateChanged(state) {
    this.user = state.user;

    if (state.forceRedraw && state.forceRedraw != this.forceRedraw) {
      this.forceRedraw = state.forceRedraw;
      // this.notifyPath('chatMode');
      // this.notifyPath('toggleMenu');
    }
    if (!(this.route === state.route)) {
      this.route = state.route;
      this.toggleMenu = false;
    }
    this.theme = state.theme;
  }

  constructor() {
    super();
    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobileMode = true;
      this.toggleMenu = false;
    }
    console.log("Mobile mode", this.mobileMode);
    localStorageService.getItem("model").then((model) => {
      if (model) {
        this.aiSelected = JSON.parse(model);
        store.dispatch(setModel(this.aiSelected));
      }
      else if (this.aiSelected) {
        store.dispatch(setModel(this.aiSelected));
      }
    });
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
    document.body.appendChild(loadGlobalStyles());
  }

  static get routes() {
    return [
      {
        name: "main",
        pattern: "",
        data: { title: "Home", type: "main" },
      },
      {
        name: "login",
        pattern: "login",
        data: { title: "Login" },
      },
      {
        name: "chat",
        pattern: "chat",
        data: { title: "Chat" },
      },
      {
        name: "chat",
        pattern: "chat/:id",
        data: { title: "Chat" },
      },
      {
        name: "chat",
        pattern: "chat/:id/:msgId",
        data: { title: "Chat" },
      },
      {
        name: "faq",
        pattern: "faq",
        data: { title: "Frequently Asked Questions" },
      },
      {
        name: "terms",
        pattern: "tos",
        data: { title: "Terms of Service" },
      },
      {
        name: "terms",
        pattern: "privacy",
        data: { title: "Privacy Policy" },
      },
      {
        name: "faq",
        pattern: "error/:id",
        data: { title: "Frequently Encountered Errors" },
      },
      {
        name: "faq",
        pattern: "faq/:id",
        data: { title: "Frequently Encountered Errors" },
      },
      {
        name: "docs",
        pattern: "docs",
        data: { title: "Documentation" },
      },
      {
        name: "docs",
        pattern: "docs/:id",
        data: { title: "Documentation" },
      },
      {
        name: "help",
        pattern: "help",
        data: { title: "Help" },
      },
      {
        name: "help",
        pattern: "support",
        data: { title: "Support" },
      },
      {
        name: "download",
        pattern: "download",
        data: { title: "Download CLI" },
      },
      {
        name: "payment",
        pattern: "upgrade-downgrade",
        data: { title: "Upgrade or Downgrade Account" },
      },
      {
        name: "payment",
        pattern: "upgrade-downgrade/return",
        data: { title: "Upgrade or Downgrade Account" },
      },
      {
        name: "settings",
        pattern: "settings",
        data: { title: "Settings" },
      },
      {
        name: "settings",
        pattern: "settings/account",
        data: { title: "Account" },
      },
      {
        name: "settings",
        pattern: "settings/password",
        data: { title: "Password" },
      },
      {
        name: "settings",
        pattern: "settings/profile",
        data: { title: "Profile" },
      },
      {
        name: "settings",
        pattern: "settings/privacy&safety",
        data: { title: "Privacy & Safety" },
      },
      {
        name: "profile",
        pattern: "profile",
        data: { title: "User Profile" },
      },
      {
        name: "not-found",
        pattern: "*",
      },
    ];
  }

  router(route, params, query, data) {
    if (query.session_id && query.uid) {
      store.dispatch(verifyPaymentSessionId({session_id:query.session_id, uid:query.uid}));
    }
    if (!(this.route === route)) {
      store.dispatch(setRoute(route));
    }
    if (route == "chat") {
      if (params) {
        if (params.id) {
          store.dispatch(loadChat(params.id));
        }
        if (params.msgId) {
          
        }
      }
      this.chatMode = true;
    }
    else
    {
      this.chatMode = false;
    }
    this.params = params;
    this.query = query;
    if (this.query && this.query.i) {
      this.postOpen = this.query.i;
    }
    if (data && data.type) {
      this.selected = data.type;
    }
    else
    {
      this.selected = "";
    }
    const settingsViews = this.shadowRoot.querySelector('settings-view');
    if (settingsViews) {
      settingsViews.reset();
    }
  }

  toggle() {
    this.toggleMenu = !this.toggleMenu;
  }

  getTheme() {
    const theme = this.theme;
    if (!theme) { return ""; }
    return theme;
  }

  toggleBlur() {
    this.profileDropdownOpen = false;
  }

  toggleDropdown() {
    this.profileDropdownOpen = !this.profileDropdownOpen;
  }

  dropdownSelect(e) {
    const type = e.detail.type;
    if (type === "log-out") {
      const firebase = window.firebase;
      firebase.signOut(firebase.auth).then(() => {
      }).catch((error) => {
        console.error(error);
      });
      store.dispatch(setAccessToken(''));
      store.dispatch(setUser(null));
      localStorageService.removeItem("user");
      localStorageService.removeItem("token");
    }
    this.toggleBlur()
  }

  getFirstCharsOfFirstTwoWords(inputString) {
      // Split the input string into an array of words
      const words = inputString.split(" ");

      // Check if there are at least two words
      if (words.length < 2) {
          return "";
      }

      // Extract the first two characters of the first two words, capitalize them, and return
      const firstWord = words[0].substring(0, 1).toUpperCase();
      const secondWord = words[1].substring(0, 1).toUpperCase();

      return firstWord+secondWord;
  }

  gptChanged(e) {
    const targetValue = e.detail;
    localStorageService.setItem("model", JSON.stringify(targetValue));
    store.dispatch(setModel(targetValue));
  }

  newChat() {
    if (window?.process?.env?.NODE_ENV === "production") {
      history.pushState({}, null, "/");
    }
    else
    {
      history.pushState({}, null, "/chat");
    }
    store.dispatch(loadChat(""));
  }

  changeUrlNoRefresh(e) {
    e.preventDefault();
    var target = e.target;
    var i = 0;
    while((!target.href || target.href.animVal) && i<5) {
      target = target.parentElement;
      i++;
    }
    const href = target.href;
    window.history.pushState({}, null, href);
    window.dispatchEvent(new CustomEvent('route'));
  }

  selectMenuItem(e) {
    var target = e.target;
    var i = 0;
    while((!target.href || target.href.animVal) && i<5) {
      target = target.parentElement;
      i++;
    }
    const targetHref = target.href;
    if (['https://inclouds.io','https://chat.inclouds.io'].indexOf(targetHref)===-1) {
      window.history.pushState({}, null, targetHref);
      window.dispatchEvent(new CustomEvent('route'));
      e.preventDefault();
    }
    this.toggleMenu = false;
  }

  getChatHref() {
    if (window?.process?.env?.NODE_ENV === "production") {
      return "https://chat.inclouds.io";
    }
    return "/chat";
  }

  getHomeHref() {
    if (window?.process?.env?.NODE_ENV === "production") {
      const currentSubdomain = window.location.hostname.split('.')[0];
      if (currentSubdomain === 'chat') {
        return "https://inclouds.io/";
      }
      else
      {
        return "/";
      }
    }
    return "/";
  }

  sidebarSelectedChat(chat) {
    if (this.mobileMode) {
      this.toggleMenu = false;
    }
  }

  static styles = css`
    input[type=text] {
      color: #181818;
    }
    .dark input[type=text] {
      color: #f3f3f3;
    }
    #sidebar {
      position: fixed;
      top: 3rem;
      bottom: 0;
      left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 16rem;
      background-color: #FAFAFB;
      border-right: 1px solid #d8dee6;
      overflow-y: auto;
    }
    .dark#sidebar {
      background-color: #2b2826;
      color: #a9a9a9;
      border-right: 1px solid #1a1b1e;
      z-index: 99;
    }
    #site-main-content.expanded {
      margin-left: 16rem;
    }
    .slds-builder-header {
      background: radial-gradient(#033673, #022a5a);
    }
    .dark .slds-checkbox_toggle [type=checkbox]+.slds-checkbox_faux_container {
      color: white;
    }
    .dark .slds-text-title {
      color: white;
    }
    .dark>.slds-builder-header {
      background: #1a1b1e;
    }
    .dark .slds-theme--default, .dark .slds-theme_default {
      color: white;
      background-color: #2b2826;
      overflow: auto;
    }
    .dark .slds-form-element__label {
      color: white;
    }
    .dark .slds-theme--alert-texture, .dark .slds-theme_alert-texture {
      background-image: linear-gradient(45deg, rgba(255, 255, 255, .025) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, .025) 0, rgba(255, 255, 255, .025) 75%, transparent 0, transparent);
    }
    main.dark#site-main-content {
      background-color: #2b2826;
      color: white;
    }
    .dark a:active {
      color: #b0adab;
    }
    .dark #login, .dark .login-options>span>a {
      background-color: #2b2826; 
    }
    .dark .slds-builder-header__item-action:hover, .dark .slds-builder-header__item-action:focus {
      background-color: #3e3e3c;
    }
    .slds-builder-header__item-action:hover, .slds-builder-header__item-action:focus {
      background-color: #e3e5ed;
    }
    .dark .slds-dropdown__header {
      color: white;
    }
    .dark .slds-dropdown, .dark .slds-button_neutral {
      color: white;
      background: #2b2826;
      border: 1px solid #514f4d;
    }
    .dark .slds-button_icon-border-inverse {
      border: 1px solid #514f4d;
    }
    .post-buttons .slds-button_outline-brand {
      background-color: #eef4ff;
    }
    @media (max-width: 767px) {
      #search .slds-media__body {
        display: none;
      }
      #search .slds-media__figure {
        margin-right: 0;
      }
      #alert .slds-media__body {
        display: none;
      }
      #alert .slds-media__figure {
        margin-right: 0;
      }
      #sidebar {
        width: 13rem;
      }
      #site-main-content.expanded {
        margin-left: 13rem;
      }
      main {
        padding-top: 0;
      }
    }
    [hidden] {
      display: none;
    }
    .slds-builder-header__utilities-item button, .slds-builder-header__utilities-item .slds-dropdown-trigger>button:hover {
      color: white;
    }
    .beta {
      margin-left: 0.15rem;
      font-size: 0.5rem;
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .fadeIn {
      opacity: 0; /* Make the element initially invisible */
      animation: fadeIn 300ms ease-in-out 50ms forwards; /* Duration of 300ms, 300ms delay */
    }
    .app-content {
      display: none;
    }
    .app-content[loaded] {
      display: block;
    }
    .dark sidebar-component {
      --background-color: #2b2826;
      --font-color: white;
      --active-background: #505050;
      --active-border: #706e6b;
      --slds-c-icon-color-foreground-default: white;
    } 
    sidebar-component {
      --background-color: #FAFAFB;
      --font-color: #181818;
      --active-background: white;
      --active-border: #e5e5e5;
    }
    .dark slds-spinner {
      --background-color: #181818;
    }
    .dark slds-menu {
      --slds-c-button-neutral-color-background: #181818;
      --background-color: #2b2826;
      --slds-c-button-neutral-color-border: #514f4d;
      --slds-c-button-text-color: white;
      --text-active-color: #0176d3;
      --text-color: white;
    }
    .dark main-view, .dark download-view, .dark faq-view, .dark docs-view, .dark slds-dropdown, .dark upgrade-downgrade, .dark slds-search, .dark terms-view, .dark payment-view, .dark settings-view, .dark chat-view {
      --background-color: #2b2826;
      --table-hover: #000000;
      --text-color: white;
      --border-color: #706e6b;
    }
  `;

  render() {
    return html`
      <slds-spinner .loading="${this.isLoading}"></slds-spinner>
      <div class="app-content" ?loaded="${!this.isLoading}">
        ${(this.toggleMenu || (this.chatMode && (!this.mobileMode))) ? html`<section id="sidebar" class="${this.getTheme()}"><sidebar-component @selected="${this.sidebarSelectedChat}" .chatMode="${this.chatMode}" .selected="${this.selected}" class="${this.getTheme()}"></sidebar-component></section>` : ''} 
        <div class="slds-builder-header_container ${this.getTheme()}">
          <header class="slds-builder-header">
            ${(this.chatMode && (!this.mobileMode)) ? '' : html`<div class="slds-builder-header__item">
              <a href="javascript:void(0);" @click=${this.toggle} class="slds-builder-header__item-action" title="Expand Menu">
                <span class="slds-icon_container slds-icon-utility-back slds-current-color">
                  <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#rows"></use>
                  </svg>
                  <span class="slds-assistive-text">Expand Menu</span>
                </span>
              </a>
            </div>`}
            <div class="slds-builder-header__item">
              <a .href="${this.chatMode ? this.getChatHref() : `/`}" class="slds-builder-header__item-action slds-media slds-media_center slds-text-link_reset">
                <div class="slds-media__body">
                  inClouds.io
                </div>
                <div class="beta">BETA</div>
              </a>
            </div>
            ${this.chatMode ? html`<div class="slds-builder-header__item slds-p-left_small">
              <slds-menu .mobileMode="${this.mobileMode}" .value="${this.aiSelected}" @changed="${this.gptChanged}" .values="${this.aiValues}" icon="dropdown"></slds-menu>
            </div>` : ``}
            ${this.chatMode ? html`<div class="slds-builder-header__item slds-p-left_small"><button @click="${this.newChat}" class="slds-button slds-button_icon slds-button_icon-border-inverse slds-m-around_small" title="New Chat">
    <svg class="slds-button__icon" aria-hidden="true">
      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#new"></use>
    </svg>
    <span class="slds-assistive-text">Search</span>
  </button></div>` : ``}
            <div class="slds-builder-header__item slds-builder-header__utilities">
              ${this.chatMode ? html`<div class="slds-builder-header__utilities-item" id="alert">
                <slds-search class="slds-builder-header__item-action slds-media slds-media_center" label="Search" icon="dropdown"></slds-search>
              </div>` : ``}
              ${this.chatMode ? '' : html`<div class="slds-builder-header__utilities-item" id="alert">
                <a href="${this.getHomeHref()}download" @click="${this.changeUrlNoRefresh}" class="slds-builder-header__item-action slds-media slds-media_center">
                  <span class="slds-media__figure">
                    <span class="slds-icon_container slds-icon-utility-settings slds-current-color">
                      <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#arrow_bottom"></use>
                      </svg>
                    </span>
                  </span>
                  <span class="slds-media__body">
                    <span class="slds-truncate" title="Download CLI">Download CLI</span>
                  </span>
                </a>
              </div>`}
              ${this.chatMode ? '' : html`<div class="slds-builder-header__utilities-item" id="alert">
                <a href="${this.getHomeHref()}docs" @click="${this.changeUrlNoRefresh}" class="slds-builder-header__item-action slds-media slds-media_center">
                  <span class="slds-media__figure">
                    <span class="slds-icon_container slds-icon-utility-settings slds-current-color">
                      <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#display_text"></use>
                      </svg>
                    </span>
                  </span>
                  <span class="slds-media__body">
                    <span class="slds-truncate" title="Documentation">Documentation</span>
                  </span>
                </a>
              </div>`}
              ${this.chatMode ? '' : html`<div class="slds-builder-header__utilities-item" id="alert">
                <a href="${this.getHomeHref()}faq" @click="${this.changeUrlNoRefresh}" class="slds-builder-header__item-action slds-media slds-media_center">
                  <span class="slds-media__figure">
                    <span class="slds-icon_container slds-icon-utility-settings slds-current-color">
                      <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#puzzle"></use>
                      </svg>
                    </span>
                  </span>
                  <span class="slds-media__body">
                    <span class="slds-truncate" title="FAQ">FAQ</span>
                  </span>
                </a>
              </div>`}
              ${(this.user && !this.chatMode) ? html`<nav class="slds-builder-header__item slds-builder-header__nav">
                <ul class="slds-builder-header__nav-list">
                    <li class="slds-builder-header__nav-item">
                      <slds-dropdown types="slds-dropdown_small slds-dropdown_right" @select="${this.dropdownSelect}" @blur="${this.toggleBlur}" .open="${this.profileDropdownOpen}" .options="${this.profileDropdown}">
                        <button @click="${this.toggleDropdown}" class="slds-button slds-builder-header__item-action slds-media slds-media_center slds-text-link_reset slds-p-top_x-small slds-p-bottom_x-small" aria-haspopup="true" aria-expanded="false" title="Click to open menu">
                          <span class="slds-media__body">
                            <span aria-hidden="true" class="slds-avatar slds-avatar_circle slds-chat-avatar">
                              ${this.user.photoUrl ? html`<img alt="${this.user.displayName}" src="${this.user.photoUrl}" title="${this.user.displayName}" />` : html`<abbr class="slds-avatar__initials slds-avatar__initials_inverse" title="${this.user.displayName}">${this.getFirstCharsOfFirstTwoWords(this.user.displayName)}</abbr>` }
                            </span>
                            <span class="slds-icon_container slds-icon-utility-chevrondown slds-current-color">
                              <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevrondown"></use>
                              </svg>
                            </span>
                            <span class="slds-assistive-text">Show More</span>
                          </span>
                        </button>
                      </slds-dropdown>
                    </li>
                </ul>
              </nav>` : ''}
              ${this.user ? '' : html`
              <div class="slds-builder-header__utilities-item">
                <div class="slds-media slds-media_center">
                  <div class="slds-media__body">
                    <a href="${this.getHomeHref()}login" @click="${this.changeUrlNoRefresh}">
                      <button class="slds-button slds-button_brand slds-m-left_medium slds-m-right_medium">
                        <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#download"></use>
                        </svg> Login
                      </button>
                    </a>
                  </div>
                </div>
              </div>`}
              <div class="slds-builder-header__utilities-item slds-show_medium">
                <a href="${this.getHomeHref()}help" @click="${this.changeUrlNoRefresh}" class="slds-builder-header__item-action slds-media slds-media_center">
                  <div class="slds-media__figure">
                    <span class="slds-icon_container slds-icon-utility-help slds-current-color">
                      <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#help"></use>
                      </svg>
                    </span>
                  </div>
                  <div class="slds-media__body">Help</div>
                </a>
              </div>
              ${this.chatMode ? html`
              <div class="slds-builder-header__utilities-item">
                <a href="${this.getHomeHref()}" @click="${this.selectMenuItem}" class="slds-builder-header__item-action slds-media slds-media_center">
                  <div class="slds-media__figure">
                    <span class="slds-icon_container slds-icon-utility-help slds-current-color">
                      <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#home"></use>
                      </svg>
                    </span>
                  </div>
                  <div class="slds-media__body">Home</div>
                </a>
              </div>` : ''}
            </div>
          </header>
        </div>
        <!-- If/else in class litelement -->
        <main role="main" class="${(this.toggleMenu || (this.chatMode && (!this.mobileMode))) ? 'expanded' : ''} slds-m-top_xx-large ${this.getTheme()}" id="site-main-content" tabindex="-1">
          <router-outlet active-route=${this.route}>
            <main-view .mobileMode="${this.mobileMode}" route='main'></main-view>
            <post-view route='post'></post-view>
            <settings-view route='settings'></settings-view>
            <not-found route='not-found'></not-found>
            <login-view route='login'>
              <!-- Other content around the reCAPTCHA -->
              <slot></slot> <!-- This will include the light DOM content -->
              <!-- Other content around the reCAPTCHA -->
            </login-view>
            <profile-view route='profile'></profile-view>
            <chat-view route='chat'></chat-view>
            <faq-view route='faq'></faq-view>
            <download-view route='download'></download-view>
            <payment-view route='payment'></payment-view>
            <docs-view route='docs'></docs-view>
            <help-view route='help'></help-view>
            <terms-view route='terms'></terms-view>
          </router-outlet>
        </main>
      </div>
    `
  }
}