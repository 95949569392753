import { LitElement, html } from 'lit';
import { outlet } from "./lit-element-router.js";
import { customElement, property } from 'lit/decorators.js';

@customElement('router-outlet')
class RouterOutlet extends outlet(LitElement) {
  constructor() {
    super();
  }
  render() {
    return html` <slot></slot> `;
  }
}