import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('help-view')
class HelpView extends LitElement {
  connectedCallback() {
    super.connectedCallback()
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  render() {
    return html`
      <!-- Help Content -->
      <main class="slds-p-around_large">
        <div class="slds-grid">
          <div class="slds-size--12-of-12">
            <div class="slds-form slds-form_stacked">
              <h1 class="slds-text-heading_large">We're here to help</h1>
              <p>Support is just a few clicks away. You can also get your questions answered by using out <a href="#!help">help library</a>.</p>
              <p>Additionally you can contact our live support Monday - Friday, 10AM - 6PM</p>
              <a target="_blank" href="https://www.smartsupp.com/widget/f324432yrth54634523"><button class="header-medium slds-m-top_small slds-m-bottom_large slds-button slds-button_success">Contact live support</button></a>
              <h1 style="font-size: 1rem;">Additionally you can try</h1>
              <p>Find us on our <a href="https://slack.inclouds.io">inClouds Slack</a> or shout out on <a target="_blank" href="https://twitter.com/inClouds">@inClouds</a></p>
              <p>You can also try to shoot us an email at support(@at)inclouds.io but we only accept emails over TLS and reject what we perceive to be spam at our convenience.</p>
            </div> 
          </div>
        </div>
        <div class="slds-grid">
          <div class="slds-size--12-of-12">
            <div class="slds-form-element">
              <a class="slds-button slds-button_neutral slds-float_left slds-m-top_small" href="/">Back</a>
            </div>
          </div>
        </div>
      </main>
    `
  }
}