import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import store from '../views/store';
import { setUser, setAccessToken, setChats, loadChat } from '../views/store';
import { navigator } from 'lit-element-router';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { ResponseData } from './ResponseData_pb';
import { RequestData } from './RequestData_pb';
import { History, HistoryEntry } from './History_pb';
import { format, formatRelative, isToday, isYesterday, differenceInDays } from 'date-fns';
import localStorageService from '../components/LocalStorageService';
import { loadGlobalStyles, setElementTheme } from '../components/slds-system';

@customElement('chat-view')
class ChatView extends connect(store)(LitElement) {
  @property({type: Object}) error = null;
  @property({type: Object}) user = null;
  @property({type: String}) accessToken = '';
  @property({type: Object}) targetModel = null;
  @property({type: Object}) lastConvoModel = "";
  // @property({type: Boolean}) loading = false;
  @property({type: Boolean}) chatboxExpanded = false;
  @property({type: Boolean}) chatboxActive = false;
  @property({type: Boolean}) sendingMsg = false;
  // @property({type: String}) strOutput = "";
  @property({type: Array}) chatMessages = [
    // {"timestamp":"2024-06-24T21:00:31.973Z","chat_id":"803e0b65-1588-40c2-b305-85f3a2ed11cc","sender_name":"Maxim T","sender_type":"user","message_type":"bookend","message_content":"Chat started by Maxim T","meta_info":"12:00 AM","avatar_initials":"AM"},
    // {"timestamp":"2024-06-24T21:00:31.973Z","chat_id":"803e0b65-1588-40c2-b305-85f3a2ed11cc","sender_name":"Maxim T","sender_type":"user","message_type":"outbound","message_content":"Hello sir, how are you on this fine day?","meta_info":"12:00 AM"},
    // {"timestamp":"2024-06-24T21:00:32.244Z","chat_id":"803e0b65-1588-40c2-b305-85f3a2ed11cc","sender_name":"Claude 3.5 Sonnet","sender_type":"agent","message_type":"inbound","message_content":"I'm doing well, thank you for asking. I hope you're having a fine day as well.","meta_info":"12:00 AM","avatar_initials":"AG","loading":false},
    // {"timestamp":"2024-06-24T21:02:12.807Z","chat_id":"803e0b65-1588-40c2-b305-85f3a2ed11cc","sender_name":"Maxim T","sender_type":"user","message_type":"outbound","message_content":"Can you write me the same in chinese?","meta_info":"12:02 AM"},{"timestamp":"2024-06-24T21:02:12.839Z","chat_id":"803e0b65-1588-40c2-b305-85f3a2ed11cc","sender_name":"Claude 3.5 Sonnet","sender_type":"agent","message_type":"inbound","message_content":"我很好，谢谢你的问候。希望你今天也过得愉快。","meta_info":"12:02 AM","avatar_initials":"AG","loading":false},
    // {"timestamp":"2024-06-24T21:02:54.205Z","chat_id":"803e0b65-1588-40c2-b305-85f3a2ed11cc","sender_name":"Maxim T","sender_type":"user","message_type":"outbound","message_content":"Try cantonese please!","meta_info":"12:02 AM"},
    // {"timestamp":"2024-06-24T21:02:54.367Z","chat_id":"803e0b65-1588-40c2-b305-85f3a2ed11cc","sender_name":"Claude 3.5 Sonnet","sender_type":"agent","message_type":"inbound","message_content":"我好好吖，多謝你問。希望你今日都過得開心。","meta_info":"12:02 AM","avatar_initials":"AG"},
  ];
  @property({type: Boolean}) chatMessageLoading = false;
  @property({type: String}) chatUuid = "";
  @property({type: String}) loadChatId = "";
  @property({type: Array}) chatThreads = null;
  @property({type: Boolean}) isFirstRequest = false;
  @property({type: String}) newTitle = "";
  @property({type: Boolean}) profileDropdownOpen = false;
  @property({type: Array}) profileDropdown = [
    {label:'My Profile', href: 'profile'},
    // {label:'Customize', value: 'customize-tags'},
    // {label:'Saved', value: 'saved'},
    {label:'Upgrade/Downgrade', href: 'upgrade-downgrade'},
    {label:'Settings', href: 'settings'},
    {label:'Help Center', value: 'help-center'},
    {label:'Report Problems', value: 'report-problems'},
    {label:'Log Out', value: 'log-out'}
  ];
  @property({type: String}) subRoute = '';
  @property({type: Object}) highlighter = null;
  @property({type: Array}) shikiLoadingLangs = [];
  @property({type: Array}) shikiLoadedLangs = [];
  @property({type: Object}) messageRenderLookup = {};
  @property({type: String}) theme = "";
  @property({type: Object}) katex = null;
  @property({type: Boolean}) katexLoading = false;
  @property({type: Object}) katexQueue = {};
  @property({type: Object}) fetchChatController = undefined;
  @property({type: Array}) stencils = [0,1,2,3,4,5];

  connectedCallback() {
    super.connectedCallback();
  }

  async initHighlighter() {
    const { createHighlighter } = await import('shiki');
    this.highlighter = await createHighlighter({
      themes: [
        'vitesse-dark',
        'min-light'
      ]
    });
  }

  formatDateTime(date) {
    const now = new Date();

    if (isToday(date)) {
      return format(date, 'h:mm a');
    } else if (isYesterday(date)) {
      return `yesterday at ${format(date, 'h:mm a')}`;
    } else if (differenceInDays(now, date) <= 7) {
      return format(date, 'EEE, MMM do h:mm a');
    } else {
      return format(date, 'EEE, MMM do h:mm a');
    }
  }

  constructor() {
    super();
    this.updateTitleTimeout = null;
    document.addEventListener('keydown', (function(e) {
        if (event.key === 'Enter' && event.ctrlKey) {
            this.sendMsg();
        }
    }).bind(this));
    document.addEventListener('click', (function(e) {
      if (this.chatboxExpanded) {
        const userValue = this.shadowRoot.getElementById("commentText").value;
        if (!(userValue==="")) { return; }
        const path = e.composedPath();
        let insideMenuClick = false;
        if(!(path === undefined || path.length == 0)) {
         insideMenuClick = path.find((el) => { return el == this; });
        }
        if(insideMenuClick) {
          //inside element
        } else {
          //outside element
          this.handleBlur();
          const event = new CustomEvent('blur', { chatboxExpanded: this.chatboxExpanded, bubbles: true });
          this.dispatchEvent(event);
        }
      }
    }).bind(this), false);

    this.initHighlighter();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());

    setTimeout((function(){
      this.shadowRoot.getElementById("commentText").select();
    }).bind(this), 500);

    // this.chatMessages = [
    //   // { timestamp: '2024-06-21T16:58:00Z', chat_id: '12345', sender_name: 'Andy Martinez', sender_type: 'user', message_type: 'bookend', message_content: 'Chat started by Andy Martinez', meta_info: '4:58 PM', avatar_initials: 'AM' },
    //   // { timestamp: '2024-06-21T16:59:00Z', chat_id: '12345', sender_name: 'Andy Martinez', sender_type: 'user', message_type: 'inbound', message_content: '', meta_info: '4:59 PM', avatar_initials: 'AM' },
    //   // { timestamp: '2024-06-21T17:02:00Z', chat_id: '12345', sender_name: 'Jason Dewar', sender_type: 'agent', message_type: 'outbound', message_content: 'Hi Andy, thank you for contacting Widget Support. Can you please tell me what language you are trying to program on your CloudWidget?', meta_info: '5:02 PM' },
    //   // { timestamp: '2024-06-21T17:19:00Z', chat_id: '12345', sender_name: 'Jason Dewar', sender_type: 'agent', message_type: 'event', message_content: 'sent a transfer request to Technical Support Team', meta_info: '5:19 PM', event_details: { action: 'transfer request', target: 'Technical Support Team' } }
    // ];
    // this.chatMessages.push({ 
    //   timestamp: '2024-06-21T16:58:00Z', 
    //   chat_id: '12345', 
    //   sender_name: 'displayName', 
    //   sender_type: 'user', 
    //   message_type: 'bookend', 
    //   message_content: 'Chat started by ', 
    //   meta_info: '4:58 PM', 
    //   avatar_initials: 'AM'
    // });
    // this.chatMessages.push({ 
    //   timestamp: '2024-06-21T16:59:00Z', 
    //   chat_id: '12345', 
    //   sender_name: 'ChatGPT 4', 
    //   sender_type: 'agent', 
    //   message_type: 'inbound', 
    //   message_content: 'This is some dummy text', 
    //   loading: true,
    //   meta_info: '4:59 PM', 
    //   avatar_initials: 'PT' 
    // });
  }

  processChatData(chatData) {
    var displayName = "";
    if (this.user && this.user.displayName) {
      displayName = this.user.displayName;
    }

    var newChatData = [];
    for (var i = 0; i < chatData.length; i++) {
      if (chatData[i].message_type==='bookend') {
        var lastConvoModel = "";
        if (chatData[i].event_details) {
          for (var ii = 0; ii < chatData[i].event_details.length; ii++) {
            if (chatData[i].event_details[ii].action === "model") {
              lastConvoModel = chatData[i].event_details[ii].target;
            }
          }
        }
        if (!(lastConvoModel==="")) {
          this.lastConvoModel = lastConvoModel;
        }
        newChatData.push({
          id: crypto.randomUUID(),
          timestamp: chatData[i].timestamp, 
          chat_id: chatData[i].chat_id, 
          sender_name: displayName, 
          sender_type: 'user', 
          message_type: 'bookend', 
          message_content: 'Chat started by ' + displayName, 
          meta_info: this.formatDateTime(chatData[i].timestamp)
        });
      }
      else if (chatData[i].message_type==='outbound') {
        newChatData.push({ 
          id: crypto.randomUUID(),
          timestamp: chatData[i].timestamp, 
          chat_id: chatData[i].chat_id, 
          sender_name: displayName, 
          sender_type: 'user', 
          message_type: 'outbound', 
          message_content: chatData[i].message_content, 
          meta_info: this.formatDateTime(chatData[i].timestamp)
        });
      }
      else if (chatData[i].message_type==='inbound') {
        newChatData.push({ 
          id: crypto.randomUUID(),
          timestamp: chatData[i].timestamp, 
          chat_id: chatData[i].chat_id, 
          sender_name: chatData[i].sender_name,  
          sender_type: 'agent', 
          message_type: 'inbound', 
          message_content: chatData[i].message_content, 
          meta_info: this.formatDateTime(chatData[i].timestamp),
          avatar_initials: this.generateAbbreviation(chatData[i].sender_name)
        });
      }
      else if (chatData[i].message_type==='event') {
        var lastConvoModel = "";
        if (chatData[i].event_details) {
          for (var ii = 0; ii < chatData[i].event_details.length; ii++) {
            if (chatData[i].event_details[ii].action === "model") {
              lastConvoModel = chatData[i].event_details[ii].target;
            }
          }
        }
        if (!(lastConvoModel==="")) {
          this.lastConvoModel = lastConvoModel;
        }
        newChatData.push({
          id: crypto.randomUUID(),
          timestamp: chatData[i].timestamp, 
          chat_id: chatData[i].chat_id, 
          sender_name: displayName,  
          sender_type: 'agent', 
          message_type: 'event', 
          message_content: chatData[i].message_content, 
          meta_info: this.formatDateTime(chatData[i].timestamp)
        });
      }
    }
    return newChatData.sort((a, b) => {
      if (a.message_type === 'bookend' && b.message_type !== 'bookend') {
        return -1; // a should come before b
      }
      if (a.message_type !== 'bookend' && b.message_type === 'bookend') {
        return 1; // b should come before a
      }
      return 0; // no change in order
    });
  }

  async fetchChat(chatId, retry = 0) {
    const apiKey = this.getApiKey();
    if (!apiKey) {
      if (retry < 5) {
        setTimeout((function() {
          this.fetchChat(chatId, retry + 1)
        }).bind(this), 100 * retry);
      }
      return null;
    }
    try {
      if (this.fetchChatController && !this.fetchChatController.signal.aborted) {
        this.fetchChatController.abort("User chose to fetch a different chat");
      }
      const controller = new AbortController();
      const signal = controller.signal;
      this.fetchChatController = controller;
      const response = await fetch(window.endpoint+'/api/chat/'+chatId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': apiKey
        },
        signal: signal
      });
      this.fetchChatController = undefined;


      if (response.status === 401) {
        // Handle 401 unauthorized response
        throw new Error('auth/invalid-api-key');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const chatData = await response.json();
      const processedChatsData = this.processChatData(chatData);
      this.chatMessages = processedChatsData;
      this.chatMessageLoading = false;
      this.requestUpdate()
    } catch (error) {
      const myError = {type: error.message.split(" ").join("+"), title: error.message, status: 500, detail: error.message};
      this.error = myError;
    }
  }

  async fetchChats() {
    const apiKey = this.getApiKey();
    try {
      const response = await fetch(window.endpoint+'/api/chats', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': apiKey
        }
      });


      if (response.status === 401) {
        // Handle 401 unauthorized response
        throw new Error('auth/invalid-api-key');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const chatsData = await response.json();
      // chatsData[0].chat_name = "Untitled Chat Untitled Chat Untitled Chat Untitled Chat Untitled Chat Untitled Chat Untitled Chat #22";
      store.dispatch(setChats(chatsData));
      this.requestUpdate()
    } catch (error) {
      const myError = {type: error.message.split(" ").join("+"), title: error.message, status: 500, detail: error.message};
      this.error = myError;
    }
  }

  stateChanged(state) {
    this.user = state.user;
    this.targetModel = state.model;
    const token = state.token;
    this.subRoute = state.route;
    if (!(token===this.accessToken) && this.subRoute === "chat") {
      this.fetchChats();
      this.accessToken = token;
    }
    if (!(state.chat_id === this.loadChatId)) {
      this.loadChatId = state.chat_id;
      if (this.loadChatId != "") {
        this.chatMessageLoading = true;
        this.fetchChat(this.loadChatId);
      }
    }
    if ((state.chat_id === '') || (this.chatUuid != state.chat_id)) {
      this.chatUuid = state.chat_id;
      if (this.chatUuid === '') {
        this.chatMessages = [];
      }
    } 
    if (state.chats) {
      this.chatThreads = state.chats;
    }
    if (!(this.theme===state.theme)) {
      this.theme = state.theme;
      const elementTarget = this.shadowRoot.getElementById("messages");
      if (!elementTarget) {
        setTimeout((function(){
          setElementTheme(this.shadowRoot.getElementById("messages"), this.theme)
        }).bind(this))
      }
      else
      {
        setElementTheme(elementTarget, this.theme);
      }
    }
  }

  handleFocus() {
    this.chatboxExpanded = true;
  }

  handleBlur() {
    this.chatboxExpanded = false;
    this.chatboxActive = false;
    // const commentText = this.shadowRoot.getElementById("commentText");
    // commentText.style.height = 'inherit';
  }

  handleClick() {
    this.chatboxActive = true;
    setTimeout((function(){
      const messageContainer = this.shadowRoot.querySelector('#messages');
      const scrollTop = document.documentElement.scrollTop;
      const isNearBottom = scrollTop + window.innerHeight >= document.documentElement.scrollHeight - 100;
      if (isNearBottom) {
        messageContainer.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
      }
    }).bind(this), 50);
  }

  getApiKey() {
    var apiKey = null;
    if (this.user && this.user.customAttributes) {
      const custom = JSON.parse(this.user.customAttributes);
      if (custom.apiKey) {
        apiKey = custom.apiKey;
      }
    }
    if (this.user && this.user.customClaims) {
      const custom = this.user.customClaims;
      if (custom.apiKey) {
        apiKey = custom.apiKey;
      }
    }
    return apiKey;
  }

  createHistory() {
    const history = new History();
    const entry = new HistoryEntry();
    entry.setRole(HistoryEntry.Role.SYSTEM); // Assuming Role enum is available
    entry.setContent(``);
    history.addEntries(entry);
    if (this.chatMessages.length>0) {
      for (var i = 0; i < this.chatMessages.length; i++) {
        const msg = this.chatMessages[i];
        // Create a history entry
        if (msg.message_type === "outbound") {
          const entry = new HistoryEntry();
          entry.setRole(HistoryEntry.Role.USER); // Assuming Role enum is available
          entry.setContent(msg.message_content);
          history.addEntries(entry);
        }
        else if (msg.message_type === "inbound") {
          const entry = new HistoryEntry();
          entry.setRole(HistoryEntry.Role.AGENT); // Assuming Role enum is available
          entry.setContent(msg.message_content);
          history.addEntries(entry);
        }
      }
    }
    return history;
  }

  generateAbbreviation(input) {
    const words = input.split(" ");
    if (words.length < 2) {
      if (words.length === 1 && words[0].length >= 2) {
        return (words[0][0] + words[0][1]).toUpperCase();
      }
      else {
        return "";
      }
    }

    let firstChar = '';
    let secondChar = '';

    // Find the first character that is a letter or number
    for (let word of words) {
        if (/[a-zA-Z]/.test(word[0])) {
            firstChar = word[0];
            break;
        }
    }
    // If no letter is found, use the first number
    if (!firstChar) {
        for (let word of words) {
            if (/\d/.test(word[0])) {
                firstChar = word[0];
                break;
            }
        }
    }

    // Find the second character that is a letter or number
    for (let word of words) {
        if (/[a-zA-Z]/.test(word[0]) && word[0] !== firstChar) {
            secondChar = word[0];
            break;
        }
    }
    // If no letter is found, use the first number (different from firstChar)
    if (!secondChar) {
        for (let word of words) {
            if (/\d/.test(word[0]) && word[0] !== firstChar) {
                secondChar = word[0];
                break;
            }
        }
    }

    const abbreviation = (firstChar + secondChar).toUpperCase();
    return abbreviation;
  }

  sortElements(elements) {
    // return elements;
    const newElements = [];
    let currentOl = null;

    elements.forEach((element) => {
      if (element.type === 'ol') {
        if (!currentOl) {
          currentOl = { ...element, children: [...element.children] };
          newElements.push(currentOl);
        } else {
          currentOl.children.push(...element.children);
        }
      } else if (element.type === 'ul') {
        const lastElement = newElements[newElements.length - 1];
        if (lastElement && lastElement.type === 'ol') {
          const lastLi = lastElement.children[lastElement.children.length - 1];
          if (lastLi) {
            if (!lastLi.children) {
              lastLi.children = [];
            }
            lastLi.children.push({ ...element });
          } else {
            lastElement.children.push({ type: 'li', children: [{ ...element }] });
          }
        } else {
          newElements.push(element);
        }
      } else if (element.type === 'p') {
        newElements.push(element);
        currentOl = null;
      } else {
        newElements.push(element);
        currentOl = null;
      }
    });

    return newElements;
  }

  renderMsgId(msgId) {
    const containers = this.shadowRoot.querySelectorAll('.message-container');
    const lookupMap = {};
    for (var i = 0; i < this.chatMessages.length; i++) {
      lookupMap[this.chatMessages[i].id] = this.chatMessages[i];
    }
    containers.forEach(((container, index) => {
        const targetMsgId = container.dataset.id; // Replace with actual way of getting message content
        if (msgId === targetMsgId && lookupMap[targetMsgId]) {
          const fragment = document.createDocumentFragment();
          var elements = this.processAIOutput(lookupMap[targetMsgId].message_content, lookupMap[targetMsgId].loading, targetMsgId);
          elements = this.sortElements(elements);
          container.innerHTML = '';
          this.renderElements(elements, fragment, targetMsgId);
          container.appendChild(fragment);
        }
        else {
          console.log("Render lookup not found!");
        }
    }).bind(this));
  }

  updated(changedProperties) {
    if (changedProperties.has('chatMessages')) {
      setTimeout((function(){
        const messageContainer = this.shadowRoot.querySelector('#messages');
        messageContainer.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
      }).bind(this), 50);
    }
    if (changedProperties.has('chatMessages') || changedProperties.has('highlighter') || changedProperties.has('shikiLoadedLangs')) {
      const containers = this.shadowRoot.querySelectorAll('.message-container');

      const lookupMap = {};
      for (var i = 0; i < this.chatMessages.length; i++) {
        lookupMap[this.chatMessages[i].id] = this.chatMessages[i];
      }

      containers.forEach(((container, index) => {
          const msgId = container.dataset.id; // Replace with actual way of getting message content
          if (lookupMap[msgId]) {
            var elements = this.processAIOutput(lookupMap[msgId].message_content, lookupMap[msgId].loading, msgId);
            elements = this.sortElements(elements);
            container.innerHTML = '';
            this.renderElements(elements, container, msgId);
          }
          else {
            console.log("Render lookup not found!");
          }
      }).bind(this));
    }
  }

  parseCommands(input) {
    // Regular expressions to match commands
    const commandRegex = /command\s*\(\s*name\s*=\s*"(.*?)"\s*,\s*args\s*=\s*\[\s*'(.*?)'\s*,\s*'(.*?)'\s*\]\s*\)/;

    // Extract command
    let args = input.match(commandRegex);
    if (args && args[1]) {
      const commandName = args[1];
      args = args.splice(2);
      debugger;
    }

    return args;
  }

  parseTitle(input) {
    const result = {
      title: null
    };

    // Regular expressions to match title
    const titleRegex = /Title:\s*(.*)/;

    // Extract title
    const titleMatch = input.match(titleRegex);
    if (titleMatch && titleMatch[1]) {
      result.title = titleMatch[1].trim().trim('\n');
    }

    if (!result.title) {
      result['title'] = input.trim().trim('\n').trim('// ').substr(0, 60);
    }

    return result;
  }

  async updateTitle(new_title, retry = 0) {
    const apiKey = this.getApiKey();
    const chat_id = this.chatUuid;
    const url = window.endpoint+`/api/chat/${chat_id}/title`; // Update with your actual API endpoint
    const payload = {
      title: new_title
    };

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response.status === 404) {
        if (retry <= 3) {
          setTimeout(() => {
            this.updateTitle(new_title, retry + 1);
          }, 2000+(retry*2000));
          return null;
        }
      }

      if (!response.ok) {
        throw new Error('Failed to update title');
      }

      const data = await response.json();
      console.log('Title updated successfully:', chat_id, data);
    } catch (error) {
      console.error('Error updating title:', error);
    }
  }

  updateTitleEventually(new_title) {
    if (this.updateTitleTimeout) {
      clearTimeout(this.updateTitleTimeout);
    }

    const newTitle = new_title;

    this.updateTitleTimeout = setTimeout(() => {
      this.updateTitle(newTitle);
    }, 500); // Debounce time in milliseconds
  }

  waitForChatId(uuid, parsedData, callback) {
    const checkInterval = 200; // Check every 200ms
    const maxAttempts = 500; // Give up after 500 attempts (100 seconds)

    let attempts = 0;
    const intervalId = setInterval(() => {
      attempts++;
      for (var i = 0; i < this.chatThreads.length; i++) {
        if (this.chatThreads[i].chat_id === uuid) {
          if (!(this.chatThreads[i].chat_name === parsedData.title)) {
            this.chatThreads[i].chat_name = parsedData.title;
            store.dispatch(setChats(this.chatThreads));
          }
          let lastAgentIndex = this.chatMessages.length;
          if (!this.chatMessages[lastAgentIndex-1].loading) {
            clearInterval(intervalId);
            callback(parsedData.title);
          }
          return;
        }
      }
      if (attempts >= maxAttempts) {
        clearInterval(intervalId);
        console.error('Chat ID not found within the allowed time frame.');
      }
    }, checkInterval);
  }

  async sendSecondProtobufRequest() {
    const apiKey = this.getApiKey();
    var displayName = "";
    if (this.user && this.user.displayName) {
      displayName = this.user.displayName;
    }
    const userValue = this.shadowRoot.getElementById("commentText").value;
    if (userValue=="" && this.chatMessages.length>0) {
      if (this.chatMessages[this.chatMessages.length-1].sender_type==="agent")
      {
        this.chatMessages.pop();
      }
    }
    const history = new History();
    const entry = new HistoryEntry();
    entry.setRole(HistoryEntry.Role.SYSTEM); // Assuming Role enum is available
    entry.setContent(`Return a short title for the conversation and whether the user requests to remember something (query + time string), reply according to the following format (do not return anything else!):
        Title: Title for Conversation
        Remember::User's favorite programming languages::Yesterday (optional)
      `);
    history.addEntries(entry);
    const convoHistory = history;
    if (!(userValue==""))
    {
      // Create a history entry
      const entry = new HistoryEntry();
      entry.setRole(HistoryEntry.Role.USER); // Assuming Role enum is available
      entry.setContent(userValue);
      convoHistory.addEntries(entry); // Assuming addEntries method is available
    }

    const agentName = this.targetModel.label;
    // Create the RequestData
    const requestData = new RequestData();
    requestData.setHistory(convoHistory);
    requestData.setModel(this.targetModel.value);
    requestData.setSinglefile(true);
    // requestData.setOutputfilename("default");
    // // requestData.setNoinfo(noinfo);
    requestData.setMaxtokens(1024);
    // requestData.setTemperature(0.7);
    const bytes = requestData.serializeBinary();
    const uuid = this.chatUuid;
    const response = await fetch(window.endpoint+'/api/v1/text', {
      method: 'POST',
      headers: {
        'Authorization': apiKey,
        'Content-Type': 'application/x-protobuf',
      },
      body: bytes
    });

    const reader = response.body.getReader();
    let buffer = new Uint8Array();
    const startTime = new Date();
    var output = "";

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        console.log("End of stream reached #2");
        // console.log("output", output);
        break;
      }

      buffer = this.concatenateUint8Arrays(buffer, value);
      while (buffer.length > 0) {
        const { message, remainingBuffer } = this.parseProtobufMessage(buffer);
        if (!message) break;
        
        // Process the decoded message
        const status = message.getStatus();
        if (status == 0) {
          const schema = message.getSchema();
          if (schema) {
            const strOutput = schema.getStroutput();
            output += strOutput;
            const parsedData = this.parseTitle(output);
            if (parsedData.title) {
              const newTitle = parsedData.title;
              this.waitForChatId(uuid, parsedData, (title) => {
                this.updateTitleEventually(title);
              });
            }
            // console.log(message.getStatus());
          }
          else
          {
            console.log("sendSecondProtobufRequest", {output_tokens:message.getOutputtokens(), input_tokens:message.getInputtokens()})
          }
        }
        else if (status != 200) {
          const myError = {type: message.getType(), title: message.getTitle(), status: message.getStatus(), detail: message.getDetail()};
          this.error = myError;
          console.error(myError);
        }
        buffer = remainingBuffer;
      }
      // console.log("Done!")
    }
  }

  async sendProtobufRequest() {
    const apiKey = this.getApiKey();
    if (this.chatMessages.length===0) {
      this.isFirstRequest = true;
    }
    if (this.chatUuid.length===0) {
      this.chatUuid = crypto.randomUUID()
      this.loadChatId = this.chatUuid;
      store.dispatch(loadChat(this.chatUuid));
    }
    var displayName = "";
    if (this.user && this.user.displayName) {
      displayName = this.user.displayName;
    }
    const userValue = this.shadowRoot.getElementById("commentText").value;
    if (userValue=="" && this.chatMessages.length>0) {
      if (this.chatMessages[this.chatMessages.length-1].sender_type==="agent")
      {
        this.chatMessages.pop();
      }
    }
    const convoHistory = this.createHistory(); // Implement createHistory function
    if (!(userValue==""))
    {
      // Create a history entry
      const entry = new HistoryEntry();
      entry.setRole(HistoryEntry.Role.USER); // Assuming Role enum is available
      entry.setContent(userValue);
      convoHistory.addEntries(entry); // Assuming addEntries method is available
    }

    const agentName = this.targetModel.label;
    // Create the RequestData
    const requestData = new RequestData();
    requestData.setHistory(convoHistory);
    requestData.setModel(this.targetModel.value);
    requestData.setSinglefile(true);
    // requestData.setOutputfilename("default");
    // // requestData.setNoinfo(noinfo);
    requestData.setMaxtokens(4096);
    // requestData.setTemperature(0.7);
    const bytes = requestData.serializeBinary();
    const uuid = this.chatUuid;
    const response = await fetch(window.endpoint+'/api/v1/text', {
      method: 'POST',
      headers: {
        'Authorization': apiKey, 
        'ThreadId': uuid,
        'Content-Type': 'application/x-protobuf',
      },
      body: bytes
    });

    const reader = response.body.getReader();
    let buffer = new Uint8Array();
    let initChat = false;
    let lastAgentIndex = this.chatMessages.length;
    const startTime = new Date();

    if (this.chatMessages.length>0 && this.lastConvoModel!="" && this.lastConvoModel!=this.targetModel.value) {
      this.chatMessages.push({ 
        timestamp: startTime.toISOString(), 
        chat_id: uuid, 
        sender_name: displayName, 
        sender_type: 'user', 
        message_type: 'event', 
        message_content: `Switched Model To "${agentName}"`, 
        meta_info: this.formatDateTime(startTime),
      });
      lastAgentIndex+=1;
    }
    this.lastConvoModel = this.targetModel.value;
    
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        console.log("End of stream reached");
        this.sendingMsg = false;
        this.requestUpdate();
        if (lastAgentIndex>0) {
          if (this.chatMessages[lastAgentIndex])
          {
            this.chatMessages[lastAgentIndex].loading = false;
            this.renderMsgId(this.chatMessages[lastAgentIndex].id);
          }
          else
          {
            this.chatMessages[lastAgentIndex-1].loading = false;
            this.renderMsgId(this.chatMessages[lastAgentIndex-1].id);
          }
        }
        break;
      }

      buffer = this.concatenateUint8Arrays(buffer, value);
      while (buffer.length > 0) {
        const { message, remainingBuffer } = this.parseProtobufMessage(buffer);
        if (!message) break;
        
        // Process the decoded message
        const status = message.getStatus();
        if (status == 0) {
          const schema = message.getSchema();
          if (schema) {
            const strOutput = schema.getStroutput();
            if (!initChat) {
              var commentText = this.shadowRoot.getElementById("commentText");
              commentText.value = "";
              commentText.style.height = '';
              if (lastAgentIndex===0) {
                this.chatMessages.push({ 
                  timestamp: startTime.toISOString(), 
                  chat_id: uuid, 
                  sender_name: displayName, 
                  sender_type: 'user', 
                  message_type: 'bookend', 
                  message_content: 'Chat started by ' + displayName, 
                  meta_info: this.formatDateTime(startTime)
                });
                if (!this.chatThreads) {
                  this.chatThreads = [];
                }
                this.chatThreads.unshift({chat_id:uuid, chat_name:'Untitled Chat', timestamp:startTime.toISOString(), last_seen:startTime.toISOString()});
                store.dispatch(setChats(this.chatThreads));
                history.pushState({}, null, "/chat/"+this.chatUuid);
                this.requestUpdate()
              }    
              if (!(userValue==""))
              {
                this.chatMessages.push({ 
                  id: crypto.randomUUID(),
                  timestamp: startTime.toISOString(), 
                  chat_id: uuid, 
                  sender_name: displayName, 
                  sender_type: 'user', 
                  message_type: 'outbound', 
                  message_content: userValue, 
                  meta_info: this.formatDateTime(startTime)
                });
              }
              // this.chatMessages.push();
              // this.chatMessages.push();
              this.chatMessages.push({ 
                id: crypto.randomUUID(),
                timestamp: startTime.toISOString(), 
                chat_id: uuid, 
                sender_name: agentName, 
                sender_type: 'agent', 
                message_type: 'inbound', 
                message_content: '', 
                meta_info: this.formatDateTime(startTime), 
                avatar_initials: this.generateAbbreviation(agentName),
                loading: true
              });
              // this.requestUpdate()
              if (lastAgentIndex===0) {    
                lastAgentIndex += 2;
              }
              else if (!(userValue==""))
              {
                lastAgentIndex += 1;
              }
              if (this.chatMessages[lastAgentIndex].id) {
                this.requestUpdate();
                this.renderMsgId(this.chatMessages[lastAgentIndex].id);
              }
              initChat = true;
            }
            this.chatMessages[lastAgentIndex].message_content += strOutput;
            this.chatMessages[lastAgentIndex].loading = true;
            const timeNow = new Date();
            this.chatMessages[lastAgentIndex].timestamp = timeNow.toISOString();
            this.chatMessages[lastAgentIndex].meta_info = this.formatDateTime(startTime);
            // this.requestUpdate()
            if (this.chatMessages[lastAgentIndex].id) {
              this.requestUpdate();
              this.renderMsgId(this.chatMessages[lastAgentIndex].id);
            }
            setTimeout((function(){
              const messageContainer = this.shadowRoot.querySelector('#messages');
              const scrollTop = document.documentElement.scrollTop;
              const isNearBottom = scrollTop + window.innerHeight >= document.documentElement.scrollHeight - 100;
              if (isNearBottom) {
                messageContainer.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
              }
            }).bind(this), 50);
            // console.log(message.getStatus());
          }
          else
          {
            console.log("sendProtobufRequest", {output_tokens:message.getOutputtokens(), input_tokens:message.getInputtokens()})
          }
        }
        else if (status != 200) {
          const myError = {type: message.getType(), title: message.getTitle(), status: message.getStatus(), detail: message.getDetail()};
          this.error = myError;
          console.error(myError);
        }
        buffer = remainingBuffer;
      }
      // console.log("Done!")
    }
  }

  concatenateUint8Arrays(a, b) {
    const concatenated = new Uint8Array(a.length + b.length);
    concatenated.set(a, 0);
    concatenated.set(b, a.length);
    return concatenated;
  }

  parseProtobufMessage(buffer) {
    const [length, lengthBytes] = this.readUvarint(buffer);
    if (buffer.length < lengthBytes + length) {
      return { message: null, remainingBuffer: buffer };
    }

    const messageBytes = buffer.slice(lengthBytes, lengthBytes + length);
    const message = ResponseData.deserializeBinary(messageBytes);
    const remainingBuffer = buffer.slice(lengthBytes + length);

    return { message, remainingBuffer };
  }

  readUvarint(buffer) {
    let result = 0;
    let shift = 0;
    let lengthBytes = 0;

    for (let i = 0; i < buffer.length; i++) {
      lengthBytes++;
      const byte = buffer[i];
      result |= (byte & 0x7F) << shift;
      if ((byte & 0x80) === 0) {
        break;
      }
      shift += 7;
    }

    return [result, lengthBytes];
  }

  isWithinLastHour(timestampStr) {
    // Parse the input timestamp string to a Date object
    const timestamp = new Date(timestampStr);

    // Get the current time
    const currentTime = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentTime - timestamp;

    // Check if the time difference is less than or equal to one hour (in milliseconds)
    return timeDifference <= 3600000; // 3600000 milliseconds = 1 hour
  }

  sendMsg() {
    this.error = null;
    if (this.sendingMsg) { return; }
    if (!this.user) {
      this.sendingMsg = true;
      return;
    }
    this.sendingMsg = true;
    this.sendProtobufRequest();
    if (this.isFirstRequest) {
      this.sendSecondProtobufRequest();
      this.isFirstRequest = false;
    }

    for (var i = 0; i < this.chatThreads.length; i++) {
      if (this.chatThreads[i].chat_id===this.chatUuid) {
        if (!this.isWithinLastHour(this.chatThreads[i].timestamp)) {
          this.updateTitle(this.chatThreads[i].chat_name);
          const timeNow = new Date();
          this.chatThreads[i].timestamp = timeNow.toISOString();
          store.dispatch(setChats(this.chatThreads));
        }
      }
    }
    // this.updateTitle(newTitle);
  }

  handleLaTeX(line) {
      const latexInlineRegex = /\\\(([\s\S]*?)\\\)/g;
      const latexDisplayRegex = /\\\[([\s\S]*?)\\\]/g;

      const handleLaTeXLine = (line) => {
        const latexInlineMatches = [...line.matchAll(latexInlineRegex)];
        const latexDisplayMatches = [...line.matchAll(latexDisplayRegex)];
        const parts = [];
        let lastIndex = 0;

        latexInlineMatches.forEach(match => {
            if (match.index > lastIndex) {
                parts.push(line.substring(lastIndex, match.index));
            }
            parts.push({
                type: 'latex',
                content: match[1],
                displayMode: false
            });
            lastIndex = match.index + match[0].length;
        });

        latexDisplayMatches.forEach(match => {
            if (match.index > lastIndex) {
                parts.push(line.substring(lastIndex, match.index));
            }
            parts.push({
                type: 'latex',
                content: match[1],
                displayMode: true
            });
            lastIndex = match.index + match[0].length;
        });

        if (lastIndex < line.length) {
            parts.push(line.substring(lastIndex));
        }

        var newElements = [];
        for (var i = 0; i < parts.length; i++) {
          if (typeof parts[i] === "string") {
            // Split text by newline characters first
            newElements = newElements.concat(this.formatText(parts[i]));
          }
          else if (!(parts[i].type==="latex")) {
            newElements = newElements.concat(this.formatText(parts[i].content));
          }
          else
          {
            newElements.push(parts[i]);
          }
        }

        return newElements;
      };

      return handleLaTeXLine(line);
  }

  processAIOutput(aiOutput, isTyping, msgId) {
      let elements = [];
      let codeBlock = '';
      let inCodeBlock = false;
      let language = '';
      let inList = false;
      let listType = '';
      let listItems = [];
      let inTable = false;
      let tableHeader = [];
      let tableBody = [];
      let lineCount = 0;

      const lines = aiOutput.split(/\n|\\n/);

      for (let line of lines) {
          if (line.startsWith('```')) {
              if (inCodeBlock) {
                if (language==="latex") {
                  const contentParts = this.handleLaTeX(codeBlock);
                  listItems.push({ type: 'ul', children: [{ type: 'li', content: contentParts }] });
                  codeBlock = '';
                  inCodeBlock = false;
                  language = '';
                }
                else
                {
                  elements.push(this.createCodeBlockElement(codeBlock, language, msgId));
                  codeBlock = '';
                  inCodeBlock = false;
                  language = '';
                }
              } else {
                  inCodeBlock = true;
                  language = line.slice(3).trim();
                  if (language.substr(0, "command".length) === "command") {
                    const command = this.parseCommands(language);
                    language = "command";
                  }
              }
          } else if (inCodeBlock) {
              codeBlock += line + '\n';
                debugger;
          } else if (line.startsWith('|') && line.endsWith('|')) {
              if (!inTable) {
                  inTable = true;
                  tableHeader = line.split('|').slice(1, -1).map(cell => ({ type: 'th', content: this.formatText(cell.trim()) }));
              } else if (line.includes('|-')) {
                  // Table separator, ignore
              } else {
                  tableBody.push(line.split('|').slice(1, -1).map(cell => ({ type: 'td', content: this.formatText(cell.trim()) })));
              }

              if (lineCount === lines.length-1) {
                elements.push({
                    type: 'table',
                    className: 'slds-table slds-table_bordered slds-table_cell-buffer',
                    children: [
                        { type: 'thead', children: [{ type: 'tr', children: tableHeader }] },
                        { type: 'tbody', children: tableBody.map(row => ({ type: 'tr', children: row })) }
                    ]
                });
                inTable = false;
                tableHeader = [];
                tableBody = [];
              }
          } else if (inTable && line === '') {
              elements.push({
                  type: 'table',
                  className: 'slds-table slds-table_bordered slds-table_cell-buffer',
                  children: [
                      { type: 'thead', children: [{ type: 'tr', children: tableHeader }] },
                      { type: 'tbody', children: tableBody.map(row => ({ type: 'tr', children: row })) }
                  ]
              });
              inTable = false;
              tableHeader = [];
              tableBody = [];
          } else if (line.match(/^[0-9]+\./)) {
              if (!inList || listType !== 'ol') {
                  if (inList) {
                      elements.push(this.createListElement(listType, listItems));
                      listItems = [];
                  }
                  listType = 'ol';
                  inList = true;
              }
              const contentParts = this.handleLaTeX(line.substring(line.indexOf('.') + 1).trim());
              listItems.push({ type: 'li', content: contentParts });
          } else if (line.trim().startsWith('•') || line.trim().startsWith('-') || line.trim().startsWith('*')) {
              if (!inList || listType !== 'ul') {
                  if (inList) {
                      elements.push(this.createListElement(listType, listItems));
                      listItems = [];
                  }
                  listType = 'ul';
                  inList = true;
              }
              const contentParts = this.handleLaTeX(line.trim().substring(1));
              listItems.push({ type: 'li', className: 'slds-m-top_xxx-small slds-m-bottom_xxx-small', content: contentParts });
          } else if (line.startsWith('>')) {
              const contentParts = this.handleLaTeX(line.substring(1).trim());
              elements.push({
                  type: 'blockquote',
                  className: 'slds-quote',
                  style: 'border-left: 4px solid #dddbda; padding-left: 0.5rem;',
                  content: contentParts
              });
          } else if (line.startsWith('#')) {
              const level = line.split(' ')[0].length;
              const text = line.substring(level + 1);
              const contentParts = this.handleLaTeX(text);
              elements.push({
                  type: `h${level}`,
                  className: `slds-m-top_x-small slds-m-bottom_x-small slds-page-header__title slds-text-heading_${['large', 'medium', 'small'][level - 1] || 'small'}`,
                  content: contentParts
              });
          } else if (line === '---') {
              elements.push({ type: 'hr', className: 'slds-m-vertical_medium' });
          } else if (line === '') {
              if (inList) {
                  elements.push(this.createListElement(listType, listItems));
                  inList = false;
                  listItems = [];
              }
          } else {
              if (inList) {
                  elements.push(this.createListElement(listType, listItems));
                  inList = false;
                  listItems = [];
              }

              const contentParts = this.handleLaTeX(line.trim());
              elements.push({ type: 'p', className: 'slds-m-top_xx-small slds-m-bottom_xx-small', content: contentParts });
          }
          lineCount++;
      }

      // Handle any remaining code block
      if (inCodeBlock) {
          elements.push(this.createCodeBlockElement(codeBlock, language, msgId));
      }

      // Close any open list
      if (inList) {
          elements.push(this.createListElement(listType, listItems));
      }

      if (isTyping) {
        const loaderHtml = {
          type: 'span',
          className: 'slds-icon-typing slds-is-animated slds-m-left_xx-small',
          title: 'Chatbot is typing',
          children: [
              { type: 'span', className: 'slds-icon-typing__dot' },
              { type: 'span', className: 'slds-icon-typing__dot' },
              { type: 'span', className: 'slds-icon-typing__dot' },
              { type: 'span', className: 'slds-assistive-text', content: 'Chatbot is typing' }
          ]
        };
        if (elements[elements.length-1]) {
          if (elements[elements.length-1].children)
          {
            var childCount = elements[elements.length-1].children.length;
            if (elements[elements.length-1].children[childCount-1].content) {
              elements[elements.length-1].children[childCount-1].content.push(loaderHtml);
            }
            else if (elements[elements.length-1].children[childCount-1].children) {
              elements[elements.length-1].children[childCount-1].children.push(loaderHtml);
            }
            else {
              debugger;
            }
          }
          else if (typeof elements[elements.length-1].content === 'string') {
            var contentStr = elements[elements.length-1].content;
            elements[elements.length-1].content = [];
            elements[elements.length-1].content.push(contentStr);
            elements[elements.length-1].content.push(loaderHtml);
          }
          else
          {
            elements[elements.length-1].content.push(loaderHtml);
          }
        }
        else {
          elements.push(loaderHtml);
        }
      }

      return elements;
  }

  formatText(text) {
    let elements = [];

    // Handle inline code
    text = text.split(/(`[^`]+`)/).map(part => {
        if (part.startsWith('`') && part.endsWith('`')) {
            return { type: 'code', content: part.slice(1, -1) };
        }
        return part;
    });

    // Handle bold text
    text = text.flatMap(part => {
        if (typeof part === 'string') {
            return part.split(/(\*\*[^*]+\*\*)/).map(subpart => {
                if (subpart.startsWith('**') && subpart.endsWith('**')) {
                    const content = subpart.slice(2, -2);
                    if (content.indexOf('=')>-1) {
                      return { type: 'latex', content: content, displayMode: false };
                    }
                    return { type: 'strong', content: content };
                }
                return subpart;
            });
        }
        return part;
    });

    // Handle bold text #2
    // text = text.flatMap(part => {
    //     if (typeof part === 'string') {
    //         return part.split(/(\*[^*]+\*\*)/).map(subpart => {
    //             if (subpart.startsWith('*') && subpart.endsWith('**')) {
    //                 return { type: 'strong', content: subpart.slice(2, -2) };
    //             }
    //             return subpart;
    //         });
    //     }
    //     return part;
    // });

    // Handle italic text
    text = text.flatMap(part => {
        if (typeof part === 'string') {
            return part.split(/(\*[^*]+\*)/).map(subpart => {
                if (subpart.startsWith('*') && subpart.endsWith('*')) {
                    return { type: 'em', content: subpart.slice(1, -1) };
                }
                return subpart;
            });
        }
        return part;
    });

    // Handle strikethrough
    text = text.flatMap(part => {
        if (typeof part === 'string') {
            return part.split(/(~~[^~]+~~)/).map(subpart => {
                if (subpart.startsWith('~~') && subpart.endsWith('~~')) {
                    return { type: 'del', content: subpart.slice(2, -2) };
                }
                return subpart;
            });
        }
        return part;
    });

    // Handle hyperlinks
    text = text.flatMap(part => {
        if (typeof part === 'string') {
            return part.split(/(\[[^\]]+\]\([^)]+\))/).map(subpart => {
                const match = subpart.match(/\[([^\]]+)\]\(([^)]+)\)/);
                if (match) {
                    return { type: 'a', content: match[1], href: match[2] };
                }
                return subpart;
            });
        }
        return part;
    });

    // Combine parts into a single array of elements
    for (let part of text) {
        if (typeof part === 'string') {
            if (elements.length > 0 && typeof elements[elements.length - 1] === 'string') {
                elements[elements.length - 1] += part;
            } else {
                elements.push(part);
            }
        } else {
            elements.push(part);
        }
    }

    return elements;
  }

  async loadKatex(msgId) {
    const myMsgId = msgId;
    if (!this.katexLoading) {
      this.katexLoading = true;
      const katex = await import('katex');
      this.katexLoading = false;
      this.katex = katex.default;
      this.katexQueue[myMsgId] = true;
      const idList = Object.keys(this.katexQueue);
      for (var i = 0; i < idList.length; i++) {
        this.renderMsgId(idList[i]);
      }
    }
    else
    {
      this.katexQueue[myMsgId] = true;
    }
  }

  renderElements(elements, parent, msgId) {
    elements.forEach(element => {
        if (typeof element === 'string') {
            parent.appendChild(document.createTextNode(element));
        } else {
            let el;
            if (element.type === 'svg') {
                el = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                
                if (element.className) {
                    el.setAttribute('class', element.className);
                }

                if (element.children) {
                    element.children.forEach(child => {
                        if (child.type === 'use') {
                            const useEl = document.createElementNS('http://www.w3.org/2000/svg', 'use');
                            useEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', child.icon);
                            el.appendChild(useEl);
                        } else {
                            this.renderElements([child], el, msgId);
                        }
                    });
                }
            } else if (element.type === 'latex') {
                if (element.displayMode) {
                  el = document.createElement('div');
                }
                else {
                  el = document.createElement('span');
                }
                if (!this.katex) {
                  this.loadKatex(msgId);
                }
                else {
                  this.katex.render(element.content, el, {
                    throwOnError: false,
                    displayMode: element.displayMode,
                    // output: 'mathml' // Only render HTML part
                  });
                }
                el.className = 'latex-container';
            } else {
                el = document.createElement(element.type);
                
                if (element.className) {
                    el.className = element.className;
                }

                if (element.content) {
                    if (typeof element.content === 'string') {
                        el.textContent = element.content;
                    } else {
                        this.renderElements(element.content, el, msgId);
                    }
                }

                if (element.style) {
                    el.style.cssText = element.style;
                }

                if (element.attributes) {
                    Object.keys(element.attributes).forEach(attr => {
                        el.setAttribute(attr, element.attributes[attr]);
                    });
                }

                if (element.events) {
                    Object.keys(element.events).forEach(event => {
                        el.addEventListener(event, element.events[event]);
                    });
                }

                if (element.children) {
                    this.renderElements(element.children, el, msgId);
                }

                if (element.icon) {
                    const useEl = document.createElementNS('http://www.w3.org/2000/svg', 'use');
                    useEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', element.icon);
                    // el.appendChild(useEl);
                    el.insertBefore(useEl, el.firstChild);
                }

                if (element.href) {
                    el.href = element.href;
                    el.target = '_blank';
                    el.rel = 'noopener noreferrer';
                }
            }

            if (element.position === 'prepend') {
                parent.insertBefore(el, parent.firstChild);
            } else {
                parent.appendChild(el);
            }
        }
    });
  }

  createListElement(type, items) {
      return { type, className: type === 'ol' ? 'slds-m-top_xx-small slds-m-bottom_xx-small slds-list_ordered' : 'slds-m-top_xx-small slds-m-bottom_xx-small slds-list_dotted', children: items };
  }

  createEmptyStructureWithContents(code, language) {
    const structuredElements = [];
    const fg = '#24292eff;--shiki-dark:#dbd7caee';
    const bg = '#ffffff;--shiki-dark-bg:#121212';
    const themeName = 'shiki-themes min-light vitesse-dark';

    structuredElements.push({
        type: 'div',
        className: 'slds-card',
        children: [
            {
                type: 'div',
                className: 'slds-card__header slds-m-bottom_none slds-grid slds-grid_align-spread slds-p-horizontal_small slds-p-vertical_x-small',
                children: [
                    {
                        type: 'span',
                        className: 'slds-text-title_caps',
                        content: language.toUpperCase(), // Use language variable if dynamic
                    },
                    {
                        type: 'button',
                        className: 'slds-button slds-button_icon slds-text-title_caps slds-m-left_small',
                        events: {
                            click: (e) => {
                                this.copyCode(e, code);
                                // Replace with actual copy code function
                            }
                        },
                        content: 'COPY',
                        children: [
                            {
                                type: 'svg',
                                className: 'slds-button__icon slds-m-right_xxx-small',
                                position: 'prepend',
                                children: [
                                    {
                                        type: 'use',
                                        icon: '/assets/icons/utility-sprite/svg/symbols.svg#copy'
                                    },
                                ],
                            }
                        ],
                    },
                ],
            },
            {
                type: 'div',
                className: 'slds-card__body slds-m-around_none',
                children: [
                    {
                        type: 'pre',
                        className: `shiki ${themeName} slds-m-around_none slds-p-around_x-small`,
                        style: `background-color:${bg};color:${fg}`,
                        content: code
                    },
                ],
            },
        ],
    });

    return structuredElements;
  }

  convertTokensToStructuredFormat(code, data, language) {
      const { tokens, fg, bg, themeName } = data;
      const structuredElements = [];

      // Convert tokens into structured elements with newlines
      const codeChildren = tokens.map(lineTokens => ({
          type: 'span',
          className: 'line',
          style: lineTokens.htmlStyle,
          children: lineTokens.map(token => ({
              type: 'span',
              style: (token.htmlStyle?token.htmlStyle:`color:${token.color}`),
              content: token.content,
          })),
      }));

      // Add newlines between lines
      for (let i = 0; i < codeChildren.length - 1; i++) {
          codeChildren[i].children.push({ type: 'br' });
      }

      // Build the entire structure
      structuredElements.push({
          type: 'div',
          className: 'slds-card',
          children: [
              {
                  type: 'div',
                  className: 'slds-card__header slds-m-bottom_none slds-grid slds-grid_align-spread slds-p-horizontal_small slds-p-vertical_x-small',
                  children: [
                      {
                          type: 'span',
                          className: 'slds-text-title_caps',
                          content: language.toUpperCase(), // Use language variable if dynamic
                      },
                      {
                          type: 'button',
                          className: 'slds-button slds-button_icon slds-text-title_caps slds-m-left_small',
                          events: {
                              click: (e) => {
                                  this.copyCode(e, code);
                                  // Replace with actual copy code function
                              }
                          },
                          content: 'COPY',
                          children: [
                              {
                                  type: 'svg',
                                  className: 'slds-button__icon slds-m-right_xxx-small',
                                  position: 'prepend',
                                  children: [
                                      {
                                          type: 'use',
                                          icon: '/assets/icons/utility-sprite/svg/symbols.svg#copy'
                                          // attributes: { 'xlink:href': '/assets/icons/utility-sprite/svg/symbols.svg#copy' },
                                      },
                                  ],
                              },
                          ],
                      },
                  ],
              },
              {
                  type: 'div',
                  className: 'slds-card__body slds-m-around_none',
                  children: [
                      {
                          type: 'pre',
                          className: `shiki ${themeName} slds-m-around_none slds-p-around_x-small`,
                          style: `background-color:${bg};color:${fg}`,
                          children: [
                              {
                                  type: 'code',
                                  children: codeChildren,
                              },
                          ],
                      },
                  ],
              },
          ],
      });

      return structuredElements;
  }

  addMessageToRender(msgId, language) {
    if (!this.messageRenderLookup) {
      this.messageRenderLookup = {};
    }
    if (!this.messageRenderLookup[language]) {
      this.messageRenderLookup[language] = [];
    }
    this.messageRenderLookup[language].push(msgId);
  }

  createCodeBlockElement(code, language, msgId) {
    if (language==="C#") {
      language = "csharp";
    }
    if (this.highlighter) {
      var token_result = null
      try {
        token_result = this.highlighter.codeToTokens(code, {
          lang: language,
          themes: { 
            light: 'min-light',
            dark: 'vitesse-dark',
          }
          // theme: 'vitesse-dark'
        });
      }
      catch(e) {
        const myMsgId = msgId;
        if (e.name === "ShikiError") {
          const lang = e.message.split("`")[1];
          if (this.shikiLoadingLangs.indexOf(lang)===-1) {
            var myLang = lang;
            try {
              this.highlighter.loadLanguage(lang).then((function(e){
                const oldValue = this.shikiLoadedLangs;
                this.shikiLoadedLangs.push(myLang);
                this.addMessageToRender(myMsgId, language); //Add message to render queue
                var itemsToRender = this.messageRenderLookup[myLang];
                this.messageRenderLookup[myLang] = [];
                for (var i = 0; i < itemsToRender.length; i++) {
                  this.renderMsgId(itemsToRender[i]);
                }
                // this.requestUpdate("shikiLoadedLangs", oldValue);
                // setTimeout((function(){ this.requestUpdate(); }).bind(this), 500);
                // debugger;
              }).bind(this));
              this.shikiLoadingLangs.push(lang);
            }
            catch(e) { }
          }
        }
      }
      if (token_result)
      {
        this.addMessageToRender(msgId, language); //Add message to render queue
        const children = this.convertTokensToStructuredFormat(code, token_result, language);
        return {
            type: 'pre',
            children: [
                { type: 'code', children: children, className: `language-${language}` }
            ]
        };
      }
      else
      {
        this.addMessageToRender(msgId, language); //Add message to render queue
        const children = this.createEmptyStructureWithContents(code, language);
        return {
            type: 'pre',
            children: [
                { type: 'code', children: children, className: `language-${language}` }
            ]
        };
      }
    }
    else {
      const children = this.createEmptyStructureWithContents(code, language);
      return {
          type: 'pre',
          children: [
              { type: 'code', children: children, className: `language-${language}` }
          ]
      };
    }
  }

  // Function to copy code (to be used in the onclick event)
  copyCode(e, code) {
    let svgElement;
    let buttonElement = e.target;

    // Check if e.target is an SVG element or contains one
    if (e.target.tagName.toLowerCase() === 'svg') {
        svgElement = e.target;
        buttonElement = svgElement.closest('button'); // Ensure the button element is the parent
    } else {
        svgElement = e.target.querySelector('svg');
    }

    if (svgElement) {
        // Find the <use> element within the SVG
        let useElement = svgElement.querySelector('use');
        
        if (useElement) {
            // Save the original xlink:href value
            let originalHref = useElement.getAttribute('xlink:href');
            
            // Set the new xlink:href value
            useElement.setAttribute('xlink:href', '/assets/icons/utility-sprite/svg/symbols.svg#check');
            
            // Save the original button content
            let originalButtonContent = buttonElement.textContent;
            
            // Set the button content to "COPIED"
            let originalTextNode = buttonElement.firstChild;
            buttonElement.textContent = "COPIED";
            buttonElement.insertBefore(svgElement, buttonElement.firstChild); // Reinsert SVG at the start
            
            // Set a timeout to revert the xlink:href value and button content after 3 seconds
            setTimeout(function() {
                useElement.setAttribute('xlink:href', originalHref);
                buttonElement.textContent = originalButtonContent;
                buttonElement.insertBefore(svgElement, buttonElement.firstChild); // Reinsert SVG at the start
            }, 3000);
        }
    }
    const textArea = document.createElement('textarea');
    textArea.value = code;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  renderMessage(message) {
    switch (message.message_type) {
      case 'bookend':
        return html`
          <li class="slds-chat-listitem slds-chat-listitem_bookend fadeIn">
            <div class="slds-chat-bookend">
              <span class="slds-icon_container slds-icon-utility-chat slds-chat-icon">
                <svg class="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chat"></use>
                </svg>
              </span>
              <p>${message.message_content} • ${message.meta_info}</p>
            </div>
          </li>
        `;
      case 'event':
        return html`
          <li class="slds-chat-listitem slds-chat-listitem_event fadeIn">
            <div class="slds-chat-event">
              <div class="slds-chat-event__body">
                <span class="slds-icon_container slds-icon-utility-change_owner slds-chat-icon">
                  <svg class="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#change_owner"></use>
                  </svg>
                </span>
                <p>
                  <b>${message.sender_name}</b> ${message.message_content} • ${message.meta_info}
                </p>
              </div>
              ${message.event_details ? html`
                <div class="slds-chat-event__agent-message">${message.event_details.action} to ${message.event_details.target}</div>
              ` : ''}
            </div>
          </li>
        `;
      case 'inbound':
        return html`
          <li class="slds-chat-listitem slds-chat-listitem_inbound fadeIn">
            <div class="slds-chat-message">
              <span aria-hidden="true" class="slds-avatar slds-avatar_circle slds-chat-avatar">
                <abbr class="slds-avatar__initials slds-avatar__initials_inverse" title="${message.sender_name}">${message.avatar_initials}</abbr>
              </span>
              <div class="slds-chat-message__body">
                <div class="slds-chat-message__text slds-chat-message__text_inbound"><div class="message-container" data-id="${message.id}"><span class="slds-icon-typing slds-is-animated" style="display: table;" title="Chatbot is typing">
  <span class="slds-icon-typing__dot"></span>
  <span class="slds-icon-typing__dot"></span>
  <span class="slds-icon-typing__dot"></span>
  <span class="slds-assistive-text">Chatbot is typing</span>
</span></div></div>
                <div class="slds-chat-message__meta" aria-label="said ${message.sender_name} at ${message.meta_info}">${message.sender_name} • ${message.meta_info}</div>
              </div>
            </div>
          </li>
        `;
      case 'outbound':
        return html`
          <li class="slds-chat-listitem slds-chat-listitem_outbound fadeIn">
            <div class="slds-chat-message">
              <div class="slds-chat-message__body">
                <div class="slds-chat-message__text slds-chat-message__text_outbound">${message.message_content}</div>
                <div class="slds-chat-message__meta" aria-label="said ${message.sender_name} at ${message.meta_info}">${message.sender_name} • ${message.meta_info}</div>
              </div>
            </div>
          </li>
        `;
      default:
        return '';
    }
  }

  getFirstCharsOfFirstTwoWords(inputString) {
      // Split the input string into an array of words
      const words = inputString.split(" ");

      // Check if there are at least two words
      if (words.length < 2) {
          return "";
      }

      // Extract the first two characters of the first two words, capitalize them, and return
      const firstWord = words[0].substring(0, 1).toUpperCase();
      const secondWord = words[1].substring(0, 1).toUpperCase();

      return firstWord+secondWord;
  }

  closeError() {
    this.error = null;
  }

  getOffsetClass(chatboxActive, chatboxExpanded, error) {
    if (chatboxActive && chatboxExpanded && error) {
      return "big-margin";//"margin-top: -15.25rem;";
    }
    else if (error) {
      return "sml-margin";//"margin-top: -10rem;";
    }
    if (chatboxActive && chatboxExpanded) {
      return "mid-margin";//"margin-top: -11.75rem;";
    }
    return "";
  }

  toggleDropdown() {
    this.profileDropdownOpen = !this.profileDropdownOpen;
  }

  stencilCss(index) {
    return "opacity:"+((this.stencils.length - index)/this.stencils.length)+";";
  }

  dropdownSelect(e) {
    const type = e.detail.type;
    if (type === "log-out") {
      const firebase = window.firebase;
      firebase.signOut(firebase.auth).then(() => {
      }).catch((error) => {
        console.error(error);
      });
      store.dispatch(setAccessToken(''));
      store.dispatch(setUser(null));
      localStorageService.removeItem("user");
      localStorageService.removeItem("token");
    }
    this.toggleDropdown()
  }

  adjustHeight(e) {
    const textarea = e.target;
    textarea.style.height = 'auto'; // Reset height to auto to calculate the new height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height based on scrollHeight
    this.chatboxExpanded = true;
    this.chatboxActive = true;
  }

  getErrorUrl(type) {
    if (!type) { return ""; }
    type = type.split("/").join("-");
    return "/error/" + type;
  }

  static styles = css`
    #messages .slds-chat-message__text {
      max-width: 56.25rem;
    }
    .slds-notify_alert {
      position: sticky !important;
      top: 3rem;
      z-index: 999;
      margin-bottom: 1rem;
    }
    .dark p, .dark .slds-chat-message__meta, .dark .slds-chat-event__agent-message {
      color: white;
    }
    .dark .slds-icon-text-default {
      --slds-c-icon-color-foreground: white;
    }
    .dark a {
      --slds-c-button-text-color: white;
      --sds-c-button-text-color: white;
    }
    .dark .slds-button:focus, .dark .slds-button:hover {
      --slds-c-button-text-color-hover: #0176d3;
      --sds-c-button-text-color-hover: #0176d3;
      color: #0176d3;
    }
    .dark .user-write-area {
      background-color: #2b2826;
      border-top: 1px solid black;
    }
    .user-write-area {
      position: -webkit-sticky; /* For Safari */
      position: sticky;
      bottom: 0;
      background-color: #FAFAFB;
      border-top: 1px solid #d8dee6;
    }
    .dark .slds-publisher__input, .dark .slds-publisher.slds-has-focus {
      background-color: #1a1b1e;
    }
    .dark .slds-publisher_comment {
      border: 1px solid #181818;
      background-color: #2b2826;
    }
    .slds-modal__content {
      --slds-c-modal-content-color-background: var(--background-color, white);
    }
    .slds-theme--default, .slds-theme_default {
      background-color: var(--background-color, #fff);
      color: var(--text-color, #181818);
    }
    .slds-modal__container {
      --slds-c-modal-color-border: var(--table-hover, #e5e5e5);
    }
    .slds-theme--alert-texture, .slds-theme_alert-texture {
      background-color: var(--background-color, #fff);
      color: var(--text-color, #181818);
    }
    .slds-has-submenu a:hover .slds-icon-text-default {
      --slds-c-icon-color-foreground: #0176d3;
    }
    .slds-chat-list {
      min-height: 100vh;
      margin-top: -6.75rem;
      position: relative;
    }
    .slds-chat-list>li:first-child {
      padding-top: 6.75rem;
    }
    .slds-chat-list.big-margin {
      margin-top: -14.25rem;
      padding-top: 5.25rem;
    }
    .slds-chat-list.mid-margin {
      margin-top: -10.75rem;
      padding-top: 2.75rem;
    }
    .slds-chat-list.sml-margin {
      margin-top: -9rem;
    }
    .no-messages {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%; /* Optional: If you want to center horizontally as well */
      transform: translate(-50%, -50%); /* Optional: If you want to center horizontally as well */
      margin: 0; /* Remove default margin */
    }
    .dark .slds-chat-message__text_inbound p {
      color: #181818;
    }
    pre>pre.shiki {
      margin-top: 0;
    }
    .slds-chat-message__text_inbound a {
      color: #0176d3;
    }
    .dark .shiki,
    .dark .shiki span {
      color: var(--shiki-dark) !important;
      background-color: var(--shiki-dark-bg) !important;
      /* Optional, if you also want font styles */
      font-style: var(--shiki-dark-font-style) !important;
      font-weight: var(--shiki-dark-font-weight) !important;
      text-decoration: var(--shiki-dark-text-decoration) !important;
    }
    .slds-inline {
      display: inline !important;
    }
    .mathjax {
      text-align: left;
    }
    .latex-container>.katex-display {
      display: inline-block;
      padding-left: 0.5rem;
    }
    .message-container>*:first-child {
      margin-top: 0;
    }
    .message-container>*:last-child {
      margin-bottom: 0;
    }
    @font-face{
      font-family:KaTeX_AMS;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_AMS-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_AMS-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_AMS-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Caligraphic;
      font-style:normal;
      font-weight:700;
      src:url(/assets/fonts/KaTeX_Caligraphic-Bold.woff2) format("woff2"),url(/assets/fonts/KaTeX_Caligraphic-Bold.woff) format("woff"),url(/assets/fonts/KaTeX_Caligraphic-Bold.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Caligraphic;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Caligraphic-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Caligraphic-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Caligraphic-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Fraktur;
      font-style:normal;
      font-weight:700;
      src:url(/assets/fonts/KaTeX_Fraktur-Bold.woff2) format("woff2"),url(/assets/fonts/KaTeX_Fraktur-Bold.woff) format("woff"),url(/assets/fonts/KaTeX_Fraktur-Bold.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Fraktur;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Fraktur-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Fraktur-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Fraktur-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Main;
      font-style:normal;
      font-weight:700;
      src:url(/assets/fonts/KaTeX_Main-Bold.woff2) format("woff2"),url(/assets/fonts/KaTeX_Main-Bold.woff) format("woff"),url(/assets/fonts/KaTeX_Main-Bold.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Main;
      font-style:italic;
      font-weight:700;
      src:url(/assets/fonts/KaTeX_Main-BoldItalic.woff2) format("woff2"),url(/assets/fonts/KaTeX_Main-BoldItalic.woff) format("woff"),url(/assets/fonts/KaTeX_Main-BoldItalic.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Main;
      font-style:italic;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Main-Italic.woff2) format("woff2"),url(/assets/fonts/KaTeX_Main-Italic.woff) format("woff"),url(/assets/fonts/KaTeX_Main-Italic.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Main;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Main-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Main-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Main-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Math;
      font-style:italic;
      font-weight:700;
      src:url(/assets/fonts/KaTeX_Math-BoldItalic.woff2) format("woff2"),url(/assets/fonts/KaTeX_Math-BoldItalic.woff) format("woff"),url(/assets/fonts/KaTeX_Math-BoldItalic.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Math;
      font-style:italic;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Math-Italic.woff2) format("woff2"),url(/assets/fonts/KaTeX_Math-Italic.woff) format("woff"),url(/assets/fonts/KaTeX_Math-Italic.ttf) format("truetype")
    }
    @font-face{
      font-family:"KaTeX_SansSerif";
      font-style:normal;
      font-weight:700;
      src:url(/assets/fonts/KaTeX_SansSerif-Bold.woff2) format("woff2"),url(/assets/fonts/KaTeX_SansSerif-Bold.woff) format("woff"),url(/assets/fonts/KaTeX_SansSerif-Bold.ttf) format("truetype")
    }
    @font-face{
      font-family:"KaTeX_SansSerif";
      font-style:italic;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_SansSerif-Italic.woff2) format("woff2"),url(/assets/fonts/KaTeX_SansSerif-Italic.woff) format("woff"),url(/assets/fonts/KaTeX_SansSerif-Italic.ttf) format("truetype")
    }
    @font-face{
      font-family:"KaTeX_SansSerif";
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_SansSerif-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_SansSerif-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_SansSerif-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Script;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Script-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Script-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Script-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Size1;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Size1-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Size1-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Size1-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Size2;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Size2-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Size2-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Size2-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Size3;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Size3-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Size3-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Size3-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Size4;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Size4-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Size4-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Size4-Regular.ttf) format("truetype")
    }
    @font-face{
      font-family:KaTeX_Typewriter;
      font-style:normal;
      font-weight:400;
      src:url(/assets/fonts/KaTeX_Typewriter-Regular.woff2) format("woff2"),url(/assets/fonts/KaTeX_Typewriter-Regular.woff) format("woff"),url(/assets/fonts/KaTeX_Typewriter-Regular.ttf) format("truetype")
    }
    .katex{
      font:normal 1.21em KaTeX_Main,Times New Roman,serif;
      line-height:1.2;
      text-indent:0;
      text-rendering:auto
    }
    .katex *{
      -ms-high-contrast-adjust:none!important;
      border-color:currentColor
    }
    .katex .katex-version:after{
      content:"0.13.11"
    }
    .katex .katex-mathml{
      clip:rect(1px,1px,1px,1px);
      border:0;
      height:1px;
      overflow:hidden;
      padding:0;
      position:absolute;
      width:1px
    }
    .katex .katex-html>.newline{
      display:block
    }
    .katex .base{
      position:relative;
      white-space:nowrap;
      width:-webkit-min-content;
      width:-moz-min-content;
      width:min-content
    }
    .katex .base,.katex .strut{
      display:inline-block
    }
    .katex .textbf{
      font-weight:700
    }
    .katex .textit{
      font-style:italic
    }
    .katex .textrm{
      font-family:KaTeX_Main
    }
    .katex .textsf{
      font-family:KaTeX_SansSerif
    }
    .katex .texttt{
      font-family:KaTeX_Typewriter
    }
    .katex .mathnormal{
      font-family:KaTeX_Math;
      font-style:italic
    }
    .katex .mathit{
      font-family:KaTeX_Main;
      font-style:italic
    }
    .katex .mathrm{
      font-style:normal
    }
    .katex .mathbf{
      font-family:KaTeX_Main;
      font-weight:700
    }
    .katex .boldsymbol{
      font-family:KaTeX_Math;
      font-style:italic;
      font-weight:700
    }
    .katex .amsrm,.katex .mathbb,.katex .textbb{
      font-family:KaTeX_AMS
    }
    .katex .mathcal{
      font-family:KaTeX_Caligraphic
    }
    .katex .mathfrak,.katex .textfrak{
      font-family:KaTeX_Fraktur
    }
    .katex .mathtt{
      font-family:KaTeX_Typewriter
    }
    .katex .mathscr,.katex .textscr{
      font-family:KaTeX_Script
    }
    .katex .mathsf,.katex .textsf{
      font-family:KaTeX_SansSerif
    }
    .katex .mathboldsf,.katex .textboldsf{
      font-family:KaTeX_SansSerif;
      font-weight:700
    }
    .katex .mathitsf,.katex .textitsf{
      font-family:KaTeX_SansSerif;
      font-style:italic
    }
    .katex .mainrm{
      font-family:KaTeX_Main;
      font-style:normal
    }
    .katex .vlist-t{
      border-collapse:collapse;
      display:inline-table;
      table-layout:fixed
    }
    .katex .vlist-r{
      display:table-row
    }
    .katex .vlist{
      display:table-cell;
      position:relative;
      vertical-align:bottom
    }
    .katex .vlist>span{
      display:block;
      height:0;
      position:relative
    }
    .katex .vlist>span>span{
      display:inline-block
    }
    .katex .vlist>span>.pstrut{
      overflow:hidden;
      width:0
    }
    .katex .vlist-t2{
      margin-right:-2px
    }
    .katex .vlist-s{
      display:table-cell;
      font-size:1px;
      min-width:2px;
      vertical-align:bottom;
      width:2px
    }
    .katex .vbox{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -webkit-box-align:baseline;
      align-items:baseline;
      display:-webkit-inline-box;
      display:inline-flex;
      flex-direction:column
    }
    .katex .hbox{
      width:100%
    }
    .katex .hbox,.katex .thinbox{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      display:-webkit-inline-box;
      display:inline-flex;
      flex-direction:row
    }
    .katex .thinbox{
      max-width:0;
      width:0
    }
    .katex .msupsub{
      text-align:left
    }
    .katex .mfrac>span>span{
      text-align:center
    }
    .katex .mfrac .frac-line{
      border-bottom-style:solid;
      display:inline-block;
      width:100%
    }
    .katex .hdashline,.katex .hline,.katex .mfrac .frac-line,.katex .overline .overline-line,.katex .rule,.katex .underline .underline-line{
      min-height:1px
    }
    .katex .mspace{
      display:inline-block
    }
    .katex .clap,.katex .llap,.katex .rlap{
      position:relative;
      width:0
    }
    .katex .clap>.inner,.katex .llap>.inner,.katex .rlap>.inner{
      position:absolute
    }
    .katex .clap>.fix,.katex .llap>.fix,.katex .rlap>.fix{
      display:inline-block
    }
    .katex .llap>.inner{
      right:0
    }
    .katex .clap>.inner,.katex .rlap>.inner{
      left:0
    }
    .katex .clap>.inner>span{
      margin-left:-50%;
      margin-right:50%
    }
    .katex .rule{
      border:0 solid;
      display:inline-block;
      position:relative
    }
    .katex .hline,.katex .overline .overline-line,.katex .underline .underline-line{
      border-bottom-style:solid;
      display:inline-block;
      width:100%
    }
    .katex .hdashline{
      border-bottom-style:dashed;
      display:inline-block;
      width:100%
    }
    .katex .sqrt>.root{
      margin-left:.27777778em;
      margin-right:-.55555556em
    }
    .katex .fontsize-ensurer.reset-size1.size1,.katex .sizing.reset-size1.size1{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size1.size2,.katex .sizing.reset-size1.size2{
      font-size:1.2em
    }
    .katex .fontsize-ensurer.reset-size1.size3,.katex .sizing.reset-size1.size3{
      font-size:1.4em
    }
    .katex .fontsize-ensurer.reset-size1.size4,.katex .sizing.reset-size1.size4{
      font-size:1.6em
    }
    .katex .fontsize-ensurer.reset-size1.size5,.katex .sizing.reset-size1.size5{
      font-size:1.8em
    }
    .katex .fontsize-ensurer.reset-size1.size6,.katex .sizing.reset-size1.size6{
      font-size:2em
    }
    .katex .fontsize-ensurer.reset-size1.size7,.katex .sizing.reset-size1.size7{
      font-size:2.4em
    }
    .katex .fontsize-ensurer.reset-size1.size8,.katex .sizing.reset-size1.size8{
      font-size:2.88em
    }
    .katex .fontsize-ensurer.reset-size1.size9,.katex .sizing.reset-size1.size9{
      font-size:3.456em
    }
    .katex .fontsize-ensurer.reset-size1.size10,.katex .sizing.reset-size1.size10{
      font-size:4.148em
    }
    .katex .fontsize-ensurer.reset-size1.size11,.katex .sizing.reset-size1.size11{
      font-size:4.976em
    }
    .katex .fontsize-ensurer.reset-size2.size1,.katex .sizing.reset-size2.size1{
      font-size:.83333333em
    }
    .katex .fontsize-ensurer.reset-size2.size2,.katex .sizing.reset-size2.size2{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size2.size3,.katex .sizing.reset-size2.size3{
      font-size:1.16666667em
    }
    .katex .fontsize-ensurer.reset-size2.size4,.katex .sizing.reset-size2.size4{
      font-size:1.33333333em
    }
    .katex .fontsize-ensurer.reset-size2.size5,.katex .sizing.reset-size2.size5{
      font-size:1.5em
    }
    .katex .fontsize-ensurer.reset-size2.size6,.katex .sizing.reset-size2.size6{
      font-size:1.66666667em
    }
    .katex .fontsize-ensurer.reset-size2.size7,.katex .sizing.reset-size2.size7{
      font-size:2em
    }
    .katex .fontsize-ensurer.reset-size2.size8,.katex .sizing.reset-size2.size8{
      font-size:2.4em
    }
    .katex .fontsize-ensurer.reset-size2.size9,.katex .sizing.reset-size2.size9{
      font-size:2.88em
    }
    .katex .fontsize-ensurer.reset-size2.size10,.katex .sizing.reset-size2.size10{
      font-size:3.45666667em
    }
    .katex .fontsize-ensurer.reset-size2.size11,.katex .sizing.reset-size2.size11{
      font-size:4.14666667em
    }
    .katex .fontsize-ensurer.reset-size3.size1,.katex .sizing.reset-size3.size1{
      font-size:.71428571em
    }
    .katex .fontsize-ensurer.reset-size3.size2,.katex .sizing.reset-size3.size2{
      font-size:.85714286em
    }
    .katex .fontsize-ensurer.reset-size3.size3,.katex .sizing.reset-size3.size3{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size3.size4,.katex .sizing.reset-size3.size4{
      font-size:1.14285714em
    }
    .katex .fontsize-ensurer.reset-size3.size5,.katex .sizing.reset-size3.size5{
      font-size:1.28571429em
    }
    .katex .fontsize-ensurer.reset-size3.size6,.katex .sizing.reset-size3.size6{
      font-size:1.42857143em
    }
    .katex .fontsize-ensurer.reset-size3.size7,.katex .sizing.reset-size3.size7{
      font-size:1.71428571em
    }
    .katex .fontsize-ensurer.reset-size3.size8,.katex .sizing.reset-size3.size8{
      font-size:2.05714286em
    }
    .katex .fontsize-ensurer.reset-size3.size9,.katex .sizing.reset-size3.size9{
      font-size:2.46857143em
    }
    .katex .fontsize-ensurer.reset-size3.size10,.katex .sizing.reset-size3.size10{
      font-size:2.96285714em
    }
    .katex .fontsize-ensurer.reset-size3.size11,.katex .sizing.reset-size3.size11{
      font-size:3.55428571em
    }
    .katex .fontsize-ensurer.reset-size4.size1,.katex .sizing.reset-size4.size1{
      font-size:.625em
    }
    .katex .fontsize-ensurer.reset-size4.size2,.katex .sizing.reset-size4.size2{
      font-size:.75em
    }
    .katex .fontsize-ensurer.reset-size4.size3,.katex .sizing.reset-size4.size3{
      font-size:.875em
    }
    .katex .fontsize-ensurer.reset-size4.size4,.katex .sizing.reset-size4.size4{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size4.size5,.katex .sizing.reset-size4.size5{
      font-size:1.125em
    }
    .katex .fontsize-ensurer.reset-size4.size6,.katex .sizing.reset-size4.size6{
      font-size:1.25em
    }
    .katex .fontsize-ensurer.reset-size4.size7,.katex .sizing.reset-size4.size7{
      font-size:1.5em
    }
    .katex .fontsize-ensurer.reset-size4.size8,.katex .sizing.reset-size4.size8{
      font-size:1.8em
    }
    .katex .fontsize-ensurer.reset-size4.size9,.katex .sizing.reset-size4.size9{
      font-size:2.16em
    }
    .katex .fontsize-ensurer.reset-size4.size10,.katex .sizing.reset-size4.size10{
      font-size:2.5925em
    }
    .katex .fontsize-ensurer.reset-size4.size11,.katex .sizing.reset-size4.size11{
      font-size:3.11em
    }
    .katex .fontsize-ensurer.reset-size5.size1,.katex .sizing.reset-size5.size1{
      font-size:.55555556em
    }
    .katex .fontsize-ensurer.reset-size5.size2,.katex .sizing.reset-size5.size2{
      font-size:.66666667em
    }
    .katex .fontsize-ensurer.reset-size5.size3,.katex .sizing.reset-size5.size3{
      font-size:.77777778em
    }
    .katex .fontsize-ensurer.reset-size5.size4,.katex .sizing.reset-size5.size4{
      font-size:.88888889em
    }
    .katex .fontsize-ensurer.reset-size5.size5,.katex .sizing.reset-size5.size5{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size5.size6,.katex .sizing.reset-size5.size6{
      font-size:1.11111111em
    }
    .katex .fontsize-ensurer.reset-size5.size7,.katex .sizing.reset-size5.size7{
      font-size:1.33333333em
    }
    .katex .fontsize-ensurer.reset-size5.size8,.katex .sizing.reset-size5.size8{
      font-size:1.6em
    }
    .katex .fontsize-ensurer.reset-size5.size9,.katex .sizing.reset-size5.size9{
      font-size:1.92em
    }
    .katex .fontsize-ensurer.reset-size5.size10,.katex .sizing.reset-size5.size10{
      font-size:2.30444444em
    }
    .katex .fontsize-ensurer.reset-size5.size11,.katex .sizing.reset-size5.size11{
      font-size:2.76444444em
    }
    .katex .fontsize-ensurer.reset-size6.size1,.katex .sizing.reset-size6.size1{
      font-size:.5em
    }
    .katex .fontsize-ensurer.reset-size6.size2,.katex .sizing.reset-size6.size2{
      font-size:.6em
    }
    .katex .fontsize-ensurer.reset-size6.size3,.katex .sizing.reset-size6.size3{
      font-size:.7em
    }
    .katex .fontsize-ensurer.reset-size6.size4,.katex .sizing.reset-size6.size4{
      font-size:.8em
    }
    .katex .fontsize-ensurer.reset-size6.size5,.katex .sizing.reset-size6.size5{
      font-size:.9em
    }
    .katex .fontsize-ensurer.reset-size6.size6,.katex .sizing.reset-size6.size6{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size6.size7,.katex .sizing.reset-size6.size7{
      font-size:1.2em
    }
    .katex .fontsize-ensurer.reset-size6.size8,.katex .sizing.reset-size6.size8{
      font-size:1.44em
    }
    .katex .fontsize-ensurer.reset-size6.size9,.katex .sizing.reset-size6.size9{
      font-size:1.728em
    }
    .katex .fontsize-ensurer.reset-size6.size10,.katex .sizing.reset-size6.size10{
      font-size:2.074em
    }
    .katex .fontsize-ensurer.reset-size6.size11,.katex .sizing.reset-size6.size11{
      font-size:2.488em
    }
    .katex .fontsize-ensurer.reset-size7.size1,.katex .sizing.reset-size7.size1{
      font-size:.41666667em
    }
    .katex .fontsize-ensurer.reset-size7.size2,.katex .sizing.reset-size7.size2{
      font-size:.5em
    }
    .katex .fontsize-ensurer.reset-size7.size3,.katex .sizing.reset-size7.size3{
      font-size:.58333333em
    }
    .katex .fontsize-ensurer.reset-size7.size4,.katex .sizing.reset-size7.size4{
      font-size:.66666667em
    }
    .katex .fontsize-ensurer.reset-size7.size5,.katex .sizing.reset-size7.size5{
      font-size:.75em
    }
    .katex .fontsize-ensurer.reset-size7.size6,.katex .sizing.reset-size7.size6{
      font-size:.83333333em
    }
    .katex .fontsize-ensurer.reset-size7.size7,.katex .sizing.reset-size7.size7{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size7.size8,.katex .sizing.reset-size7.size8{
      font-size:1.2em
    }
    .katex .fontsize-ensurer.reset-size7.size9,.katex .sizing.reset-size7.size9{
      font-size:1.44em
    }
    .katex .fontsize-ensurer.reset-size7.size10,.katex .sizing.reset-size7.size10{
      font-size:1.72833333em
    }
    .katex .fontsize-ensurer.reset-size7.size11,.katex .sizing.reset-size7.size11{
      font-size:2.07333333em
    }
    .katex .fontsize-ensurer.reset-size8.size1,.katex .sizing.reset-size8.size1{
      font-size:.34722222em
    }
    .katex .fontsize-ensurer.reset-size8.size2,.katex .sizing.reset-size8.size2{
      font-size:.41666667em
    }
    .katex .fontsize-ensurer.reset-size8.size3,.katex .sizing.reset-size8.size3{
      font-size:.48611111em
    }
    .katex .fontsize-ensurer.reset-size8.size4,.katex .sizing.reset-size8.size4{
      font-size:.55555556em
    }
    .katex .fontsize-ensurer.reset-size8.size5,.katex .sizing.reset-size8.size5{
      font-size:.625em
    }
    .katex .fontsize-ensurer.reset-size8.size6,.katex .sizing.reset-size8.size6{
      font-size:.69444444em
    }
    .katex .fontsize-ensurer.reset-size8.size7,.katex .sizing.reset-size8.size7{
      font-size:.83333333em
    }
    .katex .fontsize-ensurer.reset-size8.size8,.katex .sizing.reset-size8.size8{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size8.size9,.katex .sizing.reset-size8.size9{
      font-size:1.2em
    }
    .katex .fontsize-ensurer.reset-size8.size10,.katex .sizing.reset-size8.size10{
      font-size:1.44027778em
    }
    .katex .fontsize-ensurer.reset-size8.size11,.katex .sizing.reset-size8.size11{
      font-size:1.72777778em
    }
    .katex .fontsize-ensurer.reset-size9.size1,.katex .sizing.reset-size9.size1{
      font-size:.28935185em
    }
    .katex .fontsize-ensurer.reset-size9.size2,.katex .sizing.reset-size9.size2{
      font-size:.34722222em
    }
    .katex .fontsize-ensurer.reset-size9.size3,.katex .sizing.reset-size9.size3{
      font-size:.40509259em
    }
    .katex .fontsize-ensurer.reset-size9.size4,.katex .sizing.reset-size9.size4{
      font-size:.46296296em
    }
    .katex .fontsize-ensurer.reset-size9.size5,.katex .sizing.reset-size9.size5{
      font-size:.52083333em
    }
    .katex .fontsize-ensurer.reset-size9.size6,.katex .sizing.reset-size9.size6{
      font-size:.5787037em
    }
    .katex .fontsize-ensurer.reset-size9.size7,.katex .sizing.reset-size9.size7{
      font-size:.69444444em
    }
    .katex .fontsize-ensurer.reset-size9.size8,.katex .sizing.reset-size9.size8{
      font-size:.83333333em
    }
    .katex .fontsize-ensurer.reset-size9.size9,.katex .sizing.reset-size9.size9{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size9.size10,.katex .sizing.reset-size9.size10{
      font-size:1.20023148em
    }
    .katex .fontsize-ensurer.reset-size9.size11,.katex .sizing.reset-size9.size11{
      font-size:1.43981481em
    }
    .katex .fontsize-ensurer.reset-size10.size1,.katex .sizing.reset-size10.size1{
      font-size:.24108004em
    }
    .katex .fontsize-ensurer.reset-size10.size2,.katex .sizing.reset-size10.size2{
      font-size:.28929605em
    }
    .katex .fontsize-ensurer.reset-size10.size3,.katex .sizing.reset-size10.size3{
      font-size:.33751205em
    }
    .katex .fontsize-ensurer.reset-size10.size4,.katex .sizing.reset-size10.size4{
      font-size:.38572806em
    }
    .katex .fontsize-ensurer.reset-size10.size5,.katex .sizing.reset-size10.size5{
      font-size:.43394407em
    }
    .katex .fontsize-ensurer.reset-size10.size6,.katex .sizing.reset-size10.size6{
      font-size:.48216008em
    }
    .katex .fontsize-ensurer.reset-size10.size7,.katex .sizing.reset-size10.size7{
      font-size:.57859209em
    }
    .katex .fontsize-ensurer.reset-size10.size8,.katex .sizing.reset-size10.size8{
      font-size:.69431051em
    }
    .katex .fontsize-ensurer.reset-size10.size9,.katex .sizing.reset-size10.size9{
      font-size:.83317261em
    }
    .katex .fontsize-ensurer.reset-size10.size10,.katex .sizing.reset-size10.size10{
      font-size:1em
    }
    .katex .fontsize-ensurer.reset-size10.size11,.katex .sizing.reset-size10.size11{
      font-size:1.19961427em
    }
    .katex .fontsize-ensurer.reset-size11.size1,.katex .sizing.reset-size11.size1{
      font-size:.20096463em
    }
    .katex .fontsize-ensurer.reset-size11.size2,.katex .sizing.reset-size11.size2{
      font-size:.24115756em
    }
    .katex .fontsize-ensurer.reset-size11.size3,.katex .sizing.reset-size11.size3{
      font-size:.28135048em
    }
    .katex .fontsize-ensurer.reset-size11.size4,.katex .sizing.reset-size11.size4{
      font-size:.32154341em
    }
    .katex .fontsize-ensurer.reset-size11.size5,.katex .sizing.reset-size11.size5{
      font-size:.36173633em
    }
    .katex .fontsize-ensurer.reset-size11.size6,.katex .sizing.reset-size11.size6{
      font-size:.40192926em
    }
    .katex .fontsize-ensurer.reset-size11.size7,.katex .sizing.reset-size11.size7{
      font-size:.48231511em
    }
    .katex .fontsize-ensurer.reset-size11.size8,.katex .sizing.reset-size11.size8{
      font-size:.57877814em
    }
    .katex .fontsize-ensurer.reset-size11.size9,.katex .sizing.reset-size11.size9{
      font-size:.69453376em
    }
    .katex .fontsize-ensurer.reset-size11.size10,.katex .sizing.reset-size11.size10{
      font-size:.83360129em
    }
    .katex .fontsize-ensurer.reset-size11.size11,.katex .sizing.reset-size11.size11{
      font-size:1em
    }
    .katex .delimsizing.size1{
      font-family:KaTeX_Size1
    }
    .katex .delimsizing.size2{
      font-family:KaTeX_Size2
    }
    .katex .delimsizing.size3{
      font-family:KaTeX_Size3
    }
    .katex .delimsizing.size4{
      font-family:KaTeX_Size4
    }
    .katex .delimsizing.mult .delim-size1>span{
      font-family:KaTeX_Size1
    }
    .katex .delimsizing.mult .delim-size4>span{
      font-family:KaTeX_Size4
    }
    .katex .nulldelimiter{
      display:inline-block;
      width:.12em
    }
    .katex .delimcenter,.katex .op-symbol{
      position:relative
    }
    .katex .op-symbol.small-op{
      font-family:KaTeX_Size1
    }
    .katex .op-symbol.large-op{
      font-family:KaTeX_Size2
    }
    .katex .accent>.vlist-t,.katex .op-limits>.vlist-t{
      text-align:center
    }
    .katex .accent .accent-body{
      position:relative
    }
    .katex .accent .accent-body:not(.accent-full){
      width:0
    }
    .katex .overlay{
      display:block
    }
    .katex .mtable .vertical-separator{
      display:inline-block;
      min-width:1px
    }
    .katex .mtable .arraycolsep{
      display:inline-block
    }
    .katex .mtable .col-align-c>.vlist-t{
      text-align:center
    }
    .katex .mtable .col-align-l>.vlist-t{
      text-align:left
    }
    .katex .mtable .col-align-r>.vlist-t{
      text-align:right
    }
    .katex .svg-align{
      text-align:left
    }
    .katex svg{
      fill:currentColor;
      stroke:currentColor;
      fill-rule:nonzero;
      fill-opacity:1;
      stroke-width:1;
      stroke-linecap:butt;
      stroke-linejoin:miter;
      stroke-miterlimit:4;
      stroke-dasharray:none;
      stroke-dashoffset:0;
      stroke-opacity:1;
      display:block;
      height:inherit;
      position:absolute;
      width:100%
    }
    .katex svg path{
      stroke:none
    }
    .katex img{
      border-style:none;
      max-height:none;
      max-width:none;
      min-height:0;
      min-width:0
    }
    .katex .stretchy{
      display:block;
      overflow:hidden;
      position:relative;
      width:100%
    }
    .katex .stretchy:after,.katex .stretchy:before{
      content:""
    }
    .katex .hide-tail{
      overflow:hidden;
      position:relative;
      width:100%
    }
    .katex .halfarrow-left{
      left:0;
      overflow:hidden;
      position:absolute;
      width:50.2%
    }
    .katex .halfarrow-right{
      overflow:hidden;
      position:absolute;
      right:0;
      width:50.2%
    }
    .katex .brace-left{
      left:0;
      overflow:hidden;
      position:absolute;
      width:25.1%
    }
    .katex .brace-center{
      left:25%;
      overflow:hidden;
      position:absolute;
      width:50%
    }
    .katex .brace-right{
      overflow:hidden;
      position:absolute;
      right:0;
      width:25.1%
    }
    .katex .x-arrow-pad{
      padding:0 .5em
    }
    .katex .cd-arrow-pad{
      padding:0 .55556em 0 .27778em
    }
    .katex .mover,.katex .munder,.katex .x-arrow{
      text-align:center
    }
    .katex .boxpad{
      padding:0 .3em
    }
    .katex .fbox,.katex .fcolorbox{
      border:.04em solid;
      box-sizing:border-box
    }
    .katex .cancel-pad{
      padding:0 .2em
    }
    .katex .cancel-lap{
      margin-left:-.2em;
      margin-right:-.2em
    }
    .katex .sout{
      border-bottom-style:solid;
      border-bottom-width:.08em
    }
    .katex .angl{
      border-right:.049em solid;
      border-top:.049em solid;
      box-sizing:border-content;
      margin-right:.03889em
    }
    .katex .anglpad{
      padding:0 .03889em
    }
    .katex .eqn-num:before{
      content:"(" counter(katexEqnNo) ")";
      counter-increment:katexEqnNo
    }
    .katex .mml-eqn-num:before{
      content:"(" counter(mmlEqnNo) ")";
      counter-increment:mmlEqnNo
    }
    .katex .mtr-glue{
      width:50%
    }
    .katex .cd-vert-arrow{
      display:inline-block;
      position:relative
    }
    .katex .cd-label-left{
      display:inline-block;
      position:absolute;
      right:-webkit-calc(50% + .3em);
      right:calc(50% + .3em);
      text-align:left
    }
    .katex .cd-label-right{
      display:inline-block;
      left:-webkit-calc(50% + .3em);
      left:calc(50% + .3em);
      position:absolute;
      text-align:right
    }
    .katex-display{
      display:block;
      margin:1em 0;
      text-align:center
    }
    .katex-display>.katex{
      display:block;
      text-align:center;
      white-space:nowrap
    }
    .katex-display>.katex>.katex-html{
      display:block;
      position:relative
    }
    .katex-display>.katex>.katex-html>.tag{
      position:absolute;
      right:0
    }
    .katex-display.leqno>.katex>.katex-html>.tag{
      left:0;
      right:auto
    }
    .katex-display.fleqn>.katex{
      padding-left:2em;
      text-align:left
    }
    body{
      counter-reset:katexEqnNo mmlEqnNo
    }
    .slds-publisher.slds-is-active .slds-publisher__input, .slds-publisher--comment.slds-is-active, .slds-publisher_comment.slds-is-active {
      max-height: 50vh !important;
    }
    @keyframes slds-icon-typing__dot-first {
      0% {
          background-color: #c9c9c9
      }

      16% {
          background-color: #505050
      }

      33%,to {
          background-color: #c9c9c9
      }
    }
    @keyframes slds-icon-typing__dot-second {
      0%,33% {
          background-color: #c9c9c9
      }

      50% {
          background-color: #505050
      }

      67%,to {
          background-color: #c9c9c9
      }
    }
    @keyframes slds-icon-typing__dot-third {
      0%,67% {
          background-color: #c9c9c9
      }

      83% {
          background-color: #505050
      }

      to {
          background-color: #c9c9c9
      }
    }
    .stencil {
      background-color: lightgray !important;
      width: 52rem;
      height: 10rem;
    }
    .stencil2 {
      border-radius: .5rem;
      background-color: lightgray !important;
      width: 21rem;
      height: 1.5rem;
    }
    .dark slds-dropdown {
      --background-color: #2b2826;
      --table-hover: #000000;
      --text-color: white;
      --border-color: #706e6b;
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .fadeIn {
      opacity: 0; /* Make the element initially invisible */
      animation: fadeIn 300ms ease-in-out 50ms forwards; /* Duration of 300ms, 300ms delay */
    }
  `;

  render() {
    return html`
    ${(this.error) ? html`<div class="slds-notify slds-notify_alert slds-alert_error" role="alert">
      <span class="slds-assistive-text">error</span>
      <span class="slds-icon_container slds-icon-utility-error slds-m-right_x-small" title="Description of icon when needed">
        <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#error"></use>
        </svg>
      </span>
      <h2>${this.error.status} ${this.error.title}: ${this.error.detail}
        <a href="${this.error.type}">More Information</a>
      </h2>
      <div class="slds-notify__close">
        <button @click="${this.closeError}" class="slds-button slds-button_icon slds-button_icon-small slds-button_icon-inverse" title="Close">
          <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
          </svg>
          <span class="slds-assistive-text">Close</span>
        </button>
      </div>
    </div>` : ``}
    ${(!this.user) ? html`<section role="dialog" tabindex="0" aria-modal="true" aria-labelledby="prompt-heading-id" aria-describedby="prompt-message-wrapper" class="slds-modal slds-fade-in-open slds-modal_prompt">
      <div class="slds-modal__container">
        <div class="slds-modal__header slds-theme--info slds-theme--alert-texture">
          <h1 class="slds-text-heading_medium" id="prompt-heading-id">Login Is Required</h1>
        </div>
        <div class="slds-modal__content slds-p-around_medium" id="prompt-message-wrapper">
          <p>Currently the service is not available to users that are not authenticated.</p>
        </div>
        <div class="slds-modal__footer slds-theme_default">
          <a class="slds-button slds-button--brand" href="/login">Login</a>
        </div>
      </div>
    </section>
    <div class="slds-backdrop slds-backdrop_open" role="presentation"></div>` : ``}
    <section role="log" id="messages">
      <ul class="slds-chat-list slds-p-bottom_small ${this.getOffsetClass(this.chatboxActive, this.chatboxExpanded, this.error)}">
        ${this.chatMessageLoading ? html`<li class="slds-chat-listitem slds-chat-listitem_bookend fadeIn">
            <div class="slds-chat-bookend">
              <div class="stencil2"></div>
            </div>
          </li>
          ${this.stencils.map((val, index) =>
            html`<li class="slds-chat-listitem ${((index%2)==0)?'slds-chat-listitem_outbound':'slds-chat-listitem_inbound'} fadeIn">
              <div .style="${this.stencilCss(index)}" class="slds-chat-message">
                <div class="slds-chat-message__body">
                  <div class="slds-chat-message__text ${((index%2)==0)?'slds-chat-message__text_outbound':'slds-chat-message__text_inbound'} stencil"></div>
                </div>
              </div>
            </li>`
          )}` : `` }
        ${(!this.chatMessageLoading && this.chatMessages.length===0) ? html`<li><h4 class="slds-text-heading_large slds-text-align_center no-messages fadeIn">No Messages</h4></li>` : `` }
        ${this.chatMessages.map(message => html`
          ${this.renderMessage(message)}
        `)}
      </ul>
      <div class="user-write-area slds-p-around_small">
        <div class="slds-media slds-comment slds-hint-parent">
          <div class="slds-media__figure">
            ${this.user ? html`<slds-dropdown types="slds-dropdown_bottom slds-dropdown_left" @select="${this.dropdownSelect}" @blur="${this.toggleDropdown}" .open="${this.profileDropdownOpen}" .options="${this.profileDropdown}">
              <button @click="${this.toggleDropdown}" class="slds-button slds-builder-header__item-action slds-media slds-media_center slds-text-link_reset" aria-haspopup="true" aria-expanded="false" title="Click to open menu">
                <span class="slds-media__body">
                  <span aria-hidden="true" class="slds-avatar slds-avatar_circle">
                    ${this.user.photoUrl ? html`<img alt="${this.user.displayName}" src="${this.user.photoUrl}" title="${this.user.displayName}" />` : html`<abbr class="slds-avatar__initials slds-avatar__initials_inverse" title="${this.user.displayName}">${this.getFirstCharsOfFirstTwoWords(this.user.displayName)}</abbr>` }
                  </span>
                  <span class="slds-assistive-text">Show More</span>
                </span>
              </button>
            </slds-dropdown>` : ``}
          </div>
          <div class="slds-media__body">
            <div class="slds-publisher slds-publisher_comment ${this.chatboxActive ? 'slds-is-active' : ''} ${this.chatboxExpanded ? 'slds-has-focus' : ''}">
              <label for="commentText" class="slds-assistive-text">Write a message</label>
              <textarea @input="${this.adjustHeight}" rows="1" ?disabled="${!this.user}" @click="${this.handleClick}" @focus="${this.handleFocus}" id="commentText" class="slds-publisher__input slds-input_bare slds-text-longform" placeholder="Write a message…"></textarea>
              <div class="slds-publisher__actions slds-grid slds-grid_align-spread">
                <ul class="slds-grid">
<!--                   <li>
                    <button class="slds-button slds-button_icon slds-button_icon-container" title="Add User">
                      <svg class="slds-button__icon" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#adduser"></use>
                      </svg>
                      <span class="slds-assistive-text">Add User</span>
                    </button>
                  </li> -->
                  <li>
                    <button class="slds-button slds-button_icon slds-button_icon-container" title="Attach a file">
                      <svg class="slds-button__icon" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#attach"></use>
                      </svg>
                      <span class="slds-assistive-text">Attach a file</span>
                    </button>
                  </li>
                </ul>
                <button class="slds-button slds-button_brand" ?disabled="${this.sendingMsg}" @click="${this.sendMsg}">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    `
  }
}