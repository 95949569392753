import {LitElement, html, css} from 'lit'
import { customElement, property } from 'lit/decorators.js';
import store from '../views/store';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { loadChat } from '../views/store';
import { loadGlobalStyles } from './slds-system';

@customElement("slds-search")
export class SldsSearch extends connect(store)(LitElement) {
  // static get properties() {
  //   return {
  //     label: { type: String },
  //     open: { type: Boolean }
  //   };
  // }
  @property({type: Boolean}) loading = false;
  @property({type: Boolean}) noResults = false;
  @property({type: Array}) stencils = [0,1,2,3,4,5,6,7];
  @property({type: Object}) user = null;
  @property({type: Array}) values = [];
  @property({type: String}) value = '';
  @property({type: String}) label;
  @property({type: Boolean}) open;

  constructor() {
    super();
    this.debouncer = null;
    document.onkeydown = (function(evt) {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
            isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
          this.open = false;
        }
    }).bind(this);
    document.addEventListener('click', (function(e) {
      if (this.open) {
        const path = e.composedPath();
        let insideMenuClick = false;
        if(!(path === undefined || path.length == 0)) {
         insideMenuClick = path.find((el) => { return el == this; });
        }
        if(insideMenuClick) {
          //inside element
        } else {
          //outside element
          this.open = false;
          for (var i = this.getElementsByTagName("a").length - 1; i >= 0; i--) {
            this.getElementsByTagName("a")[i].ariaExpanded = "false";
          }
          const event = new CustomEvent('blur', { open: this.open, bubbles: true });
          this.dispatchEvent(event);
        }
      }
    }).bind(this), false);
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  stateChanged(state) {
    this.user = state.user;
  }

  activate() {
    this.open = true;
  }

  deactivate() {
    this.open = false;
  }

  stencilCss(index) {
    return "opacity:"+((this.stencils.length - index)/this.stencils.length)+";";
  }

  isOpenClass(isOpen) {
    return (isOpen ? 'slds-is-open' : 'slds-is-closed');
  }

  getApiKey() {
    var apiKey = null;
    if (this.user && this.user.customAttributes) {
      const custom = JSON.parse(this.user.customAttributes);
      if (custom.apiKey) {
        apiKey = custom.apiKey;
      }
    }
    if (this.user && this.user.customClaims) {
      const custom = this.user.customClaims;
      if (custom.apiKey) {
        apiKey = custom.apiKey;
      }
    }
    return apiKey;
  }

  async fetchData(value) {
    const apiKey = this.getApiKey();
    try {
      const response = await fetch(window.endpoint+'/api/chats/search?' + new URLSearchParams({
          query: value
      }), 
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': apiKey
        }
      });


      if (response.status === 401) {
        // Handle 401 unauthorized response
        throw new Error('auth/invalid-api-key');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const chatsData = await response.json();
      this.loading = false;
      this.values = chatsData;
      if (this.values.length===0) {
        this.noResults = true;
      }
      else
      {
        this.noResults = false;
      }
      // store.dispatch(setChats(chatsData));
      // this.requestUpdate();
    } catch (error) {
      this.loading = false;
      debugger;
    }
  }

  debouncedSearch(e) {
    this.value = e.target.value;
    const value = this.value;
    this.loading = true;

    if (this.debouncer) {
      clearTimeout(this.debouncer);
    }

    this.debouncer = setTimeout((() => {
      // Perform your search operation here (e.g., make API call)
      this.fetchData(value);
      console.log('Performing search for:', value);
    }).bind(this), 300); // Adjust the debounce delay (in milliseconds) as needed
  }

  renderMessageContent(message) {
    const { message_content, highlight } = message;

    if (!highlight || highlight.length === 0) {
        return html`${message_content}`;
    }

    const changesArr = this.replaceEmWithSpan(message_content, highlight);

    if (changesArr.length>0) {
      return changesArr.map((change, index) => 
        html`${change.before} <span class="highlight">${change.insideEm}</span> ${change.after}`
      );
    }
    else
    {
      return `${message_content}`;
    }

    // return `${before}&nbsp;<span class="highlight">${insideEm}</span>&nbsp;${after}`;
  }

  replaceEmWithSpan(messageContent, highlight) {
    let changesArr = [];

    highlight.forEach(item => {
      let fragments = item.split('</em>');
      fragments.forEach(fragment => {
        let startIndex = fragment.indexOf('<em>');
        if (startIndex !== -1) {
          const before = fragment.substring(0, startIndex);
          const insideEm = fragment.substring(startIndex + 4);
          changesArr.push({ before, insideEm, after: '' });
        } else {
          // This fragment doesn't contain <em>, it's part of the 'after' content of the previous fragment
          if (changesArr.length > 0) {
            changesArr[changesArr.length - 1].after += fragment;
          } else {
            // Handle the case where the first fragment doesn't contain <em>
            changesArr.push({ before: '', insideEm: '', after: fragment });
          }
        }
      });
    });

    return changesArr;
  }

  selectItem(e) {
    var target = e.target;
    var i = 0;
    while((!target.dataset.id || !target.href) && i<5) {
      target = target.parentElement;
      i++;
    }
    var targetId = target.dataset.id;
    var chatId = target.dataset.chatId;
    this.open = false;
    history.pushState({}, null, "/chat/"+chatId+"/"+targetId);
    store.dispatch(loadChat(chatId));
    e.preventDefault();
  }

  renderItems(values) {
    if (this.noResults) {
      return html`<li class="slds-dropdown__item slds-is-disabled" aria-level="1" role="treeitem">
        <span class="slds-tree__item-label slds-truncate slds-text-align_center slds-m-top_x-small slds-m-bottom_x-small" title="No results">No results</span>
      </li>`;
    }
    return this.values.map((message, index) => 
      html`<li class="slds-dropdown__item" aria-level="1" role="treeitem">
        <a class="slds-truncate" href="#" @click="${this.selectItem}" title="${message.message_content}" data-id="${message.id}" data-chat-id="${message.chat_id}" role="menuitem" tabindex="${index}">
          ${this.renderMessageContent(message)}
        </a>
      </li>`
    );
  }

  renderDropdown() {
    if (this.loading || this.noResults || this.values.length > 0)
    {
      return html`<div class="slds-dropdown slds-size_1-of-1 slds-dropdown_left">
        <ul class="slds-dropdown__list" role="menu" aria-label="Show More">
          ${this.loading ? this.stencils.map((val, index) =>
            html`<li .style="${this.stencilCss(index)}" class="stencil slds-is-disabled" aria-disabled="true" aria-level="1" role="treeitem">
              <div class="slds-tree__item">
                <span class="slds-tree__item-label slds-truncate" title="Stencil"></span>
              </div>
            </li>`
          ) : this.renderItems(this.values)}
        </ul>
      </div>`;
    }
  }

// style="--text-color: white;--slds-c-button-text-color: white;--slds-c-button-text-color-hover: white;--slds-c-button-text-color-active: #b0adab;" 
  static styles = css`
    .stencil span {
      visibility: hidden;
    }
    .stencil:first-child div.slds-tree__item {
      margin-top: 1rem;
    }
    .stencil:last-child div.slds-tree__item {
      margin-bottom: 1rem;
    }
    .stencil div.slds-tree__item {
      background-color: lightgray !important;
      margin: 1rem;
      border-radius: 1rem;
      padding: 0.275rem 0 0.275rem 1rem;
      margin: 1.25rem 1rem;
    }
    .slds-is-disabled>.slds-tree__item {
      cursor: default;
    }
    .highlight {
      background-color: #faf33e;
      color: #181818;
    }
    .slds-dropdown__item a.slds-truncate {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    button.slds-button, button.slds-button:hover {
      color: var(--text-color);
    }
    input.slds-input {
      background-color: var(--background-color, white);
      border: 1px solid var(--border-color, #c9c9c9);
      color: var(--text-color, #181818);
    }
    .slds-dropdown {
      background: var(--background-color, #fff);
      color: var(--text-color, #181818);
      border: 1px solid var(--border-color, #e5e5e5);
    }
    .slds-dropdown__item>a {
      color: var(--text-color, #181818);
    }
    .slds-dropdown__item>a:focus, .slds-dropdown__item>a:hover {
      background-color: var(--table-hover, #f3f3f3);
    }
  `;

  render() {
    return html`
      <div class="slds-dropdown-trigger slds-dropdown-trigger_click ${this.isOpenClass(this.open)}">
        ${this.open ? html`<div class="slds-form-element">
  <div class="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right">
    <svg class="slds-icon slds-input__icon slds-input__icon_left slds-icon-text-default" aria-hidden="true">
      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
    </svg>
    <input .value="${this.value}" @keydown="${this.debouncedSearch}" @input=${this.debouncedSearch} type="text" id="search-input" placeholder="Input query…" class="slds-input" />
    <button @click="${this.deactivate}" class="slds-button slds-button_icon slds-input__icon slds-input__icon_right" title="Clear">
      <svg class="slds-button__icon slds-icon-text-light" aria-hidden="true">
        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
      </svg>
      <span class="slds-assistive-text">Clear</span>
    </button> 
  </div>
</div>
${this.renderDropdown()}` : html`<button @click="${this.activate}" class="slds-button">
          <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
          </svg>
          ${this.label}
        </button>`}
      </div>
    `
  }
}
