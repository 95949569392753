import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import store from '../views/store';
import { forceRedraw, loadChat, setTheme } from '../views/store';
import { connect } from 'pwa-helpers/connect-mixin.js';
import {
  format,
  isToday,
  isYesterday,
  isThisWeek,
  isThisMonth,
  isThisYear,
  parseISO,
  subMonths,
  subDays,
  isWithinInterval,
  subYears,
} from 'date-fns';
import localStorageService from '../components/LocalStorageService';
import { loadGlobalStyles, setElementTheme } from '../components/slds-system';

@customElement('sidebar-component')
class MainView extends connect(store)(LitElement) {
  @property({type: String}) selected = "main";
  @property({type: Boolean}) chatMode = false;
  @property({type: Boolean}) loading = true;
  @property({type: Array}) stencils = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19];
  @property({type: Array}) historyItems = null;
  @property({type: String}) loadedId = "";
  @property({type: String}) theme = "";

  stateChanged(state) {
    if (state.chats) {
      this.historyItems = this.categorizeItems(state.chats);
      this.requestUpdate();
    }
    this.loadedId = state.chat_id;
    if (!(this.theme===state.theme)) {
      this.theme = state.theme;
      const elementTarget = this.shadowRoot.getElementById("sidebar");
      if (!elementTarget) {
        setTimeout((function(){
          setElementTheme(this.shadowRoot.getElementById("sidebar"), this.theme)
        }).bind(this))
      }
      else
      {
        setElementTheme(elementTarget, this.theme);
      }
    }
  }

  categorizeItems(items) {
    const categorized = {
      Today: [],
      Yesterday: [],
      'Last 7 Days': [],
      'Last Month': [],
      'Last 3 Months': [],
      'Last Year': [],
      Older: [],
    };

    items.forEach(item => {
      const date = parseISO(item.timestamp);    

      const formattedItem = {
        label: item.chat_name,
        value: item.chat_id,
        timestamp: item.timestamp,
      };

      if (isToday(date)) {
        categorized.Today.push(formattedItem);
      } else if (isYesterday(date)) {
        categorized.Yesterday.push(formattedItem);
      } else if (isWithinInterval(date, { start: subDays(new Date(), 7), end: new Date() })) {
        categorized['Last 7 Days'].push(formattedItem);
      } else if (isWithinInterval(date, { start: subMonths(new Date(), 1), end: new Date() })) {
        categorized['Last Month'].push(formattedItem);
      } else if (isWithinInterval(date, { start: subMonths(new Date(), 3), end: new Date() })) {
        categorized['Last 3 Months'].push(formattedItem);
      } else if (isThisYear(date)) {
        categorized['Last Year'].push(formattedItem);
      } else {
        categorized.Older.push(formattedItem);
      }
    });

    // Remove empty categories
    Object.keys(categorized).forEach(category => {
      if (categorized[category].length === 0) {
        delete categorized[category];
      }
      else
      {
        categorized[category] = categorized[category].sort((a, b) => {
          const timestampDiff = new Date(a.timestamp) - new Date(b.timestamp);
          if (timestampDiff !== 0) return timestampDiff;
          return b.last_seen - a.last_seen;
        }).reverse();
      }
    });

    return Object.keys(categorized).map(header => ({
      header,
      items: categorized[header]
    }));
  };

  checkboxChecked(e) {
    var theme;
    // const targetEl = this.shadowRoot.getElementById("sidebar"); //document.documentElement
    if (e.target.checked) {
      theme = "dark";
      localStorageService.setItem("theme", "dark");
      localStorage.setItem("theme", "dark");
    }
    else
    {
      theme = "light";
      localStorageService.setItem("theme", "light");
      localStorage.setItem("theme", "light")
    }
    store.dispatch(setTheme(theme));
    document.body.click();
    // this.toggleMenu = false;
    // setTimeout((function(){ this.toggleMenu = true; }).bind(this), 500)
    store.dispatch(forceRedraw());
  }

  toStr(test) {
    debugger;
  }

  selectHistoryItem(e) {
    var target = e.target;
    var i = 0;
    while(!target.dataset.value && i<5) {
      target = target.parentElement;
      i++;
    }
    const targetChat = target.dataset.value;
    store.dispatch(loadChat(""));
    store.dispatch(loadChat(targetChat));
    history.pushState({}, null, "/chat/"+targetChat);

    // Create the custom event
    const event = new CustomEvent('selected', {
      detail: { chat: targetChat },
      // bubbles: true, // Allows the event to bubble up through the DOM
      // composed: true // Allows the event to pass through shadow DOM boundaries
    });

    // Dispatch the custom event
    this.dispatchEvent(event);
    e.preventDefault();
  }

  selectMenuItem(e) {
    var target = e.target;
    var i = 0;
    while((!target.href || !(target.tagName === 'A')) && i<5) {
      target = target.parentElement;
      i++;
    }
    const targetHref = target.href;
    if (!(targetHref === "https://chat.inclouds.io")) {
      window.history.pushState({}, null, targetHref);
      window.dispatchEvent(new CustomEvent('route'));
      e.preventDefault();
    }
  }

  renderHistoryItem(history) {
    const middlePart = history.items.map(historyItem => html`<li class="slds-nav-vertical__item ${(this.loadedId==historyItem.value)?'slds-is-active':''}"><a @click="${this.selectHistoryItem}" data-value="${historyItem.value}" href="javascript:void(0);" class="slds-nav-vertical__action slds-truncate" aria-current="${(this.loadedId==historyItem.value)?true:false}">${historyItem.label}</a></li>`);
    return html`<li><div class="slds-nav-vertical__section">
          <h2 id="entity-header" class="slds-nav-vertical__title slds-text-title">${history.header}</h2>
          <ul aria-describedby="entity-header">${middlePart}</ul></div></li>`;
  }

  renderHistory(histories) {
    if (!histories) {
      return this.stencils.map((val, index) =>
        html`<li .style="${this.stencilCss(index)}" class="stencil slds-is-disabled slds-m-right_xx-small" aria-disabled="true" aria-level="1" role="treeitem">
          <div class="slds-tree__item">
            <span class="slds-has-flexi-truncate">
              <span class="slds-tree__item-label slds-truncate" title="Stencil"></span>
            </span>
          </div>
        </li>`
      );
    }
    this.style.overflowY = "auto";
    if (histories.length>0) {
      return histories.map(history => html`
        ${this.renderHistoryItem(history)}
      `);
    }
    else
    {
      return html`<li class="slds-text-align_center slds-p-top_x-large">No History Items</li>`;
    }
  }

  stencilCss(index) {
    return "opacity:"+((this.stencils.length - index)/this.stencils.length)+";";
  }

  getChatHref() {
    if (window?.process?.env?.NODE_ENV === "production") {
      return "https://chat.inclouds.io";
    }
    return "/chat";
  }

  getHomeHref() {
    if (window?.process?.env?.NODE_ENV === "production") {
      const currentSubdomain = window.location.hostname.split('.')[0];
      if (currentSubdomain === 'chat') {
        return "https://inclouds.io/";
      }
      else
      {
        return "/";
      }
    }
    return "/";
  }

  static styles = css`
    .slds-nav-vertical__action, .slds-text-title_caps, .slds-form-element__label, .slds-text-heading--label-normal, .slds-text-heading_label-normal, .slds-text-title, #darkmode {
      color: var(--font-color);
    }
    .slds-nav-vertical_shade .slds-is-active .slds-nav-vertical__action {
      border-color: var(--active-border);
    }
    .slds-nav-vertical_shade .slds-is-active.slds-nav-vertical__item:before, .slds-nav-vertical_shade .slds-nav-vertical__item:hover:before {
      background-color: var(--active-background);
    }
    .darkmode {
      position: fixed;
      bottom: 0rem;
      left: 0rem;
      background-color: var(--background-color);
      padding-top: 0;
      padding-bottom: 0.75rem;
    }
    .darkmode>hr {
      border-top: 1px solid #d8dee6;
      margin: 0;
      margin-bottom: 1rem;
      margin-left: 0.85rem;
    }
    .dark .darkmode>hr {
      border-top: 1px solid black;
    }
    .slds-nav-vertical__item a.slds-truncate {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (max-width: 767px) {
      #darkmode {
        margin-left: 0; /* Adjust the margin value as needed */
      }
    }
    .stencil span {
      visibility: hidden;
    }
    .stencil:first-child div.slds-tree__item {
      margin-top: 1rem;
    }
    .stencil:last-child div.slds-tree__item {
      margin-bottom: 1rem;
    }
    .stencil div.slds-tree__item {
      background-color: lightgray !important;
      margin: 1rem;
      border-radius: 1rem;
      padding: 0.275rem 0 0.275rem 1rem;
      margin: 1.25rem 1rem;
      cursor: default;
    }
  `;

  render() {
    return html`
    <div id="sidebar">
      <nav class="slds-nav-vertical slds-nav-vertical_shade" aria-label="Sub page">
        <div class="slds-nav-vertical__section">
          <h2 id="entity-header" class="slds-nav-vertical__title slds-text-title_caps">${(!this.chatMode) ? `MENU` : `HISTORY`}</h2>
          <ul class="slds-p-bottom_x-large slds-m-bottom_xx-large fadeIn" aria-describedby="entity-header">
            ${(!this.chatMode) ? html`<li class="slds-nav-vertical__item ${this.selected==="main"?"slds-is-active":""}">
              <a @click="${this.selectMenuItem}" href="${this.getHomeHref()}" class="slds-nav-vertical__action" aria-current="true"><svg class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#home"></use>
            </svg>Home</a>
            </li>
            <li class="slds-nav-vertical__item ${this.selected==="chat"?"slds-is-active":""}">
              <a @click="${this.selectMenuItem}" href="${this.getChatHref()}" class="slds-nav-vertical__action"><svg class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#engage"></use>
            </svg>Chat</a>
            </li>` : this.renderHistory(this.historyItems)}
          </ul>
          <div class="darkmode" role="presentation">
            <hr>
            <div class="slds-form-element slds-m-left_xx-small slds-p-left_small slds-p-right_large">
              <label class="slds-checkbox_toggle slds-grid">
                <span class="slds-form-element__label slds-m-bottom_none">Dark Mode</span>
                <input type="checkbox" checked="${ifDefined((this.theme==="dark") ? true : undefined)}" @change="${this.checkboxChecked}" name="darkmode" value="darkmode" aria-describedby="darkmode" />
                <span id="darkmode" class="slds-checkbox_faux_container slds-p-left_xx-large slds-m-left_x-large" aria-live="assertive">
                  <span class="slds-checkbox_faux"></span>
                  <span class="slds-checkbox_on">Enabled</span>
                  <span class="slds-checkbox_off">Disabled</span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </nav>
    </div>
    `
  }

  constructor() {
    super();
    this.style.overflowY = "hidden";
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }
}