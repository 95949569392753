import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { setUser, setAccessToken } from '../views/store';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('faq-view')
class FAQView extends LitElement {
  @property({type: Array}) faqData = [
    {
      title: "Common Errors",
      expanded: false,
      content: [
        {
          title: "Authentication Error",
          expanded: false,
          slug: 'auth-error',
          content: html`This error is best remediated by logging out and logging back in again. If this doesn't work please contact <a href='help'>support</a>.`
        },
        {
          title: "Internal Server Error",
          expanded: false,
          slug: 'internal-server-error',
          content: html`Internal server errors can be caused by a variety of reasons, in all cases they have already been logged and automatically reported to our development team.`
        },
        {
          title: "Parser Error",
          expanded: false,
          slug: 'parser-error',
          content: html`Parser has failed parsing invalid JSON, this usually occurs when a JSON response from the AI is expected, however the AI has provided an invalid JSON response.`
        },
        {
          title: "Rate Limit Exceeded",
          expanded: false,
          slug: 'rate-limit-exceeded',
          content: html`You have exceeded the rate limit for your account <a href='/error/rate-limits'>rate limits</a>.`
        },
        {
          title: "Invalid Model Provided",
          expanded: false,
          slug: 'invalid-model-provided',
          content: html`Invalid model selection provided.`
        },
        {
          title: "Invalid Request Data",
          expanded: false,
          slug: 'INVALID-REQUESTDATA',
          content: html`Invalid request data has been provided as input.`
        },
        {
          title: "Invalid UUID Provided",
          expanded: false,
          slug: 'invalid-uuid',
          content: html`Invalid UUID has been provided as input to the AI generating endpoint.`
        },
        {
          title: "Resource Not Found",
          expanded: false,
          slug: 'resource-not-found',
          content: html`This error can occur occassionally when a model that no longer exists is still being referenced.`
        },
        {
          title: "Auth Account Exists With Different Credential",
          expanded: false,
          slug: 'auth-account-exists-with-different-credential',
          content: html`An account for this email has already been registered with a different SSO provider. e.g. this error occurs when trying to login with the same email in Github after having signed up with Google.`
        },
        {
          title: "Auth Invalid Api Key",
          expanded: false,
          slug: 'auth-invalid-api-key',
          content: html`This can sometimes happen when the API key has been lost in the memory of the server, please try logging out and back in again.`
        }
      ]
    },
    {
      title: "Model Tiers and Access Limits",
      expanded: false,
      slug: 'rate-limits',
      content: html`<table class="slds-table slds-table_bordered slds-table_cell-buffer slds-table_col-bordered">
    <thead>
      <tr class="slds-line-height_reset">
        <th scope="col">
          <div class="slds-truncate" title="Model">Model</div>
        </th>
        <th scope="col">
          <div class="slds-truncate" title="Sub Model">Sub Model</div>
        </th>
        <th scope="col">
          <div class="slds-truncate" title="Tier">Tier</div>
        </th>
        <th scope="col">
          <div class="slds-truncate" title="Free User">Free User</div>
        </th>
        <th scope="col">
          <div class="slds-truncate" title="Premium User">Premium User</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row" rowspan="3">
          <div class="slds-truncate" title="Gemini">Gemini</div>
        </th>
        <td>
          <div class="slds-truncate" title="Gemini 1.5 Pro Preview">Gemini 1.5 Pro Preview</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="Gemini 1.5 Flash">Gemini 1.5 Flash</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 2">Tier 2</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="Gemini 1.0 Pro">Gemini 1.0 Pro</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <th scope="row" rowspan="9">
          <div class="slds-truncate" title="ChatGPT">ChatGPT</div>
        </th>
        <td>
          <div class="slds-truncate" title="GPT 4o">GPT 4o</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="GPT 4o Mini">GPT 4o Mini</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 2">Tier 2</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="GPT 4o 2024">GPT 4o 2024</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="GPT 4 Turbo 0125">GPT 4 Turbo 0125</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="GPT 4 Turbo">GPT 4 Turbo</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="GPT 4 Turbo 1106">GPT 4 Turbo 1106</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="GPT 4">GPT 4</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="GPT 3.5 Turbo 0125">GPT 3.5 Turbo 0125</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 2">Tier 2</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="GPT 3.5 Turbo 1106">GPT 3.5 Turbo 1106</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 2">Tier 2</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <th scope="row" rowspan="4">
          <div class="slds-truncate" title="Claude">Claude</div>
        </th>
        <td>
          <div class="slds-truncate" title="Claude 3.5 Sonnet">Claude 3.5 Sonnet</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="Claude 3 Opus">Claude 3 Opus</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 1">Tier 1</div>
        </td>
        <td>
          <div class="slds-truncate" title="Disabled">Disabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="Claude 3 Sonnet">Claude 3 Sonnet</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 2">Tier 2</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="slds-truncate" title="Claude 3 Haiku">Claude 3 Haiku</div>
        </td>
        <td>
          <div class="slds-truncate" title="Tier 2">Tier 2</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
        <td>
          <div class="slds-truncate" title="Enabled">Enabled</div>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="slds-m-top_xx-small">A lower tier indicates a smaller invocation limit for the model, Free users and Premium users can have different limits per Tier. The limits are reset every 3 hours and they are automatically adjusted.</p>`
    },
    {
      title: "User Policy and Terms of Service ELI5",
      expanded: false,
      content: html`<h2 class="slds-card__header-title slds-m-bottom_xx-small">
        <span>Terms of Service Summary</span>
      </h2>
      <ul class="slds-list_dotted">
        <li>
          <strong>Introduction:</strong> By using inClouds.io services, you agree to follow these rules.
        </li>
        <li>
          <strong>Acceptance of Terms:</strong> Using our services means you accept these terms.
        </li>
        <li>
          <strong>User Responsibilities:</strong> Use the services legally and responsibly. You're accountable for your actions and content.
        </li>
        <li>
          <strong>AI Models and Third-Party Terms:</strong> Our services include AI tools from other companies. You must follow their rules too.
        </li>
        <li>
          <strong>Privacy and Data Security:</strong> We protect your privacy, don't use your data for AI training, and only keep it as long as necessary. You can request deletion of your data.
        </li>
        <li>
          <strong>Data Usage and Retention:</strong> We analyze errors to improve services, but don't use this data for other purposes.
        </li>
      </ul>`
    },
    {
      title: "Free Trial Period",
      expanded: false,
      content: "Free trial period is available for any users that purchase Premium membership for the duration of the Beta, the free trial will last for exactly 30 days since the day of the purchase."
    },
    {
      title: "inClouds Beta Period",
      expanded: false,
      content: "The beta period will last for 1 to 3 months starting from July 2024. Early members of inClouds.io will be given additional benefits upon the cessation of the beta period."
    }
  ];

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  firstUpdated() {
    const path = window.location.pathname.split('/');
    const slug = path[path.length - 1];
    this.scrollToFAQ(slug);
  }

  toggleSection(index, subIndex = null) {
    this.faqData = this.faqData.map((section, i) => {
      if (i === index) {
        if (subIndex !== null && section.content instanceof Array) {
          section.content = section.content.map((subSection, j) => {
            if (j === subIndex) {
              subSection.expanded = !subSection.expanded;
            }
            return subSection;
          });
        } else {
          section.expanded = !section.expanded;
        }
      }
      return section;
    });
  }

  scrollToFAQ(slug) {
    this.faqData.forEach((section, index) => {
      if (section.slug === slug) {
        this.expandAndScroll(index);
      } else if (Array.isArray(section.content)) {
        section.content.forEach((subSection, subIndex) => {
          if (subSection.slug === slug) {
            this.expandAndScroll(index, subIndex);
          }
        });
      }
    });
  }

  expandAndScroll(index, subIndex = null) {
    this.toggleSection(index, null, true);
    if (subIndex !== null) {
      this.toggleSection(index, subIndex, true);
    }
    this.updateComplete.then(() => {
      const elementId = subIndex === null ? `section-${index}` : `section-${index}-${subIndex}`;
      const element = this.shadowRoot?.getElementById(elementId) || document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  renderSection(section, index, subIndex = null) {
    return html`
      <li class="slds-accordion__list-item">
        <section class="slds-accordion__section ${section.expanded ? 'slds-is-open' : ''}">
          <div class="slds-accordion__summary">
            <h2 class="slds-accordion__summary-heading">
              <button
                class="slds-button slds-button_reset slds-accordion__summary-action"
                aria-controls="section-${index}-${subIndex}"
                aria-expanded="${section.expanded}"
                @click="${() => this.toggleSection(index, subIndex)}"
              >
                <svg class="slds-accordion__summary-action-icon slds-button__icon slds-button__icon_left" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                </svg>
                <span class="slds-accordion__summary-content">${section.title}</span>
              </button>
            </h2>
          </div>
          <div class="slds-accordion__content" id="section-${index}-${subIndex}" ?hidden="${!section.expanded}">
            ${Array.isArray(section.content)
              ? html`<ul class="slds-accordion">${section.content.map((subSection, subIndex) => this.renderSection(subSection, index, subIndex))}</ul>`
              : html`${section.content}`
            }
          </div>
        </section>
      </li>
    `;
  }

  static styles = css`
    .slds-table thead th, .slds-table {
      background-color: var(--background-color, #f3f3f3);
      color: var(--text-color, #181818);
    }
    span {
      color: var(--text-color, #181818);
    }
    svg.slds-button__icon {
      fill: var(--text-color, #181818);
    }
  `;

  render() {
    return html`
      <main class="slds-p-left_large slds-p-top_medium slds-p-right_large" id="faq">
        <ul class="slds-accordion">
          ${this.faqData.map((section, index) => this.renderSection(section, index))}
        </ul>
      </main>
    `;
  }
}