// source: protobufs/ResponseData.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.protobufs.Instruction', null, global);
goog.exportSymbol('proto.protobufs.Modification', null, global);
goog.exportSymbol('proto.protobufs.RequiredFile', null, global);
goog.exportSymbol('proto.protobufs.ResponseData', null, global);
goog.exportSymbol('proto.protobufs.Schema', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobufs.ResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobufs.ResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobufs.ResponseData.displayName = 'proto.protobufs.ResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobufs.Schema = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobufs.Schema.repeatedFields_, null);
};
goog.inherits(proto.protobufs.Schema, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobufs.Schema.displayName = 'proto.protobufs.Schema';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobufs.RequiredFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobufs.RequiredFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobufs.RequiredFile.displayName = 'proto.protobufs.RequiredFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobufs.Instruction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protobufs.Instruction.repeatedFields_, null);
};
goog.inherits(proto.protobufs.Instruction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobufs.Instruction.displayName = 'proto.protobufs.Instruction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protobufs.Modification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protobufs.Modification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protobufs.Modification.displayName = 'proto.protobufs.Modification';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobufs.ResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.protobufs.ResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobufs.ResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.ResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    detail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    instance: jspb.Message.getFieldWithDefault(msg, 5, ""),
    responsetype: jspb.Message.getFieldWithDefault(msg, 6, ""),
    schema: (f = msg.getSchema()) && proto.protobufs.Schema.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 8, ""),
    inputtokens: jspb.Message.getFieldWithDefault(msg, 9, 0),
    outputtokens: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobufs.ResponseData}
 */
proto.protobufs.ResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobufs.ResponseData;
  return proto.protobufs.ResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobufs.ResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobufs.ResponseData}
 */
proto.protobufs.ResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstance(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsetype(value);
      break;
    case 7:
      var value = new proto.protobufs.Schema;
      reader.readMessage(value,proto.protobufs.Schema.deserializeBinaryFromReader);
      msg.setSchema(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInputtokens(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutputtokens(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobufs.ResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobufs.ResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobufs.ResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.ResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInstance();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResponsetype();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSchema();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protobufs.Schema.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInputtokens();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOutputtokens();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.protobufs.ResponseData.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.protobufs.ResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 status = 3;
 * @return {number}
 */
proto.protobufs.ResponseData.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string detail = 4;
 * @return {string}
 */
proto.protobufs.ResponseData.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string instance = 5;
 * @return {string}
 */
proto.protobufs.ResponseData.prototype.getInstance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setInstance = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string responseType = 6;
 * @return {string}
 */
proto.protobufs.ResponseData.prototype.getResponsetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setResponsetype = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Schema schema = 7;
 * @return {?proto.protobufs.Schema}
 */
proto.protobufs.ResponseData.prototype.getSchema = function() {
  return /** @type{?proto.protobufs.Schema} */ (
    jspb.Message.getWrapperField(this, proto.protobufs.Schema, 7));
};


/**
 * @param {?proto.protobufs.Schema|undefined} value
 * @return {!proto.protobufs.ResponseData} returns this
*/
proto.protobufs.ResponseData.prototype.setSchema = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.clearSchema = function() {
  return this.setSchema(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protobufs.ResponseData.prototype.hasSchema = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string error = 8;
 * @return {string}
 */
proto.protobufs.ResponseData.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 inputTokens = 9;
 * @return {number}
 */
proto.protobufs.ResponseData.prototype.getInputtokens = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setInputtokens = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 outputTokens = 10;
 * @return {number}
 */
proto.protobufs.ResponseData.prototype.getOutputtokens = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobufs.ResponseData} returns this
 */
proto.protobufs.ResponseData.prototype.setOutputtokens = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobufs.Schema.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobufs.Schema.prototype.toObject = function(opt_includeInstance) {
  return proto.protobufs.Schema.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobufs.Schema} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.Schema.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requiredfilesList: jspb.Message.toObjectList(msg.getRequiredfilesList(),
    proto.protobufs.RequiredFile.toObject, includeInstance),
    instructionsList: jspb.Message.toObjectList(msg.getInstructionsList(),
    proto.protobufs.Instruction.toObject, includeInstance),
    stroutput: jspb.Message.getFieldWithDefault(msg, 4, ""),
    explanation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    testing: jspb.Message.getFieldWithDefault(msg, 6, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobufs.Schema}
 */
proto.protobufs.Schema.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobufs.Schema;
  return proto.protobufs.Schema.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobufs.Schema} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobufs.Schema}
 */
proto.protobufs.Schema.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = new proto.protobufs.RequiredFile;
      reader.readMessage(value,proto.protobufs.RequiredFile.deserializeBinaryFromReader);
      msg.addRequiredfiles(value);
      break;
    case 3:
      var value = new proto.protobufs.Instruction;
      reader.readMessage(value,proto.protobufs.Instruction.deserializeBinaryFromReader);
      msg.addInstructions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStroutput(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExplanation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTesting(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobufs.Schema.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobufs.Schema.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobufs.Schema} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.Schema.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequiredfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.protobufs.RequiredFile.serializeBinaryToWriter
    );
  }
  f = message.getInstructionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.protobufs.Instruction.serializeBinaryToWriter
    );
  }
  f = message.getStroutput();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExplanation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTesting();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.protobufs.Schema.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Schema} returns this
 */
proto.protobufs.Schema.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RequiredFile requiredFiles = 2;
 * @return {!Array<!proto.protobufs.RequiredFile>}
 */
proto.protobufs.Schema.prototype.getRequiredfilesList = function() {
  return /** @type{!Array<!proto.protobufs.RequiredFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobufs.RequiredFile, 2));
};


/**
 * @param {!Array<!proto.protobufs.RequiredFile>} value
 * @return {!proto.protobufs.Schema} returns this
*/
proto.protobufs.Schema.prototype.setRequiredfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.protobufs.RequiredFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobufs.RequiredFile}
 */
proto.protobufs.Schema.prototype.addRequiredfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.protobufs.RequiredFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobufs.Schema} returns this
 */
proto.protobufs.Schema.prototype.clearRequiredfilesList = function() {
  return this.setRequiredfilesList([]);
};


/**
 * repeated Instruction instructions = 3;
 * @return {!Array<!proto.protobufs.Instruction>}
 */
proto.protobufs.Schema.prototype.getInstructionsList = function() {
  return /** @type{!Array<!proto.protobufs.Instruction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobufs.Instruction, 3));
};


/**
 * @param {!Array<!proto.protobufs.Instruction>} value
 * @return {!proto.protobufs.Schema} returns this
*/
proto.protobufs.Schema.prototype.setInstructionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.protobufs.Instruction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobufs.Instruction}
 */
proto.protobufs.Schema.prototype.addInstructions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.protobufs.Instruction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobufs.Schema} returns this
 */
proto.protobufs.Schema.prototype.clearInstructionsList = function() {
  return this.setInstructionsList([]);
};


/**
 * optional string strOutput = 4;
 * @return {string}
 */
proto.protobufs.Schema.prototype.getStroutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Schema} returns this
 */
proto.protobufs.Schema.prototype.setStroutput = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string explanation = 5;
 * @return {string}
 */
proto.protobufs.Schema.prototype.getExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Schema} returns this
 */
proto.protobufs.Schema.prototype.setExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string testing = 6;
 * @return {string}
 */
proto.protobufs.Schema.prototype.getTesting = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Schema} returns this
 */
proto.protobufs.Schema.prototype.setTesting = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string notes = 7;
 * @return {string}
 */
proto.protobufs.Schema.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Schema} returns this
 */
proto.protobufs.Schema.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobufs.RequiredFile.prototype.toObject = function(opt_includeInstance) {
  return proto.protobufs.RequiredFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobufs.RequiredFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.RequiredFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobufs.RequiredFile}
 */
proto.protobufs.RequiredFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobufs.RequiredFile;
  return proto.protobufs.RequiredFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobufs.RequiredFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobufs.RequiredFile}
 */
proto.protobufs.RequiredFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobufs.RequiredFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobufs.RequiredFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobufs.RequiredFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.RequiredFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.protobufs.RequiredFile.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.RequiredFile} returns this
 */
proto.protobufs.RequiredFile.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.protobufs.RequiredFile.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.RequiredFile} returns this
 */
proto.protobufs.RequiredFile.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protobufs.Instruction.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobufs.Instruction.prototype.toObject = function(opt_includeInstance) {
  return proto.protobufs.Instruction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobufs.Instruction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.Instruction.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modificationsList: jspb.Message.toObjectList(msg.getModificationsList(),
    proto.protobufs.Modification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobufs.Instruction}
 */
proto.protobufs.Instruction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobufs.Instruction;
  return proto.protobufs.Instruction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobufs.Instruction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobufs.Instruction}
 */
proto.protobufs.Instruction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = new proto.protobufs.Modification;
      reader.readMessage(value,proto.protobufs.Modification.deserializeBinaryFromReader);
      msg.addModifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobufs.Instruction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobufs.Instruction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobufs.Instruction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.Instruction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.protobufs.Modification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.protobufs.Instruction.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Instruction} returns this
 */
proto.protobufs.Instruction.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.protobufs.Instruction.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Instruction} returns this
 */
proto.protobufs.Instruction.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.protobufs.Instruction.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Instruction} returns this
 */
proto.protobufs.Instruction.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Modification modifications = 4;
 * @return {!Array<!proto.protobufs.Modification>}
 */
proto.protobufs.Instruction.prototype.getModificationsList = function() {
  return /** @type{!Array<!proto.protobufs.Modification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protobufs.Modification, 4));
};


/**
 * @param {!Array<!proto.protobufs.Modification>} value
 * @return {!proto.protobufs.Instruction} returns this
*/
proto.protobufs.Instruction.prototype.setModificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.protobufs.Modification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protobufs.Modification}
 */
proto.protobufs.Instruction.prototype.addModifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.protobufs.Modification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protobufs.Instruction} returns this
 */
proto.protobufs.Instruction.prototype.clearModificationsList = function() {
  return this.setModificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protobufs.Modification.prototype.toObject = function(opt_includeInstance) {
  return proto.protobufs.Modification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protobufs.Modification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.Modification.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0),
    length: jspb.Message.getFieldWithDefault(msg, 3, 0),
    text: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protobufs.Modification}
 */
proto.protobufs.Modification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protobufs.Modification;
  return proto.protobufs.Modification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protobufs.Modification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protobufs.Modification}
 */
proto.protobufs.Modification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLength(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protobufs.Modification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protobufs.Modification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protobufs.Modification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protobufs.Modification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.protobufs.Modification.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Modification} returns this
 */
proto.protobufs.Modification.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 position = 2;
 * @return {number}
 */
proto.protobufs.Modification.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobufs.Modification} returns this
 */
proto.protobufs.Modification.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 length = 3;
 * @return {number}
 */
proto.protobufs.Modification.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protobufs.Modification} returns this
 */
proto.protobufs.Modification.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.protobufs.Modification.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protobufs.Modification} returns this
 */
proto.protobufs.Modification.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.protobufs);
