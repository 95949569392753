import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import "./../components/slds-combobox.js";
import { loadGlobalStyles } from '../components/slds-system';

@customElement('settings-view')
class SettingsView extends LitElement {
  @property({type: String}) selectedTab = "slds-vertical-tabs-0";

  reset() {
    this.selectedTab = "slds-vertical-tabs-0";
  }

  constructor() {
    super();
    const currentPath = window.location.pathname.split("/").pop();
    if (currentPath==="password")
    {
      this.selectedTab = "slds-vertical-tabs-1";
    }
    else if (currentPath==="profile")
    {
      this.selectedTab = "slds-vertical-tabs-2";
    }
    else if (currentPath==="privacy&safety")
    {
      this.selectedTab = "slds-vertical-tabs-3";
    }
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  getTabLiClass(sel, target) {
    if (sel === target) {
      return "slds-vertical-tabs__nav-item slds-is-active";
    }
    else
    {
      return "slds-vertical-tabs__nav-item";
    }
  }

  getTabClass(sel, target) {
    if (sel === target) {
      return "slds-vertical-tabs__content slds-show";
    }
    else
    {
      return "slds-vertical-tabs__content slds-hide";
    }
  }

  clickLink(e) {
    e.preventDefault();
    var target = e.target;
    var i = 0;
    while((!target.getAttribute("aria-controls") && !target.getAttribute("aria-controls")) && i<5) {
      target = target.parentElement;
      i++;
    }
    const ariaControls = target.getAttribute("aria-controls");
    const href = target.getAttribute("href");
    this.selectedTab = ariaControls;
    history.pushState({}, null, href);
  }

  static styles = css`
    :host {
      margin-top: 4.5rem;
      margin-bottom: 1.5rem;
    }
    .slds-vertical-tabs__content, .slds-vertical-tabs__nav-item.slds-is-active {
      background-color: var(--background-color, #fff);
    }
    .slds-vertical-tabs {
      border: 1px solid var(--border-color, #e5e5e5);
    }
    .slds-vertical-tabs__nav {
      background-color: var(--background-color, #f3f3f3);
      border-right: 1px solid var(--border-color, #e5e5e5);
    }
    .slds-vertical-tabs__nav-item {
      border-bottom: 1px solid var(--border-color, #e5e5e5);
    }
    .slds-icon {
      --slds-c-icon-color-foreground: var(--text-color, #444);
    }
    .slds-form-element__label {
      color: var(--text-color, #444);
    }
    .slds-vertical-tabs__link {
      color: var(--text-color, #444);
    }
    .slds-vertical-tabs__link:hover svg, a:active svg {
      --slds-c-icon-color-foreground: #014486;
    }
  `;

  render() {
    return html`
    <div class="slds-p-top_small">
      <div class="slds-vertical-tabs slds-m-left_large slds-m-right_large slds-m-top_medium slds-m-bottom_large">
        <ul class="slds-vertical-tabs__nav" role="tablist" aria-orientation="vertical">
          <li class="${this.getTabLiClass(this.selectedTab, 'slds-vertical-tabs-0')}" title="Account" role="presentation">
            <a class="slds-vertical-tabs__link" href="/settings/account" @click="${this.clickLink}" role="tab" tabindex="0" aria-selected="true" aria-controls="slds-vertical-tabs-0" id="slds-vertical-tabs-0__nav">
              <span class="slds-vertical-tabs__left-icon">
                <span class="slds-icon_container" title="Identity Icon">
                  <svg class="slds-icon slds-icon_small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#identity"></use>
                  </svg>
                </span>
              </span>
              <span class="slds-truncate" title="Account">Account</span>
              <span class="slds-vertical-tabs__right-icon"></span>
            </a>
          </li>
          <li class="${this.getTabLiClass(this.selectedTab, 'slds-vertical-tabs-1')}" title="Password" role="presentation">
            <a class="slds-vertical-tabs__link" href="/settings/password" @click="${this.clickLink}" role="tab" tabindex="-1" aria-selected="false" aria-controls="slds-vertical-tabs-1" id="slds-vertical-tabs-1__nav">
              <span class="slds-vertical-tabs__left-icon">
                <span class="slds-icon_container" title="Lock Icon">
                  <svg class="slds-icon slds-icon_small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#lock"></use>
                  </svg>
                </span>
              </span>
              <span class="slds-truncate" title="Password">Password</span>
              <span class="slds-vertical-tabs__right-icon"></span>
            </a>
          </li>
          <li class="${this.getTabLiClass(this.selectedTab, 'slds-vertical-tabs-2')}" title="Profile" role="presentation">
            <a class="slds-vertical-tabs__link" href="/settings/profile" @click="${this.clickLink}" role="tab" tabindex="-1" aria-selected="false" aria-controls="slds-vertical-tabs-2" id="slds-vertical-tabs-2__nav">
              <span class="slds-vertical-tabs__left-icon">
                <span class="slds-icon_container" title="Edit Icon">
                  <svg class="slds-icon slds-icon_small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit"></use>
                  </svg>
                </span>
              </span>
              <span class="slds-truncate" title="Profile">Profile</span>
              <span class="slds-vertical-tabs__right-icon"></span>
            </a>
          </li>
          <li class="${this.getTabLiClass(this.selectedTab, 'slds-vertical-tabs-3')}" title="Privacy & Safety" role="presentation">
            <a class="slds-vertical-tabs__link" href="/settings/privacy&safety" @click="${this.clickLink}" role="tab" tabindex="-1" aria-selected="false" aria-controls="slds-vertical-tabs-3" id="slds-vertical-tabs-3__nav">
              <span class="slds-vertical-tabs__left-icon">
                <span class="slds-icon_container" title="Shield Icon">
                  <svg class="slds-icon slds-icon_small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#shield"></use>
                  </svg>
                </span>
              </span>
              <span class="slds-truncate" title="Privacy & Safety">Privacy & Safety</span>
              <span class="slds-vertical-tabs__right-icon"></span>
            </a>
          </li>
        </ul>
        <div class="${this.getTabClass(this.selectedTab, 'slds-vertical-tabs-0')}" id="slds-vertical-tabs-0" role="tabpanel" aria-labelledby="slds-vertical-tabs-0__nav">
          <div class="slds-text-longform">
            <h3 class="slds-text-heading_medium">Account</h3>
            <div class="slds-form">
              <div class="slds-form-element slds-form-element_stacked">
                <label class="slds-form-element__label" for="stacked-input-id-01">Username</label>
                <div class="slds-form-element__control">
                  <input type="text" id="stacked-input-id-01" placeholder="Username…" class="slds-input" />
                </div>
                <p>https://aigc.inclouds.io/user/testacc123</p>
              </div>
              <div class="slds-form-element slds-form-element_stacked">
                <label class="slds-form-element__label" for="stacked-input-id-02">Email</label>
                <div class="slds-form-element__control">
                  <input type="text" id="stacked-input-id-02" placeholder="Email…" class="slds-input" />
                </div>
                <p>Email will not be displayed publicly</p>
              </div>
              <div class="slds-form-element slds-form-element_stacked">
                <slds-combobox label="Mask Sensitive Content"></slds-combobox>
                <p>Sensitive content will be masked on inClouds AIGC</p>
              </div>
              <div class="slds-form-element slds-form-element_stacked">
                <slds-combobox label="Show Sensitive Content"></slds-combobox>
                <p>Allow sensitive contents to appears on inClouds AIGC</p>
              </div>
              <div class="slds-form-element slds-form-element_stacked">
                <slds-combobox label="Follow Thread on Reply"></slds-combobox>
              </div>
              <div class="slds-form-element slds-form-element_stacked">
                <slds-combobox label="Hide Offensive Comments"></slds-combobox>
                <p>Comments that are offensive will be automatically filtered from display.</p>
              </div>
              <button class="slds-button slds-button_brand slds-m-top_small slds-m-left_xx-small">Save Changes</button>
              <button class="slds-button slds-button_destructive slds-float_right slds-m-top_small slds-m-right_xx-small">Delete Account</button>
            </div>
          </div>
        </div>
        <div class="${this.getTabClass(this.selectedTab, 'slds-vertical-tabs-1')}" id="slds-vertical-tabs-1" role="tabpanel" aria-labelledby="slds-vertical-tabs-1__nav">
          <div class="slds-text-longform">
            <h3 class="slds-text-heading_medium">Password</h3>
            <div class="slds-form">
              <div class="slds-form-element slds-form-element_stacked">
                <label class="slds-form-element__label" for="stacked-input-id-03">Old Password</label>
                <div class="slds-form-element__control">
                  <input type="text" id="stacked-input-id-03" placeholder="Old Password" class="slds-input" />
                </div>
              </div>
              <div class="slds-form-element slds-form-element_stacked">
                <label class="slds-form-element__label" for="stacked-input-id-04">New Password</label>
                <div class="slds-form-element__control">
                  <input type="text" id="stacked-input-id-04" placeholder="New Password" class="slds-input" />
                </div>
              </div>
              <div class="slds-form-element slds-form-element_stacked">
                <label class="slds-form-element__label" for="stacked-input-id-05">Re-Type New Password</label>
                <div class="slds-form-element__control">
                  <input type="text" id="stacked-input-id-05" placeholder="Re-Type New Password" class="slds-input" />
                </div>
              </div>
              <button class="slds-button slds-button_brand slds-m-top_small slds-m-left_xx-small">Save Changes</button>
            </div>
          </div>
        </div>
        <div class="${this.getTabClass(this.selectedTab, 'slds-vertical-tabs-2')}" id="slds-vertical-tabs-2" role="tabpanel" aria-labelledby="slds-vertical-tabs-2__nav">
          <div class="slds-text-longform">
            <h3 class="slds-text-heading_medium">Profile</h3>
            <p>Content for Tab 2</p>
            <p>Lorem ipsum dolor...</p>
            <p>Lorem ipsum dolor...</p>
          </div>
        </div>
        <div class="${this.getTabClass(this.selectedTab, 'slds-vertical-tabs-3')}" id="slds-vertical-tabs-3" role="tabpanel" aria-labelledby="slds-vertical-tabs-3__nav">
          <div class="slds-text-longform">
            <h3 class="slds-text-heading_medium">Privacy & Safety</h3>
            <p>Content for Tab 3</p>
            <p>Lorem ipsum dolor...</p>
            <p>Lorem ipsum dolor...</p>
          </div>
        </div>
      </div>
    </div>
    `
  }
}