import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import store from './store';
import { loadGlobalStyles, setElementTheme } from '../components/slds-system';

@customElement('profile-view')
class ProfileView extends connect(store)(LitElement) {
  @property({type: Object}) error = null;
  @property({type: Object}) authUser = null;
  @property({type: Boolean}) fetchedGraphs = false;
  @property({type: Boolean}) loading = true;
  @property({type: String}) subRoute = '';
  @property({type: String}) apiKey = '';
  @property({type: String}) theme = '';

  stateChanged(state) {
    this.authUser = state.user;
    if (this.authUser?.customClaims?.apiKey) {
      this.apiKey = this.authUser?.customClaims?.apiKey;
    }
    this.subRoute = state.route;
    
    if (this.authUser && this.subRoute) {
      if (this.subRoute === "profile") {
        if (!this.fetchedGraphs) {
          this.getGraphData().then(() => {
            console.log("getGraphData done");
          });
          this.fetchedGraphs = true;
        }
      }
      else {
        this.fetchedGraphs = false;
      }
    }
    if (!(this.theme===state.theme)) {
      this.theme = state.theme;
      const elementTarget = this.shadowRoot.getElementById("profile");
      if (!elementTarget) {
        setTimeout((function(){
          setElementTheme(this.shadowRoot.getElementById("profile"), this.theme)
        }).bind(this))
      }
      else
      {
        setElementTheme(elementTarget, this.theme);
      }
    }
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  getApiKey() {
    var apiKey = null;
    if (this.authUser && this.authUser.customAttributes) {
      const custom = JSON.parse(this.user.customAttributes);
      if (custom.apiKey) {
        apiKey = custom.apiKey;
      }
    }
    if (this.authUser && this.authUser.customClaims) {
      const custom = this.authUser.customClaims;
      if (custom.apiKey) {
        apiKey = custom.apiKey;
      }
    }
    return apiKey;
  }

  // Function to generate a deterministic color for a given string (model name)
  stringToColor(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xFF;
          color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
  }

  async getGraphData() {
    const apiKey = this.getApiKey();
    try {
      const response = await fetch(window.endpoint+'/api/profile/usage', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': apiKey
        }
      });


      if (response.status === 401) {
        // Handle 401 unauthorized response
        throw new Error('auth/invalid-api-key');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const chatData = await response.json();
      this.updateChart(chatData);
      this.updateTokenCountsByModelLast30Days(chatData);
      this.loading = false;
      // debugger;
    } catch (error) {
      const myError = {type: error.message.split(" ").join("+"), title: error.message, status: 500, detail: error.message};
      this.error = myError;
    }
  }

  revealApiKey() {
    const apiKeyInput = this.shadowRoot.getElementById('api-key');
    const toggleButton = this.shadowRoot.getElementById('api-key-button');
    const copyButton = this.shadowRoot.getElementById('copy-button');
    if (apiKeyInput.type === 'password') {
        apiKeyInput.type = 'text';
        toggleButton.textContent = 'Hide API Key';
        copyButton.style.display = 'inline-block';
    } else {
        apiKeyInput.type = 'password';
        toggleButton.textContent = 'Show API Key';
        copyButton.style.display = 'none';
    }
  }

  copyApiKey() {
      const apiKeyInput = this.shadowRoot.getElementById('api-key');
      apiKeyInput.select();
      apiKeyInput.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(apiKeyInput.value);
      alert("API Key copied to clipboard");
  }

  static styles = css`
    img {
      min-height: initial;
    }
    .slds-avatar_x-large {
      height: 4rem;
      width: 4rem;
    }
/*      .profile-container {
      max-width: 60rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
    }*/
    .dark#profile .slds-card {
      --slds-c-card-color-background: #2b2826;
    }
    .highcharts-legend-item text {
      fill: black !important;
    }
    .dark .highcharts-legend-item text {
      fill: white !important;
    }
    .highcharts-xaxis-labels text {
      fill: black !important;
    }
    .dark .highcharts-xaxis-labels text {
      fill: white !important;
    }
    .highcharts-yaxis-labels:last-child text {
        fill: black !important;
    }
    .dark .highcharts-yaxis-labels:last-child text {
        fill: white !important;
    }
    #copy-button {
        display: none;
    }
    .dark #container2 .highcharts-yaxis-labels text {
      fill: white !important;
    }
    #container2 {
      max-height: 12.5rem;
    }
    #profile>div {
      height: 100vh;
      padding-bottom: 6rem;
      padding-top: 1rem;
      min-height: 36rem;
    }
    .graph-stencil {
      background-color: #f3f3f3;
      height: 12.6rem;
      border-radius: 0.5rem;
    }
    .dark .graph-stencil {
      background-color: #747474;
    }
    .graph-stencil.graph-stencil2 {
      height: 25rem;
    }
  `;

  render() {
    return html`
    <div id="profile">
      <div class="slds-grid slds-wrap slds-align_absolute-center slds-p-left_medium">
        <!-- Left Cell -->
        <div class="slds-col slds-size_8-of-12">
          <article class="slds-card slds-m-right_medium">
            <div class="slds-card__header slds-grid">
              <header class="slds-media slds-media_center slds-has-flexi-truncate">
                <div class="slds-media__figure">
                  <span class="slds-icon_container slds-icon-standard-task" title="Graph">
                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                      <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom102"></use>
                    </svg>
                    <span class="slds-assistive-text">Graph</span>
                  </span>
                </div>
                <div class="slds-media__body">
                  <h2 class="slds-card__header-title">
                    <a href="#" class="slds-card __header-link slds-truncate" title="User Requests and Token Usage per 3-Hour Period">
                      <span>User Requests and Token Usage per 3-Hour Period</span>
                    </a>
                  </h2>
                </div>
              </header>
            </div>
            <div class="slds-card__body slds-card__body_inner">
              <div class="graph-stencil graph-stencil2" ?hidden="${!this.loading}"></div>
              <div id="container" class="container" ?hidden="${this.loading}"></div>
            </div>
          </article>
        </div>
        <!-- Right Cells Container -->
        <div class="slds-col slds-size_4-of-12 slds-grid slds-wrap">
          <!-- Top Right Cell -->
          <div class="slds-col slds-size_1-of-1 slds-p-bottom_x-small">
            <article class="slds-card slds-m-right_medium">
              <div class="slds-card__header slds-grid">
                <header class="slds-media slds-media_center slds-has-flexi-truncate">
                  <div class="slds-media__figure">
                    <span class="slds-icon_container slds-icon-standard-task" title="Graph">
                      <svg class="slds-icon slds-icon_small" aria-hidden="true">
                        <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom102"></use>
                      </svg>
                      <span class="slds-assistive-text">Graph</span>
                    </span>
                  </div>
                  <div class="slds-media__body">
                    <h2 class="slds-card__header-title">
                      <a href="#" class="slds-card__header-link slds-truncate" title="Access Token">
                        <span>Summary of Last 30 Days</span>
                      </a>
                    </h2>
                  </div>
                </header>
              </div>
              <div class="slds-card__body slds-card__body_inner">
                <div class="slds-scope">
                  <div class="graph-stencil" ?hidden="${!this.loading}"></div>
                  <div id="container2" class="container2" ?hidden="${this.loading}"></div>
                </div>
              </div>
            </article>
          </div>
          <!-- Bottom Right Cell -->
          <div class="slds-col slds-size_1-of-1 slds-p-top_x-small">
            <article class="slds-card slds-m-right_medium">
              <div class="slds-card__header slds-grid">
                <header class="slds-media slds-media_center slds-has-flexi-truncate">
                  <div class="slds-media__figure">
                    <span class="slds-icon_container_circle slds-icon-action-preview slds-icon_container" title="Graph">
                      <svg class="slds-icon slds-icon_small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#unlock"></use>
                      </svg>
                      <span class="slds-assistive-text">Graph</span>
                    </span>
                  </div>
                  <div class="slds-media__body">
                    <h2 class="slds-card__header-title">
                      <a href="#" class="slds-card__header-link slds-truncate" title="Access Token">
                        <span>Access Token</span>
                      </a>
                    </h2>
                  </div>
                </header>
              </div>
              <div class="slds-card__body slds-card__body_inner">
                <div class="slds-scope">
                  <form class="slds-form--stacked">
                    <div class="slds-form-element slds-p-bottom_medium">
                        <label class="slds-form-element__label" for="api-key-button">Reveal API Key</label>
                        <div class="slds-form-element__control">
                            <input type="password" value="${this.apiKey}" id="api-key" class="slds-input slds-p-left_x-small slds-p-right_x-small" readonly>
                        </div>
                    </div>
                    <div class="slds-form-element">
                        <div class="slds-form-element__control">
                            <button type="button" class="slds-button slds-button--brand" id="api-key-button" @click="${this.revealApiKey}">Show API Key</button>
                            <button type="button" class="slds-button slds-button--success" id="copy-button" @click="${this.copyApiKey}">Copy</button>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      ${false ? html`<div class="slds-grid slds-p-left_medium slds-p-right_medium slds-p-bottom_medium">
        <!-- Right Cells Container -->
        <div class="slds-col slds-size_1-of-1 slds-large-size_8-of-12 slds-grid slds-wrap">
          <!-- Top Right Cell -->
          <div class="slds-col slds-size_1-of-2">
            <article class="slds-card">
              <div class="slds-card__header slds-grid">
                <header class="slds-media slds-media_center slds-has-flexi-truncate">
                  <div class="slds-media__figure">
                    <span class="slds-icon_container_circle slds-icon-action-preview slds-icon_container" title="Account">
                      <svg class="slds-icon slds-icon_small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#money"></use>
                      </svg>
                      <span class="slds-assistive-text">Account</span>
                    </span>
                  </div>
                  <div class="slds-media__body">
                    <h2 class="slds-card__header-title">
                      <a href="#" class="slds-card__header-link slds-truncate" title="Account Information">
                        <span>Account Information</span>
                      </a>
                    </h2>
                  </div>
                </header>
              </div>
              <div class="slds-card__body slds-card__body_inner">
                <div class="slds-scope">
                  <form class="slds-form">
                    <div class="slds-form-element slds-form-element_horizontal">
                      <label class="slds-form-element__label" for="account-balance">Current Balance</label>
  <!--                     <div class="slds-form-element__control">
                        <input type="text" id="account-balance" class="slds-input slds-p-left_x-small slds-p-right_x-small" readonly="" value="$10,000">
                      </div> -->
                      <div class="slds-form-element__control slds-input-has-fixed-addon">
                        <span class="slds-form-element__addon" id="fixed-text-addon-pre">$</span>
                        <input type="number" id="account-balance" value="2000" readonly="" aria-labelledby="fixed-text-label fixed-text-addon-pre fixed-text-addon-post" class="slds-input slds-p-left_x-small slds-p-right_x-small" />
                        <span class="slds-form-element__addon" id="fixed-text-addon-post">USD</span>
                      </div>
                    </div>
                    <div class="slds-form-element slds-form-element_horizontal">
                      <label class="slds-form-element__label" for="account-type">Account Type</label>
                      <div class="slds-form-element__control">
                        <input type="text" id="account-type" class="slds-input slds-p-left_x-small slds-p-right_x-small" readonly="" value="Premium">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </div>
          <!-- Bottom Right Cell -->
          <div class="slds-col slds-size_1-of-2">
            <article class="slds-card">
              <div class="slds-card__header slds-grid">
                <header class="slds-media slds-media_center slds-has-flexi-truncate">
                  <div class="slds-media__figure">
                    <span class="slds-icon_container_circle slds-icon-action-preview slds-icon_container" title="Account Recharge">
                      <svg class="slds-icon slds-icon_small" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#money"></use>
                      </svg>
                      <span class="slds-assistive-text">Account Recharge</span>
                    </span>
                  </div>
                  <div class="slds-media__body">
                    <h2 class="slds-card__header-title">
                      <a href="#" class="slds-card__header-link slds-truncate" title="Account Recharge Settings">
                        <span>Account Recharge Settings</span>
                      </a>
                    </h2>
                  </div>
                </header>
              </div>
              <div class="slds-card__body slds-card__body_inner">
                <div class="slds-scope">
                  <form class="slds-form">
                    <div class="slds-form-element slds-form-element_horizontal">
                      <label class="slds-form-element__label" for="recharge-amount">Recharge Amount</label>
                      <div class="slds-form-element__control">
                        <input type="number" id="recharge-amount" class="slds-input" placeholder="Enter amount">
                      </div>
                    </div>
                    <div class="slds-form-element slds-form-element_horizontal">
                      <label class="slds-form-element__label" for="balance-threshold">Balance Threshold</label>
                      <div class="slds-form-element__control">
                        <input type="number" id="balance-threshold" class="slds-input" placeholder="Enter threshold">
                      </div>
                    </div>
                    <div class="slds-form-element slds-form-element_horizontal">
                      <div class="slds-form-element__control">
                        <button type="button" class="slds-button slds-button_brand" id="save-settings-button">Save Settings</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>` : ``}
    `
  }

  // Function to generate the last 24 hours in 3-hour periods
  generateLast24Hours() {
    const periods = [];
    const now = new Date();
    now.setMinutes(0, 0, 0); // Round to the nearest hour
    
    // Adjust hours to the nearest 12, 3, 6, or 9
    const hour = now.getHours();
    if (hour >= 0 && hour < 3) {
      now.setHours(3);
    } else if (hour >= 3 && hour < 6) {
      now.setHours(6);
    } else if (hour >= 6 && hour < 9) {
      now.setHours(9);
    } else if (hour >= 9 && hour < 12) {
      now.setHours(12);
    } else if (hour >= 12 && hour < 15) {
      now.setHours(15);
    } else if (hour >= 15 && hour < 18) {
      now.setHours(18);
    } else if (hour >= 18 && hour < 21) {
      now.setHours(21);
    } else {
      now.setHours(24); // This will round to 00:00 of the next day
    }

    for (let i = 0; i < 8; i++) {
        const end = new Date(now - i * 3 * 60 * 60 * 1000);
        const start = new Date(end - 3 * 60 * 60 * 1000);
        const period = `${start.toLocaleString()} (${start.getHours()}-${end.getHours()}h)`;
        periods.unshift(period);
    }

    return periods;
  }

  async updateTokenCountsByModelLast30Days(data) {
    const models = new Set();
    const modelTokens = {};

    // Gather all models
    data.forEach(entry => models.add(entry.model));

    // Initialize modelTokens for all models
    models.forEach(model => {
        modelTokens[model] = {
            inputTokens: 0,
            outputTokens: 0,
            totalTokens: 0
        };
    });

    // Process the data for the last 30 days
    const now = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(now.getDate() - 30);
    const thirtyDaysAgoTimestamp = thirtyDaysAgo.getTime() / 1000;

    data.forEach(entry => {
        if (entry.timestamp >= thirtyDaysAgoTimestamp) {
            const model = entry.model;
            if (entry.inputTokens) {
                modelTokens[model].inputTokens += entry.inputTokens;
                modelTokens[model].totalTokens += entry.inputTokens;
            }
            if (entry.outputTokens) {
                modelTokens[model].outputTokens += entry.outputTokens;
                modelTokens[model].totalTokens += entry.outputTokens;
            }
        }
    });

    // Prepare data for series
    const categories = Array.from(models);
    const seriesData = categories.map(model => modelTokens[model].totalTokens);
    // Update chart
    const Highcharts = await import('highcharts');
    Highcharts.chart(this.renderRoot.querySelector('#container2'), {
        chart: {
            type: 'column',
            backgroundColor: 'transparent'
        },
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: categories,
            crosshair: true,
            title: {
                text: null
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: null
            }
        },
        tooltip: {
            shared: true,
            formatter: function() {
                let tooltip = '<b>' + this.x + '</b>';
                this.points.forEach(point => {
                    tooltip += '<br/><span style="color:' + point.color + '">\u25CF</span> ' +
                        point.series.name + ': <b>' + point.y + '</b> tokens' +
                        '<br/>Input Tokens: <b>' + modelTokens[point.key].inputTokens + '</b>' +
                        '<br/>Output Tokens: <b>' + modelTokens[point.key].outputTokens + '</b>';
                });
                return tooltip;
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            name: 'Total Tokens',
            data: seriesData,
            colorByPoint: true,
            showInLegend: false
        }]
    });
  }

  async updateChart(data) {
    const periods = this.generateLast24Hours(); // Assume this generates consistent 3-hour intervals
    const invocationCounts = {};
    const modelTokens = {};

    // Initialize invocationCounts for all periods
    periods.forEach(period => {
        invocationCounts[period] = 0;
    });

    // Process the data
    data.forEach(entry => {
        // const date = new Date(entry.timestamp * 1000);
        // const hours = date.getHours();
        // // const period = `${date.toLocaleDateString()} (${hours}-${(hours + 3) % 24}h)`;
        // const period = `${date.toLocaleString()} (${date.getHours()}-${date.getHours()+3}h)`;
        const now = new Date((entry.timestamp * 1000));
        // now.setMinutes(0, 0, 0); // Round to the nearest hour
        // now.setHours(now.getHours() + 1);
        const i = -1;
        const end = new Date(now - i * 3 * 60 * 60 * 1000);
        const start = new Date(end - 3 * 60 * 60 * 1000);
        const period = `${start.toLocaleString()} (${start.getHours()}-${end.getHours()}h)`;
        const model = entry.model;

        // Ensure the period exists in the periods array
        // if (!periods.includes(period)) {
        //     periods.push(period);
        //     invocationCounts[period] = 0; // Initialize new period in invocationCounts
        // }

        // Update invocation counts
        if (entry.count) {
            invocationCounts[period] += entry.count;
        }

        // Update tokens for each model
        if (!modelTokens[model]) {
            modelTokens[model] = {};
        }

        if (!modelTokens[model][period]) {
            modelTokens[model][period] = {
                inputTokens: 0,
                outputTokens: 0,
                totalTokens: 0
            };
        }

        if (entry.inputTokens) {
            modelTokens[model][period].inputTokens += entry.inputTokens;
            modelTokens[model][period].totalTokens += entry.inputTokens;
        }

        if (entry.outputTokens) {
            modelTokens[model][period].outputTokens += entry.outputTokens;
            modelTokens[model][period].totalTokens += entry.outputTokens;
        }
    });

    // Prepare data for series
    const invocationSeries = {
        name: 'Invocation Count',
        type: 'line',
        yAxis: 0,
        data: periods.map(period => invocationCounts[period] || 0),
        tooltip: {
            valueSuffix: ' invocations'
        }
    };

    const tokenSeries = Object.keys(modelTokens).map(model => {
        return {
            name: `${model} Tokens`,
            type: 'column',
            yAxis: 1,
            data: periods.map(period => {
                const tokenData = modelTokens[model][period];
                return tokenData ? {
                    y: tokenData.totalTokens,
                    inputTokens: tokenData.inputTokens,
                    outputTokens: tokenData.outputTokens
                } : { y: 0, inputTokens: 0, outputTokens: 0 };
            }),
            stack: `${model}Tokens`,
            color: this.stringToColor(model)
        };
    });

    // Update chart
    const Highcharts = await import('highcharts');
    Highcharts.chart(this.renderRoot.querySelector('#container'), {
        chart: {
            zoomType: 'xy',
            backgroundColor: 'transparent'
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        xAxis: [{
            categories: periods,
            crosshair: true
        }],
        yAxis: [{ // Primary yAxis for Invocation Counts
            labels: {
                format: '{value}',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            title: {
                text: 'Invocation Count',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            }
        }, { // Secondary yAxis for Tokens
            title: {
                text: 'Tokens',
                style: {
                    color: 'white'
                }
            },
            labels: {
                format: '{value}',
                style: {
                    color: 'white'
                }
            },
            opposite: true
        }],
        tooltip: {
            shared: true,
            formatter: function() {
                let tooltip = '<b>' + this.x + '</b>';
                this.points.forEach(point => {
                    if (point.series.name.includes('Invocation Count')) {
                        tooltip += '<br/><span style="color:' + point.color + '">\u25CF</span> ' +
                            point.series.name + ': <b>' + point.y + '</b> invocations';
                    } else {
                        tooltip += '<br/><span style="color:' + point.color + '">\u25CF</span> ' +
                            point.series.name + ': <b>' + point.point.inputTokens + '</b> input, ' +
                            '<b>' + point.point.outputTokens + '</b> output';
                    }
                });
                return tooltip;
            }
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            x: 80,
            verticalAlign: 'top',
            y: 55,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)',
            itemStyle: {
                color: 'white'
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        series: [invocationSeries, ...tokenSeries]
    });
  }
}