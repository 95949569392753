import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import store from '../views/store';
import { setUser, setAccessToken } from '../views/store';
import { navigator } from 'lit-element-router';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('terms-view')
class TermsView extends connect(store)(LitElement) {
  connectedCallback() {
    super.connectedCallback()
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  static styles = css`
    .slds-box {
      border: 1px solid var(--border-color, #e5e5e5);
    }
    .slds-theme--default, .slds-theme_default {
      background-color: var(--background-color, #fff);
      color: var(--text-color, #181818);
    }
  `;

  render() {
    return html`
      <!-- Terms Content -->
      <main class="slds-p-around_large">
        <div class="slds-scope">
          <div class="slds-box slds-theme_default">
            <h1 class="slds-text-heading_large">Terms of Service</h1>
            <p class="slds-text-body_small slds-m-bottom_x-small slds-m-top_xx-small">Last Updated: <b>23rd July, 2024</b></p>
            <div class="slds-text-longform">
                <h2>1. Introduction</h2>
                <p>Welcome to inClouds.io ("we", "our", "us"). These Terms of Service ("Terms") govern your use of our services, which include access to various artificial intelligence (AI) models such as Claude, Gemini, and ChatGPT (the "Services"). By accessing or using our Services, you agree to comply with and be bound by these Terms.</p>

                <h2>2. Acceptance of Terms</h2>
                <p>By using our Services, you confirm that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you must not use our Services.</p>

                <h2>3. User Responsibilities</h2>
                <p>You agree to use our Services in compliance with all applicable laws and regulations. You are responsible for any content you provide and for your use of the Services. You must not use the Services to engage in any unlawful or harmful activities.</p>

                <h2>4. AI Models and Third-Party Terms</h2>
                <p>Our Services integrate various AI models provided by third-party companies such as Claude, Gemini, and ChatGPT. By using our Services, you acknowledge that you are also subject to the respective terms of service and privacy policies of these third-party providers. We encourage you to review these third-party terms and policies.</p>

                <h2>5. Privacy and Data Security</h2>
                <p>We are committed to respecting your privacy and protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information. We do not use your data to train our models. Your data is stored only as long as necessary to provide you with the Services or as required by law. You can request the deletion of your data at any time.</p>

                <h2>6. Data Usage and Retention</h2>
                <p>We observe user inputs that result in errors solely for the purpose of improving our products and Services. We do not use this data for any other purpose, and it is anonymized whenever possible.</p>

                <h2>7. User Account</h2>
                <p>To access certain features of our Services, you may be required to create an account. You must provide accurate and complete information and keep your account information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                <h2>8. Intellectual Property</h2>
                <p>All intellectual property rights in the Services and any content provided by us are owned by or licensed to us. You may use the Services and content solely for your personal, non-commercial use. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Services without our prior written consent.</p>

                <h2>9. Termination</h2>
                <p>We reserve the right to terminate or suspend your access to our Services, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Services will immediately cease.</p>

                <h2>10. Disclaimers</h2>
                <p>Our Services are provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that the Services will be uninterrupted or error-free, that defects will be corrected, or that the Services are free of viruses or other harmful components.</p>

                <h2>11. Limitation of Liability</h2>
                <p>To the fullest extent permitted by law, in no event will we, our affiliates, or our licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind arising out of or in connection with your use, or inability to use, the Services, including any direct, indirect, incidental, special, consequential, or punitive damages.</p>

                <h2>12. Governing Law</h2>
                <p>These Terms and any disputes related to these Terms or our Services will be governed by and construed in accordance with the laws of Estonia, without regard to its conflict of law principles.</p>

                <h2>13. Changes to Terms</h2>
                <p>We may revise these Terms from time to time at our sole discretion. The most current version of the Terms will always be posted on our website. By continuing to use our Services after changes become effective, you agree to be bound by the revised Terms.</p>

                <h2>14. Contact Information</h2>
                <p>If you have any questions about these Terms, please contact us at <a href="/support">support</a>.</p>
            </div>
          </div>
        <div class="slds-box slds-theme_default slds-m-top_small">
            <h1 class="slds-text-heading_large">Privacy Policy</h1>
            <p class="slds-text-body_small slds-m-bottom_x-small slds-m-top_xx-small">Last Updated: <b>23rd July, 2024</b></p>
            <div class="slds-text-longform">
                <h2>1. Introduction</h2>
                <p>inClouds.io ("we", "our", "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services (the "Services"). By using our Services, you consent to the practices described in this policy.</p>

                <h2>2. Information We Collect</h2>
                <p>We may collect the following types of information when you use our Services:</p>
                <ul>
                    <li><strong>Personal Information:</strong> Information that identifies you personally, such as your name, email address, and phone number.</li>
                    <li><strong>Usage Data:</strong> Information about how you interact with our Services, such as your IP address, browser type, access times, and pages viewed.</li>
                    <li><strong>Input Data:</strong> The content you provide when using the Services, such as text, queries, and other inputs.</li>
                </ul>

                <h2>3. Use of Information</h2>
                <p>We use the information we collect for the following purposes:</p>
                <ul>
                    <li><strong>To Provide and Improve Services:</strong> To operate, maintain, and enhance our Services, including analyzing how users interact with our Services.</li>
                    <li><strong>To Communicate with You:</strong> To send you updates, newsletters, and other information that may be of interest to you.</li>
                    <li><strong>To Ensure Security:</strong> To monitor and protect the security of our Services these Terms. If you do not agree to these Terms, you must not use our Services.</li>
                </ul>

                <h2>3. User Responsibilities</h2>
                <p>You agree to use our Services in compliance with all applicable laws and regulations. You are responsible for any content you provide and for your use of the Services. You must not use the Services to engage in any unlawful or harmful activities.</p>

                <h2>4. AI Models and Third-Party Terms</h2>
                <p>Our Services integrate various AI models provided by third-party companies such as Claude, Gemini, and ChatGPT. By using our Services, you acknowledge that you are also subject to the respective terms of service and privacy policies of these third-party providers. We encourage you to review these third-party terms and policies.</p>

                <h2>5. Privacy and Data Security</h2>
                <p>We are committed to respecting your privacy and protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information. We do not use your data to train our models. Your data is stored only as long as necessary to provide you with the Services or as required by law. You can request the deletion of your data at any time.</p>

                <h2>6. Data Usage and Retention</h2>
                <p>We observe user inputs that result in errors solely for the purpose of improving our products and Services. We do not use this data for any other purpose, and it is anonymized whenever possible.</p>

                <h2>7. User Account</h2>
                <p>To access certain features of our Services, you may be required to create an account. You must provide accurate and complete information and keep your account information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                <h2>8. Intellectual Property</h2>
                <p>All intellectual property rights in the Services and any content provided by us are owned by or licensed to us. You may use the Services and content solely for your personal, non-commercial use. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Services without our prior written consent.</p>

                <h2>9. Termination</h2>
                <p>We reserve the right to terminate or suspend your access to our Services, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Services will immediately cease.</p>

                <h2>10. Disclaimers</h2>
                <p>Our Services are provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that the Services will be uninterrupted or error-free, that defects will be corrected, or that the Services are free of viruses or other harmful components.</p>

                <h2>11. Limitation of Liability</h2>
                <p>To the fullest extent permitted by law, in no event will we, our affiliates, or our licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind arising out of or in connection with your use, or inability to use, the Services, including any direct, indirect, incidental, special, consequential, or punitive damages.</p>

                <h2>12. Governing Law</h2>
                <p>These Terms and any disputes related to these Terms or our Services will be governed by and construed in accordance with the laws of Estonia, without regard to its conflict of law principles.</p>

                <h2>13. Changes to Terms</h2>
                <p>We may revise these Terms from time to time at our sole discretion. The most current version of the Terms will always be posted on our website. By continuing to use our Services after changes become effective, you agree to be bound by the revised Terms.</p>

                <h2>14. Contact Information</h2>
                <p>If you have any questions about these Terms, please contact us at <a href="/support">support</a>.</p>
            </div>
        </div>
      </main>
    `
  }
}