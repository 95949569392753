class LocalStorageService {
  constructor() {
    if (LocalStorageService.instance) {
      return LocalStorageService.instance;
    }
    LocalStorageService.instance = this;

    this.queue = [];
    this.isIframeLoaded = false;

    if (window?.process?.env?.NODE_ENV === "production") {
      // Create the iframe element
      const iframe = document.createElement('iframe');

      // Set the attributes for the iframe
      iframe.id = 'localStorageHub';
      iframe.src = window.endpoint + '/hub';
      iframe.style.display = 'none';

      // Append the iframe to the body of the current document
      document.body.appendChild(iframe);

      this.hub = document.getElementById('localStorageHub');

      // Attach an event listener to the load event
      this.hub.addEventListener('load', this.handleIframeLoad.bind(this));
    }
  }

  handleIframeLoad() {
    this.isIframeLoaded = true;
    // Process queued operations
    this.queue.forEach(operation => operation());
    this.queue = [];
  }

  enqueueOperation(operation) {
    if (this.isIframeLoaded) {
      operation();
    } else {
      this.queue.push(operation);
    }
  }

  setItem(key, value) {
    if (window?.process?.env?.NODE_ENV === "production") {
      this.enqueueOperation(() => {
        this.hub.contentWindow.postMessage({ type: 'setItem', key, value}, '*');
      });
    } else {
      localStorage.setItem(key, value);
    }
  }

  getItem(key) {
    return new Promise((resolve) => {
      if (window?.process?.env?.NODE_ENV === "production") {
        this.enqueueOperation(() => {
          window.addEventListener('message', function handleResponse(event) {
            if (event.data.key === key) {
              resolve(event.data.value ? event.data.value : null);
              window.removeEventListener('message', handleResponse);
            }
          });
          this.hub.contentWindow.postMessage({ type: 'getItem', key }, '*');
        });
      } else {
        const value = localStorage.getItem(key);
        resolve(value);
      }
    });
  }

  removeItem(key) {
    if (window?.process?.env?.NODE_ENV === "production") {
      this.enqueueOperation(() => {
        this.hub.contentWindow.postMessage({ type: 'removeItem', key }, '*');
      });
    } else {
      localStorage.removeItem(key);
    }
  }

  clear() {
    if (window?.process?.env?.NODE_ENV === "production") {
      this.enqueueOperation(() => {
        this.hub.contentWindow.postMessage({ type: 'clear' }, '*');
      });
    } else {
      localStorage.clear();
    }
  }
}

const localStorageService = new LocalStorageService();
export default localStorageService;