export function loadGlobalStyles() {
  const existingLink = document.querySelector('link[href="https://blobs.inclouds.io/styles/main.css"]');
  if (existingLink) {
    return existingLink.cloneNode();
  }
  
  const link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('href', 'https://blobs.inclouds.io/styles/main.css');
  return link;
}

export function setElementTheme(element, theme) {
  if (theme === "dark") {
    element.classList.add('dark');
    element.classList.remove('light');
  }
  else
  {
    element.classList.add('light');
    element.classList.remove('dark');
  }
}