import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import store from '../views/store';
import { setUser, setAccessToken } from '../views/store';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('download-view')
class DownloadView extends LitElement {
  connectedCallback() {
    super.connectedCallback();
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  changeUrlNoRefresh(e) {
    e.preventDefault();
    var target = e.target;
    var i = 0;
    while((!target.href || target.href.animVal) && i<5) {
      target = target.parentElement;
      i++;
    }
    const href = target.href;
    window.history.pushState({}, null, href);
    window.dispatchEvent(new CustomEvent('route'));
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  selectTab(e, target = null) {
    const links = this.shadowRoot.querySelectorAll('.alt-tabs .slds-tabs_default__item');
    for (var i = 0; i < links.length; i++) {
      links[i].classList.remove('slds-is-active');
      if (target && i===target) {
        links[i].classList.add('slds-is-active');
      }
    }
    if (!target) {
      e.target.parentElement.classList.add('slds-is-active');
    }
    const index = parseInt((target?target:e.target.dataset.index));
    // Get all elements with the class 'slds-tabs_default__content'
    const elements = this.shadowRoot.querySelectorAll('.alt-tabs .slds-tabs_default__content');
    
    // Check if the nth element exists
    if (index < elements.length) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].classList.remove('slds-show');
        elements[i].classList.add('slds-hide');
      }
      const element = elements[index];
      if (element.classList.contains('slds-show')) {
        element.classList.remove('slds-show');
        element.classList.add('slds-hide');
      } else if (element.classList.contains('slds-hide')) {
        element.classList.remove('slds-hide');
        element.classList.add('slds-show');
      } else {
        // If neither class is present, assume we want to show it
        element.classList.add('slds-show');
      }
    } else {
      console.error('Index out of bounds');
      return null;
    }
  }

  detectOperatingSystem() {
    const platform = navigator.platform.toLowerCase();

    if (platform.startsWith('win')) {
      return 'Windows';
    } else if (platform.startsWith('mac')) {
      return 'Mac';
    } else if (platform.includes('linux')) {
      return 'Linux';
    } else {
      return 'Unknown OS';
    }
  }

  firstUpdated() {
    const os = this.detectOperatingSystem();
    // this.selectTab(null, 2);
    if (os==="Mac") {
      this.selectTab(null, 1);
    }
    else if (os==="Linux") {
      this.selectTab(null, 2);
    }
  }

  static styles = css`
    .slds-theme--default, .slds-theme_default {
      background-color: var(--background-color, #fff);
      color: var(--text-color, #181818);
    }
    .slds-box {
      border: 1px solid var(--border-color, #e5e5e5);
    }
    .slds-text-heading--label-normal, .slds-text-heading_label-normal, .slds-text-title, .slds-tabs--default__link, .slds-tabs_default__link {
      color: var(--text-color, #444);
    }
    .slds-tabs--default__link:focus, .slds-tabs--default__link:hover, .slds-tabs_default__link:focus, .slds-tabs_default__link:hover, .slds-tabs_default__item.slds-is-active .slds-tabs_default__link:hover {
      color: var(--text-color, #444);
    }
    .slds-icon {
      --slds-c-icon-color-foreground: var(--text-color, #181818);
    }
  `;

  render() {
    return html`
      <!-- Download Content -->
      <main class="slds-p-around_large">
        <div class="slds-text-heading_medium slds-m-bottom_medium">Welcome to AIGC Installation Guide</div>
        <div class="slds-text-body_regular"> This documentation will guide you through the installation, usage, and advanced features of the AIGC (Artificial Intelligence Generate Code) tool. Whether you're a seasoned developer or new to the world of AI-powered coding, you'll find everything you need to get the most out of AIGC. </div>
        <div class="slds-m-top_large">
          <div class="slds-text-heading_small slds-m-bottom_medium">Installation</div>
          <div class="slds-text-body_regular"> To get started with AIGC, follow the installation instructions for your operating system: </div>
          <div class="slds-tabs_default alt-tabs">
            <ul class="slds-tabs_default__nav" role="tablist">
              <li class="slds-tabs_default__item slds-is-active" title="Windows" role="presentation">
                <a class="slds-tabs_default__link" data-index="0" @click="${this.selectTab}" href="javascript:void(0);" role="tab" tabindex="0" aria-selected="true" aria-controls="windows-tab" id="windows-tab__item">Windows</a>
              </li>
              <li class="slds-tabs_default__item" title="macOS" role="presentation">
                <a class="slds-tabs_default__link" data-index="1" @click="${this.selectTab}" href="javascript:void(0);" role="tab" tabindex="-1" aria-selected="false" aria-controls="macos-tab" id="macos-tab__item">macOS</a>
              </li>
              <li class="slds-tabs_default__item" title="Linux" role="presentation">
                <a class="slds-tabs_default__link" data-index="2" @click="${this.selectTab}" href="javascript:void(0);" role="tab" tabindex="-1" aria-selected="false" aria-controls="linux-tab" id="linux-tab__item">Linux</a>
              </li>
            </ul>
            <div id="windows-tab" class="slds-tabs_default__content slds-show" role="tabpanel" aria-labelledby="windows-tab__item">
              <div class="slds-text-longform">
                <p>For Windows, open your command prompt and run:</p>
                <pre>
                  <code>curl -s https://blobs.inclouds.io/install.bat -o install.bat && install.bat</code>
                </pre>
              </div>
            </div>
            <div id="macos-tab" class="slds-tabs_default__content slds-hide" role="tabpanel" aria-labelledby="macos-tab__item">
              <div class="slds-text-longform">
                <p>For macOS, open your terminal and run:</p>
                <pre>
                  <code>curl -s https://blobs.inclouds.io/install | bash</code>
                </pre>
              </div>
            </div>
            <div id="linux-tab" class="slds-tabs_default__content slds-hide" role="tabpanel" aria-labelledby="linux-tab__item">
              <div class="slds-text-longform">
                <p>For Linux, open your terminal and run:</p>
                <pre>
                  <code>wget -qO- https://blobs.inclouds.io/install | bash</code>
                </pre>
              </div>
            </div>
          </div>
        </div>
        <div class="slds-m-top_large">
          <div class="slds-text-heading_small slds-m-bottom_medium">Quick Start</div>
          <div class="slds-text-body_regular"> AIGC can be started in single-file or multi-file mode. </div>
          <div class="slds-text-body_regular"> Once you have installed AIGC, you can get started with the following steps: </div>
          <ol class="slds-list_ordered">
            <li>
              <div class="slds-text-heading_xsmall slds-m-bottom_x-small">Configure Your Secret Token</div>
              <div class="slds-text-body_regular"> First you need to get your secret profile by visiting the <a @click="${this.changeUrlNoRefresh}" href="/profile">profile</a> page and clicking the "Reveal API Key" button under "Access Token" section. You then need to configure AIGC with the provided secret token.
                <pre>
                  <code>aigc config set api-token "YOUR API TOKEN HERE"</code>
                </pre>
              </div>
            </li>
            <li>
              <div class="slds-text-heading_xsmall slds-m-bottom_x-small">Initialize a New History Entry (Optional)</div>
              <div class="slds-text-body_regular"> Run the following command to create a new history entry, this is only required for single-file mode conversations with back and forth messages with the agent:
                <pre>
                  <code>aigc history init -d "An optional system level message like a set of instructions"</code>
                </pre>
              This will create an .aigc file in the current directory with the history entry id, the actual history file can be found in the history folder inside the config path which you can receive with the following command:
                <pre>
                  <code>aigc config</code>
                </pre>
              For convenience sake the history entry is serialized to beautified JSON so you can have a conversation with an agent and it will automatically update.
              </div>
            </li>
            <li>
              <div class="slds-text-heading_xsmall slds-m-bottom_x-small slds-m-top_x-small">Understanding The File Tree</div>
              <div class="slds-text-body_regular"> 
                To get the files currently visible to AIGC under the current directory run:
                <pre>
                  <code>aigc tree</code>
                </pre>
                This will print all of the files visible to AIGC recursively under the current directory, by default any patterns that are in .gitignore are ignored, as well as hidden directories and binary files.<br>
                The output of this command is prepended to any multi-file mode requests by default, however they can also be appended to single-file request with the -T option in single-file outputs.
              </div>
            </li>
            <li>
              <div class="slds-text-heading_xsmall slds-m-bottom_x-small slds-m-top_x-small">Generate Code</div>
              <div class="slds-text-body_regular"> 
                To generate code for a new React component, use the following command:
                <pre>
                  <code>aigc gen "Generate a react controller for my authentication module"</code>
                </pre>
                Since AIGC runs in multi-file mode by default it can generate up to hundreds of files with a single request. In order to have AIGC run in single-file mode you can use the -o option in order for the output to be printed to stdout, alternatively you can supply a filename such as "-o example.json". By default AIGC will look up every directory recursively for an .aigc file to determine whether it should append the output to any history entry.
              </div>
            </li>
            <li>
              <div class="slds-text-heading_xsmall slds-m-bottom_x-small slds-m-top_x-small">Fix Errors</div>
              <div class="slds-text-body_regular"> If you encounter an error in your code, you can use AIGC to help fix it by piping it to aigc via stdin like:
                <pre>
                  <code>node app.js 2>&1 | aigc gen "How to fix this error?" -o app.js</code>
                </pre>
              </div>
            </li>
            <li>
              <div class="slds-text-heading_xsmall slds-m-bottom_x-small slds-m-top_x-small">Fix Bugs</div>
              <div class="slds-text-body_regular"> If you encounter a bug in your code, you can use AIGC to help fix it by piping it to aigc via stdin like:
                <pre>
                  <code>cat app.js | aigc gen "Please fix this bug" -o app.js</code>
                </pre>
              </div>
            </li>
            <li>
              <div class="slds-text-heading_xsmall slds-m-bottom_x-small slds-m-top_x-small">Warning</div>
              <div class="slds-text-body_regular"> It is best to use "aigc gen" features with the -d option, this will print the anticipated changes so you can subsequently run the same request without the -d option and it will create all the files, this process is meant to be deterministic meaning all of the files that were initially printed will be created:
                <pre>
                  <code>aigc gen "Generate a react controller for my authentication module" -d</code>
                </pre>
              Additionally in case of a single-file output you can simply print to stdout by supplying an empty -o flag such as:
                <pre>
                  <code>cat app.js | aigc gen "How to fix this bug?" -d -o</code>
                </pre>
              </div>
            </li>
            <li>
              <div class="slds-text-heading_xsmall slds-m-bottom_x-small slds-m-top_x-small">Setting Your Model</div>
              <div class="slds-text-body_regular"> You can print a list of all of the available models in the following manner:
                <pre>
                  <code>aigc models</code>
                </pre>
              You can either set your model globally or a per query basis, if an invalid option is provided the entire list is printed again:
                <pre>
                  <code>aigc config set model "gpt3"</code>
                </pre>
                OR
                <pre>
                  <code>aigc gen "Hello world!" -o -m "gpt3"</code>
                </pre>
              </div>
            </li>
          </ol>
        </div>
        <div class="slds-m-top_large">
          <div class="slds-text-heading_small slds-m-bottom_medium">Additional Resources</div>
          <div class="slds-grid slds-wrap slds-grid_pull-padded">
            <div class="slds-col slds-size_1-of-2 slds-p-horizontal_small">
              <div class="slds-box">
                <div class="slds-card__header slds-grid">
                  <header class="slds-media slds-media_center slds-has-flexi-truncate">
                    <div class="slds-media__figure">
                      <span class="slds-icon_container slds-icon-utility-setup" title="Description of icon when needed">
                        <svg class="slds-icon slds-icon_small" aria-hidden="true">
                          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#setup"></use>
                        </svg>
                        <span class="slds-assistive-text">Setup</span>
                      </span>
                    </div>
                    <div class="slds-media__body">
                      <h2 class="slds-card__header-title">
                        <a href="/docs" @click="${this.changeUrlNoRefresh}" class="slds-card__header-link slds-truncate" title="Containerless Form">Command Reference</a>
                      </h2>
                    </div>
                  </header>
                </div>
                <div class="slds-card__body slds-card__body_inner"> Explore the complete list of AIGC commands and their usage. </div>
                <div class="slds-card__footer">
                  <a href="/docs" @click="${this.changeUrlNoRefresh}">Learn More</a>
                </div>
              </div>
            </div>
            <div class="slds-col slds-size_1-of-2 slds-p-horizontal_small">
              <div class="slds-box">
                <div class="slds-card__header slds-grid">
                  <header class="slds-media slds-media_center slds-has-flexi-truncate">
                    <div class="slds-media__figure">
                      <span class="slds-icon_container slds-icon-utility-connected_apps" title="Description of icon when needed">
                        <svg class="slds-icon slds-icon_small" aria-hidden="true">
                          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#connected_apps"></use>
                        </svg>
                        <span class="slds-assistive-text">Connected Apps</span>
                      </span>
                    </div>
                    <div class="slds-media__body">
                      <h2 class="slds-card__header-title">
                        <a href="/docs" @click="${this.changeUrlNoRefresh}" class="slds-card__header-link slds-truncate" title="Containerless Form">Integration Guide</a>
                      </h2>
                    </div>
                  </header>
                </div>
                <div class="slds-card__body slds-card__body_inner"> Learn how to integrate AIGC with your existing development workflows. </div>
                <div class="slds-card__footer">
                  <a href="/docs" @click="${this.changeUrlNoRefresh}">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    `
  }
}