import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import store from './store';
import { setUser, setAccessToken, setTheme } from '../views/store';
import localStorageService from '../components/LocalStorageService';
import { loadGlobalStyles, setElementTheme } from '../components/slds-system';

@customElement('main-view')
class MainView extends connect(store)(LitElement) {
  @property({type: String}) accessToken = '';
  @property({type: Boolean}) requiresNewToken = false;
  @property({type: Object}) authUser = null;
  @property({type: String}) route = '';
  @property({type: String}) theme = '';

  stateChanged(state) {
    const user = state.user;
    if (user) {
      localStorageService.setItem("user", JSON.stringify(user));
    }
    const token = state.token;
    if (!(token===this.accessToken)) {
      localStorageService.setItem("token", token);
      this.fetchProfile(token);
      this.accessToken = token;
    }
    if (!(this.route === state.route)) {
      this.route = state.route;
    }
    if (!(this.theme===state.theme)) {
      this.theme = state.theme;
      setTimeout((function() {
        const myElement = document.documentElement;
        if (this.theme === "dark") {
          myElement.classList.add('dark');
          myElement.classList.remove('light');
        }
        else
        {
          myElement.classList.add('light');
          myElement.classList.remove('dark');
        }
      }).bind(this))
    }
  }

  connectedCallback() {
    super.connectedCallback();

    document.addEventListener('DOMContentLoaded', (function () {
      // Select all videos on the page
      const videos = this.shadowRoot.querySelectorAll('video');
      const shadowRoot = this.shadowRoot;
      // Function to handle subtitle display
      function handleSubtitles(video) {
        const track = video.textTracks[0]; // Assumes the first track is the one we want

        if (!track) return; // Exit if no track is available

        const subtitlesWrapper = shadowRoot.getElementById('subtitles');

        track.mode = 'hidden'; // Keep it hidden, we'll display it ourselves

        track.addEventListener('cuechange', () => {
          const activeCues = track.activeCues;
          subtitlesWrapper.innerHTML = ''; // Clear previous subtitles
          for (const cue of activeCues) {
            const subtitleDiv = document.createElement('div');
            subtitleDiv.classList.add('subtitle');
            subtitleDiv.textContent = cue.text;
            subtitlesWrapper.appendChild(subtitleDiv);
          }
        });
      }

      // Create an intersection observer to handle video playback and subtitles
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            video.play();
            handleSubtitles(video); // Handle subtitles when the video starts playing
          } else {
            video.pause();
            const subtitlesWrapper = this.shadowRoot.getElementById('subtitles');
            subtitlesWrapper.innerHTML = ''; // Clear subtitles when the video is paused
          }
        });
      }, {
        threshold: 0.5
      });

      // Observe each video element
      videos.forEach(video => observer.observe(video));
    }).bind(this));
  }

  detectOperatingSystem() {
    const platform = navigator.platform.toLowerCase();

    if (platform.startsWith('win')) {
      return 'Windows';
    } else if (platform.startsWith('mac')) {
      return 'Mac';
    } else if (platform.includes('linux')) {
      return 'Linux';
    } else {
      return 'Unknown OS';
    }
  }

  selectTab(e, target = null) {
    const links = this.shadowRoot.querySelectorAll('.main-tabs .slds-tabs_default__item');
    for (var i = 0; i < links.length; i++) {
      links[i].classList.remove('slds-is-active');
      if (target && i===target) {
        links[i].classList.add('slds-is-active');
      }
    }
    if (!target) {
      e.target.parentElement.classList.add('slds-is-active');
    }
    const index = parseInt((target?target:e.target.dataset.index));
    // Get all elements with the class 'slds-tabs_default__content'
    const elements = this.shadowRoot.querySelectorAll('.main-tabs .slds-tabs_default__content');
    
    // Check if the nth element exists
    if (index < elements.length) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].classList.remove('slds-show');
        elements[i].classList.add('slds-hide');
      }
      const element = elements[index];
      if (element.classList.contains('slds-show')) {
        element.classList.remove('slds-show');
        element.classList.add('slds-hide');
      } else if (element.classList.contains('slds-hide')) {
        element.classList.remove('slds-hide');
        element.classList.add('slds-show');
      } else {
        // If neither class is present, assume we want to show it
        element.classList.add('slds-show');
      }
    } else {
      console.error('Index out of bounds');
      return null;
    }
  }

  changeUrlNoRefresh(e) {
    var target = e.target;
    var i = 0;
    while((!target.href || target.href.animVal) && i<5) {
      target = target.parentElement;
      i++;
    }
    const href = target.href;
    if (!(href === "https://chat.inclouds.io"))
    {
      e.preventDefault();
      window.history.pushState({}, null, href);
      window.dispatchEvent(new CustomEvent('route'));
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  getChatHref() {
    if (window?.process?.env?.NODE_ENV === "production") {
      return "https://chat.inclouds.io";
    }
    return "/chat";
  }

  static styles = css`
    video {
      height: 28rem;
    }
    .dark .slds-input {
      background-color: #2b2826;
      border: 1px solid #514f4d;
    }
    .dark .slds-tabs_default__item {
      color: white;
    }
    .dark a {
      color: white;
    }
    .dark .post-buttons button {
      color: #c9c7c5;
      --slds-c-button-color-border: #514f4d;
    }
    .dark hr {
      border-top: 1px solid #c9c7c5;
    }
    .dark a:focus, .dark a:hover {
      color: #0176d3;
      text-decoration: none;
    }
    .slds-button_outline-brand {
      background-color: #f3f3f3;
    }
    .dark .slds-button_outline-brand {
      background-color: #2b2826;
    }
    span.small_icon {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
    span.small_icon>.slds-badge__icon {
      margin-right: -0.1rem;
    }
    .dark .slds-accordion {
      --slds-c-accordion-heading-text-color: white;
    }
    #main {
      margin-left: auto;
      margin-right: auto;
    }
    .alt-background {
      background-color: rgba(21, 137, 238, 0.1);
    }
    .video-placeholder {
      background-color: black;
      height: 16.875rem;
      width: 30rem;
      border-radius: 0.25rem;
      position: relative;
    }
    .video-placeholder>video {
      height: 16.875rem;
      width: 30rem;
    }
    .subtitles-wrapper {
      position: absolute;
      top: 0;
      padding: 1rem;
      width: 100%;
      overflow-y: auto;
      max-height: 500px;
      color: white;
      text-align: center;
      font-size: 18px;
    }
    .subtitle {
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.35);
      border-radius: 0.25rem;
      padding: 0.25rem 0.25rem;
    }
    .slds-theme--default, .slds-theme_default {
      background-color: var(--background-color, #fff);
      color: var(--text-color, #181818);
    }
    .slds-box {
      border: 1px solid var(--border-color, #e5e5e5);
    }
    .slds-text-heading--label-normal, .slds-text-heading_label-normal, .slds-text-title, .slds-tabs--default__link, .slds-tabs_default__link {
      color: var(--text-color, #444);
    }
    .slds-tabs--default__link:focus, .slds-tabs--default__link:hover, .slds-tabs_default__link:focus, .slds-tabs_default__link:hover, .slds-tabs_default__item.slds-is-active .slds-tabs_default__link:hover {
      color: var(--text-color, #444);
    }
  `;

  render() {
    return html`
    <!-- Hero Section -->
    <section class="slds-p-around_x-large slds-text-align_center alt-background">
      <div class="slds-container_x-large" id="main">
        <div class="slds-text-align_left slds-show_inline-block slds-p-top_large slds-p-bottom_large">
          <div class="slds-text-heading_large">Harness the Power of AI with inClouds</div>
          <div class="slds-text-body_regular">Explore our cutting-edge web-based chat and CLI tool to enhance your workflows and boost productivity.</div>
          <div class="video-placeholder slds-m-top_medium">
            <video muted loop controls>
              <source src="https://blobs.inclouds.io/videos/presentation-demo-newer.mp4" type="video/mp4">
              <source src="https://blobs.inclouds.io/videos/presentation-demo-newer.mkv" type="video/webm">
              <track src="/assets/presentation-demo-newer.vtt" kind="subtitles" srclang="en" label="English">
              Your browser does not support the video tag.
            </video>
            <div class="subtitles-wrapper" id="subtitles"></div>
          </div>
          <div class="slds-m-top_medium">
            <a @click="${this.changeUrlNoRefresh}" href="/docs/web-chat" class="slds-button slds-button_brand">Learn More About Web Chat</a>
            <a @click="${this.changeUrlNoRefresh}" href="/download" class="slds-button slds-button_outline-brand">Learn More About CLI Tool</a>
          </div>
        </div>
      </div>
    </section>
    <div class="slds-container_x-large" id="main">
      <!-- Overview Section -->
      <section class="slds-p-around_large">
        <div class="slds-text-heading_medium">About inClouds.io</div>
        <div class="slds-text-body_regular">At inClouds, we're committed to delivering state-of-the-art AI tools that empower businesses and individuals to achieve more. Our web-based chat and CLI tool offer unparalleled performance, versatility, and ease of use.</div>
        <div class="slds-m-top_large">
          <h2 class="slds-text-heading_medium slds-m-bottom_medium">Features Comparison</h2>
          <div class="slds-grid slds-gutters slds-wrap slds-grid_vertical-stretch">
            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_medium">
              <article class="slds-box">
                <div class="slds-card__header slds-grid">
                  <header class="slds-media slds-media_center slds-has-flexi-truncate">
                    <div class="slds-media__body">
                      <h3 class="slds-card__header-title slds-text-heading_small">Web-Based Chat</h3>
                    </div>
                  </header>
                </div>
                <div class="slds-card__body slds-card__body_inner">
                  <ul class="slds-list_dotted">
                    <li>High-speed performance</li>
                    <li>Multiple AI models (GPT, Gemini, Claude)</li>
                    <li>Seamless model switching</li>
                    <li>User-friendly interface</li>
                  </ul>
                </div>
              </article>
            </div>
            <div class="slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_medium">
              <article class="slds-box">
                <div class="slds-card__header slds-grid">
                  <header class="slds-media slds-media_center slds-has-flexi-truncate">
                    <div class="slds-media__body">
                      <h3 class="slds-card__header-title slds-text-heading_small">CLI Tool (AIGC)</h3>
                    </div>
                  </header>
                </div>
                <div class="slds-card__body slds-card__body_inner">
                  <ul class="slds-list_dotted">
                    <li>File system interface</li>
                    <li>Code generation</li>
                    <li>Project creation</li>
                    <li>Bug fixing</li>
                  </ul>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <!-- Web-Based Chat Section -->
      <section class="slds-p-horizontal_x-small" id="web-chat">
        <div class="slds-card__header slds-grid">
          <header class="slds-media slds-media_center slds-has-flexi-truncate">
            <div class="slds-media__body">
              <h2 class="slds-card__header-title slds-text-heading_medium"> Web-Based Chat </h2>
            </div>
          </header>
        </div>
        <div class="slds-card__body slds-card__body_inner">
          <p class="slds-text-body_regular slds-m-bottom_medium">
            Our web-based chat offers a seamless, high-performance conversational AI experience. With support for multiple AI models and the ability to switch between them mid-conversation, you can tailor your interactions to your specific needs.
          </p>
          <div class="slds-grid slds-wrap slds-gutters">
            <div class="slds-col slds-size_1-of-1 slds-large-size_1-of-2">
              <div class="slds-m-top_medium">
                <h3 class="slds-text-heading_small slds-m-bottom_small">Key Features</h3>
                <ul class="slds-list_dotted slds-m-left_medium">
                  <li>Lightning-fast message transfer and rendering</li>
                  <li>Unified interfaces for GPT, Gemini, and Claude models</li>
                  <li>Seamless model switching for optimal results</li>
                  <li>User-friendly interface with enhanced features</li>
                </ul>
              </div>
            </div>
            <div class="slds-col slds-size_1-of-1 slds-large-size_1-of-2">
              <div class="slds-m-top_small slds-box slds-box_x-small slds-text-align_center slds-show_inline-block">
                <div class="slds-text-body_small video-placeholder">
                  <video muted loop>
                    <source src="https://blobs.inclouds.io/videos/web-demo-new.mp4" type="video/mp4">
                    <source src="https://blobs.inclouds.io/videos/web-demo-new.mkv" type="video/webm">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- CLI Tool Section -->
      <section class="slds-p-top_large slds-p-horizontal_x-small" id="cli-tool">
        <div class="slds-card__body slds-card__body_inner">
          <div class="slds-grid slds-wrap slds-gutters">
            <div class="slds-col slds-size_1-of-1 slds-large-size_1-of-2">
              <header class="slds-m-bottom_medium">
                <h2 class="slds-text-heading_large">CLI Tool (AIGC)</h2>
              </header>
              <div class="slds-m-bottom_medium">
                <div class="slds-box slds-box_x-small slds-text-align_center slds-show_inline-block">
                  <!-- Add screenshots or video demo -->
                  <div class="slds-text-body_small video-placeholder">
                    <video muted loop>
                      <source src="https://blobs.inclouds.io/videos/terminal-demo-new.mp4" type="video/mp4">
                      <source src="https://blobs.inclouds.io/videos/terminal-demo-new.mkv" type="video/webm">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div class="slds-col slds-size_1-of-1 slds-large-size_1-of-2">
              <div class="slds-m-top_large">
                <p class="slds-text-body_regular">Our AI-powered CLI tool, AIGC (Artificial Intelligence Generate Code), is designed to streamline your development workflow. With its ability to generate code, create projects, and fix bugs, AIGC is an indispensable asset for developers.</p>
              </div>
              <div class="slds-m-bottom_medium slds-m-top_medium">
                <h3 class="slds-text-heading_small slds-m-bottom_small">Key Features</h3>
                <ul class="slds-list_dotted">
                  <li class="slds-item">File system interface for seamless integration</li>
                  <li class="slds-item">Code generation for various frameworks and languages</li>
                  <li class="slds-item">Project creation and scaffolding</li>
                  <li class="slds-item">Bug detection and fixing capabilities</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Testimonials/Case Studies -->
      <section class="slds-p-around_large">
        <div class="slds-text-heading_medium">Customer Stories</div>
        <div class="slds-grid slds-wrap slds-grid_pull-padded slds-m-top_medium">
          <div class="slds-col slds-size_1-of-3 slds-p-horizontal_small">
            <div class="slds-box slds-theme_default">
              <div class="slds-text-heading_small">Matthew Parker</div>
              <div class="slds-text-title">Software Engineer</div>
              <div class="slds-m-top_small">"inClouds' web-based chat has revolutionized the way I interact with AI. The seamless model switching and lightning-fast performance have boosted my productivity tremendously."</div>
            </div>
          </div>
          <div class="slds-col slds-size_1-of-3 slds-p-horizontal_small">
            <div class="slds-box slds-theme_default">
              <div class="slds-text-heading_small">Olivia Bennett</div>
              <div class="slds-text-title">Data Scientist</div>
              <div class="slds-m-top_small">"AIGC has become an indispensable tool in my development workflow. Its ability to generate code and fix bugs has saved me countless hours of manual work."</div>
            </div>
          </div>
          <div class="slds-col slds-size_1-of-3 slds-p-horizontal_small">
            <div class="slds-box slds-theme_default">
              <div class="slds-text-heading_small">Andrew Harris</div>
              <div class="slds-text-title">Product Manager</div>
              <div class="slds-m-top_small">"The inClouds suite of AI tools has transformed the way our team works. The performance, versatility, and ease of use have significantly improved our efficiency and output quality."</div>
            </div>
          </div>
        </div>
      </section>
      <!-- AIGC Installation -->
      <section class="slds-p-around_large" id="aigc-installation">
        <div class="slds-text-heading_medium">Easy Installation for AIGC</div>
        <div class="slds-text-body_regular">Getting started with AIGC is a breeze. Simply follow the installation instructions for your operating system, and you'll be up and running in no time.</div>
        <div class="slds-m-top_medium">
          <div class="slds-tabs_default main-tabs">
            <ul class="slds-tabs_default__nav" role="tablist">
              <li class="slds-tabs_default__item slds-is-active" title="Windows" role="presentation">
                <a class="slds-tabs_default__link" data-index="0" @click="${this.selectTab}" href="javascript:void(0);" role="tab" tabindex="0" aria-selected="true" aria-controls="windows-tab" id="windows-tab__item">Windows</a>
              </li>
              <li class="slds-tabs_default__item" title="macOS" role="presentation">
                <a class="slds-tabs_default__link" data-index="1" @click="${this.selectTab}" href="javascript:void(0);" role="tab" tabindex="-1" aria-selected="false" aria-controls="macos-tab" id="macos-tab__item">macOS</a>
              </li>
              <li class="slds-tabs_default__item" title="Linux" role="presentation">
                <a class="slds-tabs_default__link" data-index="2" @click="${this.selectTab}" href="javascript:void(0);" role="tab" tabindex="-1" aria-selected="false" aria-controls="linux-tab" id="linux-tab__item">Linux</a>
              </li>
            </ul>
            <div id="windows-tab" class="slds-tabs_default__content slds-show" role="tabpanel" aria-labelledby="windows-tab__item">
              <div class="slds-text-longform">
                <p>For Windows, open your command prompt and run:</p>
                <pre>
                  <code>curl -s https://blobs.inclouds.io/install.bat -o install.bat && install.bat</code>
                </pre>
              </div>
            </div>
            <div id="macos-tab" class="slds-tabs_default__content slds-hide" role="tabpanel" aria-labelledby="macos-tab__item">
              <div class="slds-text-longform">
                <p>For macOS, open your terminal and run:</p>
                <pre>
                  <code>curl -s https://blobs.inclouds.io/install | bash</code>
                </pre>
              </div>
            </div>
            <div id="linux-tab" class="slds-tabs_default__content slds-hide" role="tabpanel" aria-labelledby="linux-tab__item">
              <div class="slds-text-longform">
                <p>For Linux, open your terminal and run:</p>
                <pre>
                  <code>wget -qO- https://blobs.inclouds.io/install | bash</code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Call to Action -->
      <section class="slds-p-around_large slds-text-align_center">
        <div class="slds-text-heading_medium">Get Started with inClouds</div>
        <div class="slds-m-top_medium">
          <a @click="${this.changeUrlNoRefresh}" href="${this.getChatHref()}" class="slds-button slds-button_brand">Explore Web-Based Chat</a>
          <a @click="${this.changeUrlNoRefresh}" href="/download" class="slds-button slds-button_outline-brand">Discover CLI Tool</a>
        </div>
        <div class="slds-m-top_large">
          <a @click="${this.changeUrlNoRefresh}" href="/support" class="slds-button slds-button_neutral">Contact Us</a>
        </div>
      </section>
      <!-- Footer -->
      <footer class="slds-p-around_large">
        <div class="slds-grid slds-grid_align-spread">
          <div>
            <a @click="${this.changeUrlNoRefresh}" href="/support">Contact Us</a> | <a @click="${this.changeUrlNoRefresh}" href="/tos">Terms of Service</a> | <a @click="${this.changeUrlNoRefresh}" href="/privacy">Privacy Policy</a>
          </div>
          <div> Follow us: <a target="_blank" href="https://x.com/inClouds_io">X</a> | <a target="_blank" href="https://www.facebook.com/profile.php?id=61563501358385">Facebook</a> | <a target="_blank" href="https://www.linkedin.com/company/104334303">LinkedIn</a>
          </div>
        </div>
      </footer>
    </div>
   `
  }

  async fetchProfile(accessToken) {
    var errorData = null;
    try {
      const response = await fetch(window.endpoint+'/api/profile', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken
        }
      });

      if (response.status === 401) {
        // Handle 401 unauthorized response
        throw new Error('auth/id-token-expired');
      }

      if (!response.ok) {
        errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const profileData = await response.json();
      this.requiresNewToken = false;
      if (profileData.photoURL) {
        const photoUrl = profileData.photoURL;
        delete profileData.photoURL;
        profileData.photoUrl = photoUrl;
      }
      // if (profileData.customClaims) {
      //   const customAttributes = profileData.customClaims;
      //   delete profileData.customClaims;
      //   profileData.customAttributes = JSON.stringify(customAttributes);
      // }
      store.dispatch(setUser(profileData));
      // debugger;
      console.log('Profile Data:', profileData);
    } catch (error) {
      if (errorData) {
        if (errorData.code === "auth/argument-error") {
          store.dispatch(setAccessToken(''));
          store.dispatch(setUser(null));
          localStorage.removeItem("user");
          localStorage.removeItem("token");
        }
      }
      else if (error.message === 'auth/id-token-expired') {
        console.log("Token expired, renewing...");
        this.requiresNewToken = true;
        // await this.handleTokenExpiration();
        // Retry your API request or other logic here after renewing the token
      } else {
        console.error("API request error:", error);
      }
    }
  }

  updated(changedProperties) {
    if (this.requiresNewToken && this.authUser) {
      this.refreshUserToken(this.authUser);
      this.requiresNewToken = false;
    }
  }

  refreshUserToken(user) {
    user.getIdToken(true).then(((token) => {
      console.log("Token refreshed:", token);
      store.dispatch(setAccessToken(token));
      localStorageService.setItem("token", token);
    }).bind(this)).catch((error) => {
      console.error("Error refreshing token:", error);
    });
  }

  firstUpdated() {
    const os = this.detectOperatingSystem();
    // this.selectTab(null, 2);
    if (os==="Mac") {
      this.selectTab(null, 1);
    }
    else if (os==="Linux") {
      this.selectTab(null, 2);
    }
  }

  setMyTheme(theme) { 
    var newTheme = theme;
    window.override = true;
    if (!newTheme) {
      newTheme = "light";
      window.override = false;
    }
    if (!window.override)
    {
      if(window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches) {
        newTheme = "dark";
      } else {
        newTheme = "light";
      }
    }
    if (newTheme === "dark") {
      document.documentElement.classList.add('dark');
    }
    store.dispatch(setTheme(newTheme));
  }

  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());

    localStorageService.getItem("user").then((user) => {
      if (user) {
        const myUser = JSON.parse(user);
        store.dispatch(setUser(myUser));
      }
    });
    localStorageService.getItem("token").then((token) => {
      if (token) {
        store.dispatch(setAccessToken(token));
      }
    });

    window.firebase.onAuthStateChanged(window.firebase.auth, ((user) => {
      this.authUser = user;
    }).bind(this));
    
    var theme = localStorage.getItem("theme");
    if (theme) {
      this.setMyTheme(theme);
    }
    localStorageService.getItem("theme").then(((theme) => {
      if (theme) {
        this.setMyTheme(theme);
        this.requestUpdate();
      }
    }).bind(this));
    // var theme = localStorage.getItem("theme");
    // if (theme) {
    //   if (theme==="dark") {
    //     window.theme = "dark";
    //     this.shadowRoot.documentElement.classList.add('dark');
    //   }
    //   else if (theme==="light") {
    //     window.theme = "light";
    //     this.shadowRoot.documentElement.classList.remove('dark');
    //   }
    // }
    // window.theme = "dark";
    // this.shadowRoot.documentElement.classList.add('dark');
  }
}