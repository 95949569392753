import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { loadGlobalStyles } from '../components/slds-system';

@customElement('docs-view')
class DocsView extends LitElement {
  connectedCallback() {
    super.connectedCallback()
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  static styles = css`
    .slds-table thead th, .slds-table {
      background-color: var(--background-color, #f3f3f3);
      color: var(--text-color, #181818);
    }
    .slds-text-heading--large, .slds-text-heading_large {
      color: var(--text-color, #181818);
    }
    .slds-table tbody tr.slds-is-selected>td, .slds-table tbody tr.slds-is-selected>th, .slds-table:not(.slds-no-row-hover) tbody tr:focus>td, .slds-table:not(.slds-no-row-hover) tbody tr:focus>th, .slds-table:not(.slds-no-row-hover) tbody tr:hover>td, .slds-table:not(.slds-no-row-hover) tbody tr:hover>th, .slds-table:not(.slds-no-row-hover) tr.slds-is-selected:hover>td, .slds-table:not(.slds-no-row-hover) tr.slds-is-selected:hover>th {
      background-color: var(--table-hover, white);
    }
  `;

  render() {
    return html`
      <!-- Download Content -->
      <main class="slds-p-around_large">
        <h1 class="slds-text-heading_large">inClouds Documentation</h1>
        <p>Welcome to the inClouds documentation page. Select an inquiry from the table of contents below to be directed to the correct documentation entry.</p>
        
        <section>
            <h2 class="slds-text-heading_medium slds-m-top_medium slds-m-bottom_x-small">Table of Contents</h2>
            <table class="slds-table slds-table_bordered slds-table_cell-buffer">
                <thead>
                    <tr class="slds-line-height_reset">
                        <th class="slds-text-title_caps" scope="col">Section</th>
                        <th class="slds-text-title_caps" scope="col">Topics</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">CLI Tool</th>
                        <td>
                            <ul class="slds-list_dotted">
                                <li><a href="#cli-installation">How to install the CLI tool?</a></li>
                                <li><a href="#cli-usage">How to use the CLI tool?</a></li>
                                <li><a href="#cli-commands">Available commands in the CLI tool</a></li>
                                <li><a href="#cli-troubleshooting">Troubleshooting CLI tool</a></li>
                                <li><a href="#cli-examples">CLI tool examples</a></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Web Tool</th>
                        <td>
                            <ul class="slds-list_dotted">
                                <li><a href="#web-getting-started">Getting started with the web tool</a></li>
                                <li><a href="#web-features">Features of the web tool</a></li>
                                <li><a href="#web-troubleshooting">Troubleshooting common issues</a></li>
                                <li><a href="#web-best-practices">Web tool best practices</a></li>
                                <li><a href="#web-examples">Web tool examples</a></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">General Inquiries</th>
                        <td>
                            <ul class="slds-list_dotted">
                                <li><a href="#general-setup">How to set up inClouds?</a></li>
                                <li><a href="#general-pricing">Pricing and subscription plans</a></li>
                                <li><a href="#general-support">How to contact support?</a></li>
                                <li><a href="#general-faq">Frequently Asked Questions (FAQ)</a></li>
                                <li><a href="#general-resources">Additional Resources</a></li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
        
        <section id="cli-tool" class="slds-m-top_medium slds-m-bottom_xx-small">
            <h2 class="slds-text-heading_medium slds-m-bottom_xx-small">CLI Tool</h2>
            <article id="cli-installation">
                <h3 class="slds-text-heading_small">How to install the CLI tool?</h3>
                <p>Instructions on how to install the CLI tool...</p>
                <!-- Additional content -->
            </article>
            <article id="cli-usage">
                <h3 class="slds-text-heading_small">How to use the CLI tool?</h3>
                <p>Guidelines on how to use the CLI tool...</p>
                <!-- Additional content -->
            </article>
            <!-- More articles for CLI Tool -->
        </section>
        
        <section id="web-tool" class="slds-m-top_medium slds-m-bottom_xx-small">
            <h2 class="slds-text-heading_medium slds-m-bottom_xx-small">Web Tool</h2>
            <article id="web-getting-started">
                <h3 class="slds-text-heading_small">Getting started with the web tool</h3>
                <p>Introduction to the web tool and how to get started...</p>
                <!-- Additional content -->
            </article>
            <article id="web-features">
                <h3 class="slds-text-heading_small">Features of the web tool</h3>
                <p>Overview of the features provided by the web tool...</p>
                <!-- Additional content -->
            </article>
            <!-- More articles for Web Tool -->
        </section>

        <section id="general-inquiries" class="slds-m-top_medium slds-m-bottom_xx-small">
            <h2 class="slds-text-heading_medium slds-m-bottom_xx-small">General Inquiries</h2>
            <article id="general-setup">
                <h3 class="slds-text-heading_small">How to set up inClouds?</h3>
                <p>Step-by-step setup instructions for inClouds...</p>
                <!-- Additional content -->
            </article>
            <article id="general-pricing">
                <h3 class="slds-text-heading_small">Pricing and subscription plans</h3>
                <p>Details on pricing and available subscription plans...</p>
                <!-- Additional content -->
            </article>
            <!-- More articles for General Inquiries -->
        </section>
      </main>
    `
  }
}