// Import the functions you need from the Firebase SDKs
import { getAuth, onAuthStateChanged, getMultiFactorResolver, PhoneMultiFactorGenerator, PhoneAuthProvider, GithubAuthProvider, GoogleAuthProvider, EmailAuthProvider, signInWithPopup, RecaptchaVerifier, signOut, multiFactor } from 'firebase/auth';
import { initializeApp, FirebaseError } from 'firebase/app';

// Your web app's Firebase configuration (you can get this from the Firebase console)
const firebaseConfig = {
	apiKey: "AIzaSyBduNty7J12l9m59TQuxfpXBTgpyqlTMB0",
	authDomain: "inclouds-aigc.firebaseapp.com",
	projectId: "inclouds-aigc",
	storageBucket: "inclouds-aigc.appspot.com",
	messagingSenderId: "297848847338",
	appId: "1:297848847338:web:657ff4e2f4fc179408d093"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.useDeviceLanguage();
window.firebase = {
	onAuthStateChanged,
	auth,
	GithubAuthProvider,
	GoogleAuthProvider,
	EmailAuthProvider,
	signInWithPopup,
	FirebaseError,
	RecaptchaVerifier,
	signOut,
	multiFactor,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	getMultiFactorResolver
};

window.addEventListener('load', () => {
	// initRouter();
	registerSW();
});

async function registerSW() {
	if ('serviceWorker' in navigator) {
		try {
			await navigator.serviceWorker.register('./sw.js');
		} catch (e) {
			console.log('ServiceWorker registration failed. Sorry about that.', e);
		}
	} else {
		console.log('Your browser does not support ServiceWorker.');
	}
}

import './slds/scss/index.scss';
import './views/router-outlet.js';
import './views/login-view.js';
import './views/chat-view.js';
import './views/faq-view.js';
import './views/download-view.js';
import './views/help-view.js';
import './views/docs-view.js';
import './views/app-view.js';
import './views/terms-view.js';