import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

const FORCE_REDRAW = 'FORCE_REDRAW';
const SET_USER = 'SET_USER';
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_MODEL = 'SET_MODEL';
const SET_CHATS = 'SET_CHATS';
const LOAD_CHAT = 'LOAD_CHAT';
const SET_ROUTE = 'SET_ROUTE';
const VERIFY_SESSION_ID = 'VERIFY_SESSION_ID';
const SET_THEME = 'SET_THEME';

export const setTheme = (theme) => {  
  return {
    type: SET_THEME,
    theme
  };
};

export const verifyPaymentSessionId = (verify_session) => {  
  return {
    type: VERIFY_SESSION_ID,
    verify_session
  };
};

export const setRoute = (route) => {  
  return {
    type: SET_ROUTE,
    route
  };
};

export const loadChat = (chat_id) => {  
  return {
    type: LOAD_CHAT,
    chat_id
  };
};

export const forceRedraw = (date) => {  
  return {
    type: FORCE_REDRAW,
    date
  };
};

export const setUser = (user) => {  
  return {
    type: SET_USER,
    user
  };
};

export const setAccessToken = (token) => {  
  return {
    type: SET_ACCESS_TOKEN,
    token
  };
};

export const setModel = (model) => {  
  return {
    type: SET_MODEL,
    model
  };
};

export const setChats = (chats) => {  
  return {
    type: SET_CHATS,
    chats
  };
};

const initialState = {
  // initial state goes here
  forceRedraw: null,
  user: null, //Authenticated user
  token: '',
  model: null,
  chats: null,
  chat_id: '',
  verify_session_id: null,
  theme: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.theme
      };
    case VERIFY_SESSION_ID:
      return {
        ...state,
        verify_session: action.verify_session
      };
    case SET_ROUTE:
      return {
        ...state,
        route: action.route
      };
    case LOAD_CHAT:
      return {
        ...state,
        chat_id: action.chat_id
      };
    case FORCE_REDRAW:
      return {
        ...state,
        forceRedraw: Date.now()
      };
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case SET_MODEL:
      return {
        ...state,
        model: action.model
      };
    case SET_CHATS:
      return {
        ...state,
        chats: action.chats
      };
    // reducer logic goes here
    default:
      return state;
  }
};

const store = createStore(reducer, applyMiddleware(thunk));

export default store;