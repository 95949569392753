import {LitElement, html, css} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { navigator } from 'lit-element-router';
import {ifDefined} from 'lit/directives/if-defined.js';
import store from '../views/store';
import { forceRedraw } from '../views/store';
import localStorageService from './LocalStorageService';
import { loadGlobalStyles } from './slds-system';

@customElement('slds-dropdown')
class SldsDropdown extends navigator(LitElement) {
  @property({type: Boolean}) open;
  @property({type: Array}) options;
  @property({type: String}) types;

  constructor() {
    super();
    document.addEventListener('click', (function(e) {
      if (this.open) {
        const path = e.composedPath();
        let insideMenuClick = false;
        if(!(path === undefined || path.length == 0)) {
         insideMenuClick = path.find((el) => { return el == this; });
        }
        if(insideMenuClick) {
          //inside element
        } else {
          //outside element
          this.open = false;
          const event = new CustomEvent('blur', { open: this.open, bubbles: true });
          this.dispatchEvent(event);
        }
      }
    }).bind(this), false);
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  getHomeHref() {
    if (window?.process?.env?.NODE_ENV === "production") {
      const currentSubdomain = window.location.hostname.split('.')[0];
      if (currentSubdomain === 'chat') {
        return "https://inclouds.io/";
      }
      else
      {
        return "/";
      }
    }
    return "/";
  }

  pickOption(e) {
    var target = e.target;
    var i = 0;
    while((!target.dataset.type && !target.dataset.href) && i<5) {
      target = target.parentElement;
      i++;
    }
    var type = target.dataset.type;
    this.open = false;
    const blur = new CustomEvent('blur', { open: this.open, bubbles: true });
    this.dispatchEvent(blur);
    if (target.dataset.href) {
      const homeHref = this.getHomeHref();
      if (homeHref==="/") {
        this.navigate(homeHref+target.dataset.href);
      }
      else
      {
        window.location.href = homeHref+target.dataset.href;
      }
      return;
    }
    const event = new CustomEvent('select', { detail: { type: type }, bubbles: true });
    this.dispatchEvent(event);
  }

  renderItem(item) {
    if (item.type === 'hr') {
      return html`<li class="slds-has-divider_top-space" role="separator"></li>`;
    }
    if (item.type === 'footer') {
      return html`<li class="slds-dropdown__item slds-p-top_xx-small slds-p-left_small" role="presentation" style="color: #c9c7c5;">${item.label}</li>`;
    }
    if (item.type === 'header') {
      return html`<li class="slds-dropdown__header slds-truncate" title="${item.label}" role="separator">
        <span>${item.label}</span>
      </li>`;
    }
    return html`<li class="slds-dropdown__item" role="presentation"><a href="javascript:void(0);" data-type="${item.value}" data-href="${item.href}" @click="${this.pickOption}" role="menuitem"><span class="slds-truncate" title="${item.value}">${item.label}</span></a></li>`;

    // ... other cases from the previous implementation ...
  }

  static styles = css`
    slot {
      display: inline-block;
    }
    .dark .slds-has-divider--top-space, .dark .slds-has-divider_top-space {
      border-top: 1px solid #514f4d;
    }
    .dark .slds-form-element__label, .dark .slds-checkbox_faux_container>span {
      color: white;
    }
    .slds-dropdown {
      background: var(--background-color, #fff);
      color: var(--text-color, #181818);
      border: 1px solid var(--border-color, #e5e5e5);
    }
    .slds-dropdown__item>a {
      color: var(--text-color, #181818);
    }
    .slds-dropdown__item>a:focus, .slds-dropdown__item>a:hover {
      background-color: var(--table-hover, #f3f3f3);
    }
  `;

  render() {
    return html`
    <div class="slds-builder-header__nav-item">
      <div class="slds-dropdown-trigger slds-dropdown-trigger_click ${this.open?' slds-is-open':''}">
        <slot></slot>
        <div class="slds-dropdown ${!(this.types=="")?this.types:""}">
          <ul class="slds-dropdown__list" role="menu" aria-label="Show More">
            ${this.options.map((item) => this.renderItem(item))}
          </ul>
        </div>
      </div>
    </div>
    `
  }
}