import {LitElement, html, css} from 'lit'
import { customElement, property } from 'lit/decorators.js';

@customElement("slds-combobox")
export class SldsCombobox extends LitElement {
  @property({type: String}) label;

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
    <style>
      .dark button.slds-combobox__input {
        background-color: #2b2826;
      }
      .dark button.slds-combobox__input {
        --slds-c-input-color-border: #514f4d;
        --slds-c-input-text-color: white;
        --sds-c-input-text-color: white;
      }
    </style>
    <div class="slds-form-element">
      <label class="slds-form-element__label" for="combobox-id" id="combobox-label-id">${this.label}</label>
      <div class="slds-form-element__control">
        <div class="slds-combobox_container">
          <div class="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click">
            <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
              <button type="button" class="slds-input_faux slds-combobox__input" aria-labelledby="combobox-label-id combobox-id-selected-value" id="combobox-id-selected-value" aria-controls="listbox-id-1" aria-expanded="false" aria-haspopup="listbox">
                <span class="slds-truncate" id="combobox-value-id-94">Select an Option…</span>
              </button>
              <span class="slds-icon_container slds-icon-utility-down slds-input__icon slds-input__icon_right">
                <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                </svg>
              </span>
            </div>
            <div id="listbox-id-1" class="slds-dropdown slds-dropdown_length-5 slds-dropdown_fluid" role="listbox">
              <ul class="slds-listbox slds-listbox_vertical" role="presentation">
                <li role="presentation" class="slds-listbox__item">
                  <div id="option1" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
                    <span class="slds-media__figure slds-listbox__option-icon"></span>
                    <span class="slds-media__body">
                      <span class="slds-truncate" title="Accounts">Accounts</span>
                    </span>
                  </div>
                </li>
                <li role="presentation" class="slds-listbox__item">
                  <div id="option2" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
                    <span class="slds-media__figure slds-listbox__option-icon"></span>
                    <span class="slds-media__body">
                      <span class="slds-truncate" title="Reports">Reports</span>
                    </span>
                  </div>
                </li>
                <li role="presentation" class="slds-listbox__item">
                  <div id="option3" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
                    <span class="slds-media__figure slds-listbox__option-icon"></span>
                    <span class="slds-media__body">
                      <span class="slds-truncate" title="Contacts">Contacts</span>
                    </span>
                  </div>
                </li>
                <li role="presentation" class="slds-listbox__item">
                  <div id="option4" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
                    <span class="slds-media__figure slds-listbox__option-icon"></span>
                    <span class="slds-media__body">
                      <span class="slds-truncate" title="Files">Files</span>
                    </span>
                  </div>
                </li>
                <li role="presentation" class="slds-listbox__item">
                  <div id="option5" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
                    <span class="slds-media__figure slds-listbox__option-icon"></span>
                    <span class="slds-media__body">
                      <span class="slds-truncate" title="Groups">Groups</span>
                    </span>
                  </div>
                </li>
                <li role="presentation" class="slds-listbox__item">
                  <div id="option6" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
                    <span class="slds-media__figure slds-listbox__option-icon"></span>
                    <span class="slds-media__body">
                      <span class="slds-truncate" title="Leads">Leads</span>
                    </span>
                  </div>
                </li>
                <li role="presentation" class="slds-listbox__item">
                  <div id="option7" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
                    <span class="slds-media__figure slds-listbox__option-icon"></span>
                    <span class="slds-media__body">
                      <span class="slds-truncate" title="Notes">Notes</span>
                    </span>
                  </div>
                </li>
                <li role="presentation" class="slds-listbox__item">
                  <div id="option8" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
                    <span class="slds-media__figure slds-listbox__option-icon"></span>
                    <span class="slds-media__body">
                      <span class="slds-truncate" title="Groups">Groups</span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    `
  }
}
