import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { loadGlobalStyles } from './slds-system';

@customElement('slds-spinner')
class SldsSpinner extends LitElement {
  @property({ type: Boolean }) loading = false;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(loadGlobalStyles());
  }

  static styles = css`
    .slds-spinner_container {
      background-color: var(--background-color);
    }
  `;

  render() {
    return html`
      <div ?hidden="${!this.loading}" class="slds-spinner_container">
        <div role="status" class="slds-spinner slds-spinner_large slds-spinner_brand">
          <span class="slds-assistive-text">Loading</span>
          <div class="slds-spinner__dot-a"></div>
          <div class="slds-spinner__dot-b"></div>
        </div>
      </div>
    `;
  }
}